// Redux :: Action Types
import {
  GET_QUEUE_RESERVATION_IDLE,
  GET_QUEUE_RESERVATION_PENDING,
  GET_QUEUE_RESERVATION_FAILED,
  GET_QUEUE_RESERVATION_SUCCEEDED,

  // QUEUE STATE(Requeset server/Request Manager/Close Check)
  UPDATE_QUEUE_RESERVATION_STATE_IDLE,
  UPDATE_QUEUE_RESERVATION_STATE_PENDING,
  UPDATE_QUEUE_RESERVATION_STATE_FAILED,
  UPDATE_QUEUE_RESERVATION_STATE_SUCCEEDED,

  // QUEUE SUB PRODUCT
  GET_QUEUE_RESERVATION_SUB_PRODUCT_IDLE,
  GET_QUEUE_RESERVATION_SUB_PRODUCT_PENDING,
  GET_QUEUE_RESERVATION_SUB_PRODUCT_FAILED,
  GET_QUEUE_RESERVATION_SUB_PRODUCT_SUCCEEDED,

  // QUEUE PRODUCT
  GET_QUEUE_RESERVATION_PRODUCT_IDLE,
  GET_QUEUE_RESERVATION_PRODUCT_PENDING,
  GET_QUEUE_RESERVATION_PRODUCT_FAILED,
  GET_QUEUE_RESERVATION_PRODUCT_SUCCEEDED,
} from '../constants';

/* ----- INITIAL STATE ----- */
const initialState = {
  loading: false,
  idleErr: null,
  queueReservationErr: null,
  queueReservationResponse: [],
}

/* -----QUEUE RESERVATION REDUCER ----- */
const QueueReservationReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_QUEUE_RESERVATION_PENDING:
      return {
        ...state,
        loading: true,
        queueReservationResponse: []
      };
    case GET_QUEUE_RESERVATION_IDLE:
      return {
        ...state,
        loading: false,
        idleErr: action.payload
      };
    case GET_QUEUE_RESERVATION_SUCCEEDED:
      return {
        ...state,
        loading: false,
        queueReservationResponse: action.payload
      };

    case GET_QUEUE_RESERVATION_FAILED:
      return {
        ...state,
        loading: false,
        queueReservationErr: action.payload
      };

    default:
      return state;

  }
}

/* -----QUEUE RESERVATION STATE  INITIAL STATE ----- */
const queueStateInitialState = {
  loading: false,
  idleErr: null,
  queueStateErr: null,
  queueStateResponse: [],
}

/* ----- QUEUE REDUCER ----- */
const queueReservationStateReducer = (state = queueStateInitialState, action) => {
  switch (action.type) {

    case UPDATE_QUEUE_RESERVATION_STATE_PENDING:
      return {
        ...state,
        loading: true,
        queueStateResponse: []
      };
    case UPDATE_QUEUE_RESERVATION_STATE_IDLE:
      return {
        ...state,
        loading: false,
        idleErr: action.payload
      };
    case UPDATE_QUEUE_RESERVATION_STATE_SUCCEEDED:
      return {
        ...state,
        loading: false,
        queueStateResponse: action.payload
      };

    case UPDATE_QUEUE_RESERVATION_STATE_FAILED:
      return {
        ...state,
        loading: false,
        queueStateErr: action.payload
      };
    default:
      return state;
  }
}

/* ----- QUEUE PRODUCT AGAINST RESERVATION INITIAL STATE ----- */
const queueProductInitialState = {
  loading: false,
  idleErr: null,
  productQueueReservationErr: null,
  productQueueReservationResponse: [],
}

/* -----QUEUE PRODUCT AGAINST RESERVATION REDUCER ----- */
const QueueProductReducer = (state = queueProductInitialState, action) => {

  switch (action.type) {
    case GET_QUEUE_RESERVATION_PRODUCT_PENDING:
      return {
        ...state,
        loading: true,
        productQueueReservationResponse: []
      };
    case GET_QUEUE_RESERVATION_PRODUCT_IDLE:
      return {
        ...state,
        loading: false,
        idleErr: action.payload
      };
    case GET_QUEUE_RESERVATION_PRODUCT_SUCCEEDED:
      return {
        ...state,
        loading: false,
        productQueueReservationResponse: action.payload
      };

    case GET_QUEUE_RESERVATION_PRODUCT_FAILED:
      return {
        ...state,
        loading: false,
        productQueueReservationErr: action.payload
      };

    default:
      return state;

  }
}


/* ----- QUEUE SUB PRODUCT AGAINST RESERVATION INITIAL STATE ----- */
const queueSubProductInitialState = {
  loading: false,
  idleErr: null,
  subProductErr: null,
  subProductResponse: [],
}

/* -----QUEUE SUB  PRODUCT AGAINST RESERVATION REDUCER ----- */
const QueueSubProductReducer = (state = queueSubProductInitialState, action) => {

  switch (action.type) {
    case GET_QUEUE_RESERVATION_SUB_PRODUCT_PENDING:
      return {
        ...state,
        loading: true,
        subProductResponse: []
      };
    case GET_QUEUE_RESERVATION_SUB_PRODUCT_IDLE:
      return {
        ...state,
        loading: false,
        idleErr: action.payload
      };
    case GET_QUEUE_RESERVATION_SUB_PRODUCT_SUCCEEDED:
      return {
        ...state,
        loading: false,
        subProductResponse: action.payload
      };

    case GET_QUEUE_RESERVATION_SUB_PRODUCT_FAILED:
      return {
        ...state,
        loading: false,
        subProductErr: action.payload
      };

    default:
      return state;

  }
}


export { QueueReservationReducer, queueReservationStateReducer, QueueProductReducer, QueueSubProductReducer };
