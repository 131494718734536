import * as React from 'react';
import { View, StyleSheet } from 'react-native';
import { Box, Text, Button, HStack, Icon } from 'native-base';
import PropTypes from 'prop-types';
import { FontAwesome } from '@expo/vector-icons';
import { Ionicons } from "@expo/vector-icons";

// Redux :: Components
import { primaryColors } from '../utils/colors';

// Components
import AccordionListItem from './accordianList';
import { capitalize } from '../utils/common';

const AccordionCtrl = (props) => {
  const { data, aw, EditCategoryHandler, AddHandler, DeleteHandler, DeleteCategoryHandler, EditHandler } = props;

  const AddEvent = (item) => AddHandler(item);

  const EditCategory = (item) => EditCategoryHandler(item);

  const DeleteCategory = (item) => DeleteCategoryHandler(item);

  const DeleteProduct = (item) => DeleteHandler(item);

  const EditProduct = (item) => EditHandler(item);

  return <View style={styles.container}>
    {
      data.map((item, index) => {
        if (item?.title == null || item?.title == undefined || item?.title === "") {
          return null;
        }

        return <HStack key={`Stack_${index}`} >
          <Box w={aw || "100%"} key={`Container_${index}`} >
            <AccordionListItem
              key={`Header_${index}`}
              title={item?.title}
              addProductHandler={() => AddEvent(item)}
              editCategoryHandler={() => EditCategory(item)}
              deleteCategoryHandler={() => DeleteCategory(item)}
            >
              {
                item.content.length ?
                  (item.content || []).map((data, i) => <Box key={`Content_${i}`} p={"2"}>
                    <HStack justifyContent="space-between" space={3}>
                      <Text fontSize="md" bold minW={'60%'}>{capitalize(data?.title || "")}</Text>
                      {'price' in data && <Text fontSize="md" bold minW={'30%'}>{`$${(data?.price || "0")}`}</Text>}
                      <Button.Group size="sm" ml="50">
                        <Button
                          variant="solid"
                          colorScheme='blue'
                          size="xs"
                          pl="3"
                          pr="3"
                          pt="1"
                          pb="1"
                          mr="2"
                          borderRadius="full"
                          leftIcon={<Icon as={FontAwesome} name="edit" size="xs" />}
                          onPress={() => EditProduct(data)}
                          _text={{
                            fontSize: 'sm',
                            color: 'white'
                          }}
                        >
                          Edit
                        </Button>
                        <Button
                          variant="solid"
                          colorScheme='red'
                          size="xs"
                          borderRadius="full"
                          pl="3"
                          pr="3"
                          pt="1"
                          pb="1"
                          mr="2"
                          leftIcon={<Icon as={Ionicons} name="trash" size="xs" />}
                          onPress={() => DeleteProduct(data)}
                          _text={{
                            fontSize: 'sm',
                            color: 'white'
                          }}
                        >
                          Delete
                        </Button>
                      </Button.Group>
                    </HStack>
                  </Box>
                  )
                  :
                  <Box bold key={`Content_${index} `} p={"2"}>
                    <Text fontSize="md" bold>{capitalize("No data found")}
                    </Text>
                  </Box>
              }
            </AccordionListItem>
          </Box>
        </HStack>
      })
    }
  </View >
};

AccordionCtrl.propTypes = {
  data: PropTypes.array,
  aw: PropTypes.string,
  AddHandler: PropTypes.func,
}

AccordionCtrl.defaultProps = {
  data: [],
  aw: ""
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'flex-start',
  },
});

export default AccordionCtrl;
