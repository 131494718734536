/* eslint-disable react-native/no-inline-styles */
import React from "react";
import { Image } from 'react-native';
import { HStack, Box, StatusBar, Text } from "native-base";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Components
import { MenuLinks } from '../../shared/templates';

// Utils
import * as IMAGES from '../../shared/utils/images';

const Header = ({ navigation, route, loginDetail }) => {
  const { userRoles } = loginDetail;

  return <>
    <StatusBar backgroundColor="#3700B3" barStyle="light-content" />
    <Box safeAreaTop backgroundColor="#6200ee" />
    <HStack bg='#000' px="8" py="3" alignItems='center'>
      <HStack space="4" width="25%" alignItems='center'>
        <Image
          source={IMAGES.WEB_APP_ICON}
          alt="webIcon"
          style={{
            width: 100,
            height: 60
          }} />
      </HStack>
      <HStack space="4" width="75%" alignItems='center' justifyContent={(userRoles?.includes("VenueAdmin") || false) ? 'flex-end' : 'flex-start'}>
        <MenuLinks
          navigation={navigation}
          route={route}
          isVenueAdmin={(userRoles?.includes("VenueAdmin") || false)}
        />
      </HStack>
    </HStack>
  </>;
}

const mapStateToProps = (state) => {
  const { auth } = state;

  return {
    loginDetail: auth.loginResponse,
  };
}

Header.propTypes = {
  navigation: PropTypes.any,
  route: PropTypes.any,
  loginDetail: PropTypes.any,
}

export default connect(mapStateToProps, null)(Header);
