import axios from 'axios';
import { Platform } from 'react-native';

// Init :: Config
import { API_URL } from '../../config';

// API Endpoints
import * as API_Interface from '../../services/apiURLs';

// Redux :: Action Types
import {
  GET_ALL_USERS_IDLE,
  GET_ALL_USERS_PENDING,
  GET_ALL_USERS_FAILED,
  GET_ALL_USERS_SUCCEEDED,
  //Delete User
  DELETE_USERS_IDLE,
  DELETE_USERS_PENDING,
  DELETE_USERS_FAILED,
  DELETE_USERS_SUCCEEDED,

  //User Detail By Id
  GET_USERS_BY_ID_IDLE,
  GET_USERS_BY_ID_PENDING,
  GET_USERS_BY_ID_FAILED,
  GET_USERS_BY_ID_SUCCEEDED,

  //Post User Detail By Id
  POST_USERS_BY_ID_IDLE,
  POST_USERS_BY_ID_PENDING,
  POST_USERS_BY_ID_FAILED,
  POST_USERS_BY_ID_SUCCEEDED,
} from '../constants';

// Other Redux Actions
import { setAppLoading } from './common';

const GetAllUserDetailsWithPaging =
  (data, hideLoader = false) =>
  async (dispatch) => {
    // dispatch(setAppLoading(true));
    if (!hideLoader) {
      dispatch({ type: GET_ALL_USERS_PENDING, payload: true });
      dispatch({ type: GET_ALL_USERS_IDLE, payload: false });
      dispatch({ type: GET_ALL_USERS_FAILED, payload: false });
      dispatch({ type: GET_ALL_USERS_SUCCEEDED, payload: false });
    }
    try {
      const response = await axios({
        method: 'POST',
        baseURL: API_URL,
        url: API_Interface.GET_ALL_USERS,
        data: data,
      })
        .then((response) => response)
        .catch((error) => error.response);
      if (response.status !== 200) {
        dispatch(setAppLoading(false));
        dispatch({ type: GET_ALL_USERS_IDLE, payload: response });
      } else if (response.status == 417) {
        await dispatch({
          type: GET_ALL_USERS_FAILED,
          payload: response.data.data,
        });
      } else {
        await dispatch({
          type: GET_ALL_USERS_SUCCEEDED,
          payload: response.data.data,
        });
      }
      dispatch({ type: GET_ALL_USERS_PENDING, payload: false });
      dispatch(setAppLoading(false));
      return response;
    } catch (exception) {
      dispatch(setAppLoading(false));
      dispatch({ type: GET_ALL_USERS_FAILED, payload: exception });
      dispatch({ type: GET_ALL_USERS_PENDING, payload: false });
    }
  };
const DeleteUserById = (Id) => async (dispatch) => {
  dispatch(setAppLoading(true));
  dispatch({ type: DELETE_USERS_PENDING, payload: true });
  dispatch({ type: DELETE_USERS_IDLE, payload: null });
  dispatch({ type: DELETE_USERS_FAILED, payload: null });
  dispatch({ type: DELETE_USERS_SUCCEEDED, payload: null });

  try {
    const response = await axios({
      method: 'POST',
      baseURL: API_URL,
      url: API_Interface.DELETE_USER_BY_ID + '' + Id,
    })
      .then((response) => response)
      .catch((error) => error.response);
    if (response.status !== 200) {
      dispatch(setAppLoading(false));
      dispatch({ type: DELETE_USERS_IDLE, payload: response });
    } else if (response.status == 417) {
      await dispatch({ type: DELETE_USERS_FAILED, payload: response.data });
    } else {
      await dispatch({ type: DELETE_USERS_SUCCEEDED, payload: response.data });
    }
    dispatch({ type: DELETE_USERS_PENDING, payload: false });
    dispatch(setAppLoading(false));
    return response;
  } catch (exception) {
    dispatch(setAppLoading(false));
    dispatch({ type: DELETE_USERS_FAILED, payload: exception });
    dispatch({ type: DELETE_USERS_PENDING, payload: false });
  }
};

const GetUserDetailsByUserId = (id) => async (dispatch) => {
  dispatch({ type: GET_USERS_BY_ID_PENDING, payload: true });
  try {
    const response = await axios({
      method: 'GET',
      baseURL: API_URL,
      url: API_Interface.GET_USER_DETAIL_BY_ID + id,
    })
      .then((response) => response)
      .catch((error) => error.response);
    if (response.status !== 200) {
      dispatch({ type: GET_USERS_BY_ID_IDLE, payload: response });
    } else if (response.status == 417) {
      await dispatch({ type: GET_USERS_BY_ID_FAILED, payload: response.data });
    } else {
      await dispatch({
        type: GET_USERS_BY_ID_SUCCEEDED,
        payload: response.data,
      });
    }
    return response;
  } catch (exception) {
    dispatch(setAppLoading(false));
    dispatch({ type: GET_USERS_BY_ID_FAILED, payload: exception });
  }
};

const PostUserDetails = (data) => async (dispatch) => {
  dispatch(setAppLoading(true));
  dispatch({ type: POST_USERS_BY_ID_PENDING, payload: true });
  dispatch({ type: POST_USERS_BY_ID_IDLE, payload: null });
  dispatch({ type: POST_USERS_BY_ID_FAILED, payload: null });
  dispatch({ type: POST_USERS_BY_ID_SUCCEEDED, payload: null });
  
  var posturl = data.userId !== "" ? API_Interface.POST_USER_DETAIL_BY_ID : API_Interface.REGISTER_URL ;

  try {
    const response = await axios({
      method: 'POST',
      baseURL: API_URL,
      url: posturl,
      data: { ...data, triggerFromPlatform: Platform.OS },
    })
      .then((response) => response)
      .catch((error) => error.response);
    if (response.status !== 200) {
      dispatch(setAppLoading(false));
      dispatch({ type: POST_USERS_BY_ID_IDLE, payload: response });
    } else if (response.status == 417) {
      await dispatch({
        type: POST_USERS_BY_ID_FAILED,
        payload: response.data.data,
      });
    } else {
      await dispatch({
        type: POST_USERS_BY_ID_SUCCEEDED,
        payload: response.data.data,
      });
    }
    dispatch({ type: POST_USERS_BY_ID_PENDING, payload: false });
    dispatch(setAppLoading(false));
    return response;
  } catch (exception) {
    dispatch(setAppLoading(false));
    dispatch({ type: POST_USERS_BY_ID_FAILED, payload: exception });
    dispatch({ type: POST_USERS_BY_ID_PENDING, payload: false });
  }
};

export {
  GetAllUserDetailsWithPaging,
  DeleteUserById,
  GetUserDetailsByUserId,
  PostUserDetails,
};
