/* eslint-disable no-unsafe-optional-chaining */
import axios from 'axios';

// Init :: Config
import { API_URL } from '../../config';

// API Endpoints
import * as API_Interface from '../../services/apiURLs';

// Redux :: Action Types
import {

  // Venue Detail
  GET_TABLE_DETAIL_BY_ID_IDLE,
  GET_TABLE_DETAIL_BY_ID_PENDING,
  GET_TABLE_DETAIL_BY_ID_FAILED,
  GET_TABLE_DETAIL_BY_ID_SUCCEEDED,

  // Delete table details
  DELETE_VENUE_TABLE_DETAIL_PENDING,
  DELETE_VENUE_TABLE_DETAIL_IDLE,
  DELETE_VENUE_TABLE_DETAIL_FAILED,
  DELETE_VENUE_TABLE_DETAIL_SUCCEEDED,


} from '../constants';

// Other Redux Actions
import { setAppLoading } from './common';

const deletetabledetailsById = (Id) => async (dispatch) => {
  dispatch(setAppLoading(true));
  dispatch({ type: DELETE_VENUE_TABLE_DETAIL_PENDING, payload: true });
  dispatch({ type: DELETE_VENUE_TABLE_DETAIL_IDLE, payload: null });
  dispatch({ type: DELETE_VENUE_TABLE_DETAIL_FAILED, payload: null });
  dispatch({ type: DELETE_VENUE_TABLE_DETAIL_SUCCEEDED, payload: null });

  try {
    const response = await axios({
      method: 'POST',
      baseURL: API_URL,
      url: API_Interface.DELETE_TABLE_DETAIL_BY_ID + "" + Id,
    }).then((response) => response)
      .catch((error) => error.response);
    dispatch(setAppLoading(false));
    return response;
  } catch (exception) {
    dispatch(setAppLoading(false));
    dispatch({ type: DELETE_VENUE_TABLE_DETAIL_FAILED, payload: exception });
    dispatch({ type: DELETE_VENUE_TABLE_DETAIL_PENDING, payload: false });
  }
}
const GetTableDetailsById = (id) => async (dispatch) => {
  dispatch({ type: GET_TABLE_DETAIL_BY_ID_PENDING, payload: true });
  try {
    const response = await axios({
      method: 'GET',
      baseURL: API_URL,
      url: API_Interface.GET_TABLE_DETAIL_BY_ID + id,
    }).then((response) => response)
      .catch((error) => error.response);

    if (response.status !== 200) {
      dispatch({ type: GET_TABLE_DETAIL_BY_ID_IDLE, payload: response });
    } else if (response.status == 417) {
      await dispatch({ type: GET_TABLE_DETAIL_BY_ID_FAILED, payload: response.data });
    } else {
      await dispatch({ type: GET_TABLE_DETAIL_BY_ID_SUCCEEDED, payload: response.data });
    }
    return response;
  } catch (exception) {
    dispatch(setAppLoading(false));
    dispatch({ type: GET_TABLE_DETAIL_BY_ID_FAILED, payload: exception });
  }
}

const ResetTableDetailForm = () => async (dispatch) => {
  console.log("tst");
  dispatch({ type: GET_TABLE_DETAIL_BY_ID_SUCCEEDED, payload: [] })
}


const PostTable = (data) => async (dispatch) => {
  dispatch(setAppLoading(true));
  try {
    const response = await axios({
      method: "POST",
      baseURL: API_URL,
      url: API_Interface.POST_TABLE,
      data: data,
    }).then((response) => response)
      .catch((error) => error.response)
    dispatch(setAppLoading(false));
    return response;
  } catch (exception) {
    dispatch(setAppLoading(false));
    return exception;
  }
}



export {

  GetTableDetailsById,
  PostTable,
  ResetTableDetailForm,
  deletetabledetailsById
}

