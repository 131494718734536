import axios from 'axios';
// Redux :: Action Types
import {
  APP_LOADING,
  CURRENT_ROUTE_NAME,
  CURRENT_SELECTED_ROUTE,
  NOTIFICATION_ROUTE,

  // City
  FETCH_ALL_CITY_IDLE,
  FETCH_ALL_CITY_PENDING,
  FETCH_ALL_CITY_FAILED,
  FETCH_ALL_CITY_SUCCEEDED,

  // State
  FETCH_ALL_STATE_IDLE,
  FETCH_ALL_STATE_PENDING,
  FETCH_ALL_STATE_FAILED,
  FETCH_ALL_STATE_SUCCEEDED,

  // Country
  FETCH_ALL_COUNTRY_IDLE,
  FETCH_ALL_COUNTRY_PENDING,
  FETCH_ALL_COUNTRY_FAILED,
  FETCH_ALL_COUNTRY_SUCCEEDED,
} from '../constants';

// Init :: Config
import { API_URL } from '../../config';

// API Endpoints
import * as API_Interface from '../../services/apiURLs';

const setAppLoading = (status) => {
  return {
    type: APP_LOADING,
    payload: status
  }
}

const setCurrentRouteName = (status) => {
  return {
    type: CURRENT_ROUTE_NAME,
    payload: status
  }
}

const setSelectedRoute = (status) => {
  return {
    type: CURRENT_SELECTED_ROUTE,
    payload: status
  }
}

const setNotificationRedired = (status) => {

  return {
    type: NOTIFICATION_ROUTE,
    payload: status
  }
}

const FetchCity = () => async (dispatch) => {
  dispatch({ type: FETCH_ALL_CITY_PENDING, payload: true });
  try {
    const response = await axios({
      method: "GET",
      baseURL: API_URL,
      url: API_Interface.FETCH_ALL_CITY,
    }).then((response) => response)
      .catch((error) => error.response)
    if (response.status !== 200) {
      dispatch({ type: FETCH_ALL_CITY_IDLE, payload: response });
    }
    else if (response.status == 417) {
      await dispatch({ type: FETCH_ALL_CITY_FAILED, payload: response.data });
    } else {
      await dispatch({ type: FETCH_ALL_CITY_SUCCEEDED, payload: response.data });
    }
    return response;
  } catch (exception) {
    dispatch({ type: FETCH_ALL_CITY_FAILED, payload: exception });
  }
}

const FetchState = () => async (dispatch) => {
  dispatch({ type: FETCH_ALL_STATE_PENDING, payload: true });
  try {
    const response = await axios({
      method: "GET",
      baseURL: API_URL,
      url: API_Interface.FETCH_ALL_STATE,
    }).then((response) => response)
      .catch((error) => error.response)
    if (response.status !== 200) {
      dispatch({ type: FETCH_ALL_STATE_IDLE, payload: response });
    }
    else if (response.status == 417) {
      await dispatch({ type: FETCH_ALL_STATE_FAILED, payload: response.data });
    } else {
      await dispatch({ type: FETCH_ALL_STATE_SUCCEEDED, payload: response.data });
    }
    return response;
  } catch (exception) {
    dispatch({ type: FETCH_ALL_STATE_FAILED, payload: exception });
  }
}

const FetchCountry = () => async (dispatch) => {
  dispatch({ type: FETCH_ALL_COUNTRY_PENDING, payload: true });
  try {
    const response = await axios({
      method: "GET",
      baseURL: API_URL,
      url: API_Interface.FETCH_ALL_COUNTRY,
    }).then((response) => response)
      .catch((error) => error.response)
    if (response.status !== 200) {
      dispatch({ type: FETCH_ALL_COUNTRY_IDLE, payload: response });
    }
    else if (response.status == 417) {
      await dispatch({ type: FETCH_ALL_COUNTRY_FAILED, payload: response.data });
    } else {
      await dispatch({ type: FETCH_ALL_COUNTRY_SUCCEEDED, payload: response.data });
    }
    return response;
  } catch (exception) {
    dispatch({ type: FETCH_ALL_COUNTRY_FAILED, payload: exception });
  }
}

export {
  setAppLoading,
  setCurrentRouteName,
  setSelectedRoute,
  FetchCity,
  FetchState,
  FetchCountry,

  setNotificationRedired
}