import { applyAuthTokenInterceptor } from 'react-native-axios-jwt';
import axios from 'axios';

// Init :: Config
import { API_URL } from '../config';

// 1. Create an axios instance that you wish to apply the interceptor to
export const axiosInstance = axios.create({ baseURL: API_URL });

// 2. Define token refresh function.
const requestRefresh = async (refresh) => {
  // Important! Do NOT use the axios instance that you supplied to applyAuthTokenInterceptor
  // because this will result in an infinite loop when trying to refresh the token.
  // Use the global axios client or a different instance
  const response = await axios.post(`${API_URL}/Account/RefreshTokenNew`, { token: refresh, ipAddress: "0.0.0.0" });

  return response.data.jwtToken
}

// 3. Add interceptor to your axios instance
applyAuthTokenInterceptor(axiosInstance, { requestRefresh })