/* eslint-disable react-native/no-raw-text */
import React, { useState, useEffect, useRef } from 'react';
import { Box, Flex, Select, Button, Text, SlideFade, Icon, HStack, Input, useToast, Heading } from 'native-base';
import { connect } from 'react-redux';
import { Ionicons, MaterialIcons } from "@expo/vector-icons";
import { FontAwesome } from '@expo/vector-icons';
import Moment from "moment";
import PropTypes from 'prop-types';

/* ----- COMPONENTS ----- */
import { SpinnerLoading } from '../../../shared/templates/SpinnerLoading';

/* ----- REDUX :: ACTIONS ----- */
import * as VenueActions from '../../../shared/redux/actions/venue';
import * as ProductActions from '../../../shared/redux/actions/product';
import * as ProductCategoryActions from '../../../shared/redux/actions/productCategory';

/* ----- UTILS ----- */
import { primaryColors } from '../../../shared/utils/colors';
// Stylesheet
import { webStyles } from '../../../shared/styles';


const MenuScreen = (props) => {
  const { GetAllVenueDetailsWithPaging, FetchProducCategoryByVenueID,
    venuesListWithPagingResponse, FetchProduct,
    InsertUpdateProductCategory, InsertUpdateProduct, headerTitleOptions, CategoriesCount, Productscount,
    productCategoryByVenueIDReponse, type, venueId, productLoader } = props;

  const [isReady] = useState(true);
  const [loading, setLoading] = useState(false);
  const [venue, setVenue] = useState(venueId || '');
  const [searchText] = useState("");
  const [sortField] = useState("");
  const [sortDir] = useState("");
  const [pageNumber] = useState(1);
  const [pageSize] = useState(5);
  const [id] = useState(0);
  const [isMenuVenueInformationEdit, setIsMenuVenueInformationEdit] = useState(true);
  const toast = useToast();
  const categoryRefs = useRef([]);
  const productRefs = useRef([]);

  useEffect(() => {
    loadProductCategoryAgainstVenueID(venueId || '');
  }, []);

  useEffect(() => {
    loadVenueData();
  }, [pageNumber]);

  useEffect(() => {
    type && setIsMenuVenueInformationEdit(false);
  }, [type]);

  useEffect(() => {
    loadProductCategoryAgainstVenueID(venue);
  }, [venue, venueId]);

  useEffect(() => {
    if (productCategoryByVenueIDReponse && productCategoryByVenueIDReponse.length > 0) {
      setLoading(true);
      productCategoryByVenueIDReponse && productCategoryByVenueIDReponse.map((item, iteration) => {
        if (categoryRefs?.current[`categoryInput_${iteration}`]) {
          categoryRefs.current[`categoryInput_${iteration}`].value = item?.description;
          categoryRefs.current[`categoryInput_${iteration}`].id = `categoryInput_${item?.id}`;
        }
      });

      productCategoryByVenueIDReponse && productCategoryByVenueIDReponse.map(
        async (item, rowIteration) => {
          const loadProductAgainstCategoryIDResp = await FetchProduct(item?.id).then((result) => { return result; });

          if (loadProductAgainstCategoryIDResp && loadProductAgainstCategoryIDResp?.status == 200 && loadProductAgainstCategoryIDResp?.data.length > 0) {
            Array.from({ length: Productscount }, (_, iteration) => {
              productRefs.current[`productInput_${rowIteration}_${iteration}`].value = loadProductAgainstCategoryIDResp?.data[iteration]?.title;
              productRefs.current[`productInput_${rowIteration}_${iteration}`].id = `productInput_${item?.id}`;
              productRefs.current[`productInput_${rowIteration}_${iteration}`].placeholder = `productLabel_${loadProductAgainstCategoryIDResp?.data[iteration]?.id}`;
            });
          } else if (loadProductAgainstCategoryIDResp && loadProductAgainstCategoryIDResp?.status == 200 && loadProductAgainstCategoryIDResp?.data.length == 0) {
            Array.from({ length: Productscount }, (_, iteration) => {
              if (productRefs?.current[`productInput_${rowIteration}_${iteration}`]) {
                productRefs.current[`productInput_${rowIteration}_${iteration}`].value = "";
                productRefs.current[`productInput_${rowIteration}_${iteration}`].id = `productInput_${0}`;
                productRefs.current[`productInput_${rowIteration}_${iteration}`].placeholder = `productLabel_${0}`;
              }
            });
          } else {
            Array.from({ length: Productscount }, (_, iteration) => {
              productRefs.current[`productInput_${rowIteration}_${iteration}`].value = "";
              productRefs.current[`productInput_${rowIteration}_${iteration}`].id = `productInput_${0}`;
              productRefs.current[`productInput_${rowIteration}_${iteration}`].placeholder = `productLabel_${0}`;
            });
          }
        }
      );

      setLoading(false);
    } else {
      if (isReady) {
        setLoading(true);
        categoryRefs?.current && categoryRefs?.current?.length > 0 &&
          Array.from({ length: CategoriesCount }, (_, rowIteration) => {
            categoryRefs.current[`categoryInput_${rowIteration}`].value = "";
            Array.from({ length: Productscount }, (_, iteration) => {
              productRefs.current[`productInput_${rowIteration}_${iteration}`].value = "";
              categoryRefs.current[`categoryInput_${iteration}`].id = `categoryInput_${0}`;
            });
          });
        setLoading(false);
      }
    }
  }, [productCategoryByVenueIDReponse, venueId]);

  useEffect(() => {
    if (venuesListWithPagingResponse && venuesListWithPagingResponse.length > 0) {
      !type && setVenue(venuesListWithPagingResponse[0]?.id);
      !type && loadProductCategoryAgainstVenueID(venuesListWithPagingResponse[0]?.id);
    }
  }, [venuesListWithPagingResponse]);

  const loadVenueData = async () => {
    const response = await GetAllVenueDetailsWithPaging({
      searchText: searchText || "",
      sortField: sortField || "",
      sortDir: sortDir || "",
      pageNumber: pageNumber || 1,
      pageSize: pageSize || 5,
      id: id || 0
    }, true).then((result) => { return result; });
    return response;
  }

  const loadProductCategoryAgainstVenueID = async (venueID) => {
    if (venue) {
      const response = await FetchProducCategoryByVenueID(venueID).then((result) => { return result; });
      return response;
    }
  }

  const renderHeader = (headers) => {
    return <HStack space={3}>
      {
        headers.map((item, iteration) => {
          return <Box w={item?.width} key={`${item?.key}_${iteration}`}>
            <Text 
            fontSize="lg" 
            fontWeight="bold" 
            // fontFamily="SourceSansPro_700Bold"
            >{item?.label}</Text>
          </Box>;
        })
      }
    </HStack>
  }

  const renderRowItem = (rowIteration) => {
    return <HStack space={3} alignItems="center" key={`CategoryItem_${rowIteration}`}>
      <Input
        key={`categoryInput_${rowIteration}`}
        ref={(el) => (categoryRefs.current[`categoryInput_${rowIteration}`] = el)}
        type="default"
        bg="#FFFFFF"
        selectionColor="white"
        placeholder="Category Name"
        defaultValue={""}
        onChange={(event) => console.log(event.target.value)}
        style={isMenuVenueInformationEdit ? '' : webStyles.readOnlyInput}
      />
      <Box display="flex" alignItems="center">
        <Icon as={MaterialIcons} name="arrow-right" size="sm" />
      </Box>
      {
        Array.from({ length: Productscount }, (_, iteration) => {
          return <Input
            key={`category_against_product_list_${iteration}`}
            ref={(el) => (productRefs.current[`productInput_${rowIteration}_${iteration}`] = el)}
            type="default"
            bg="#FFFFFF"
            selectionColor="white"
            placeholder={`Product ${iteration + 1}`}
            placeholderTextColor="transparent"
            defaultValue={""}
            onChange={(event) => console.log(event.target.value)}
            style={isMenuVenueInformationEdit ? '' : webStyles.readOnlyInput}
          />
        })
      }
    </HStack>;
  }

  const checkIfCategoryNameVaries = (updatedCategoryID, updatedCategoryName) => {
    let isCategoryNameChanged = true;
    if (productCategoryByVenueIDReponse && productCategoryByVenueIDReponse?.length > 0) {
      let findChangedCategoryValue = productCategoryByVenueIDReponse.find((x) => String(x.id) === String(updatedCategoryID));
      if (findChangedCategoryValue && findChangedCategoryValue?.description === updatedCategoryName) {
        isCategoryNameChanged = false;
      }
    }

    return isCategoryNameChanged;
  }

  const handleSubmit = async () => {
    toast.closeAll();
    setLoading(true);

    if (categoryRefs.current) {
      let promises = [];

      Array.from({ length: CategoriesCount }, async (_, iteration) => {
        const current_CategoryID = categoryRefs?.current[`categoryInput_${iteration}`]?.id.split('_');
        const current_CategoryValue = categoryRefs?.current[`categoryInput_${iteration}`]?.value;

        if (current_CategoryID && current_CategoryID.length >= 1 && current_CategoryID[1]) {

          // Insert/Update Category
          if (Number(current_CategoryID[1]) > 0) // Update Category
          {
            if (checkIfCategoryNameVaries(current_CategoryID[1], current_CategoryValue)) {
              promises.push(
                InsertUpdateProductCategory({
                  id: current_CategoryID[1],
                  venueId: venue,
                  code: current_CategoryValue?.toUpperCase().replace(/\s/g, ''),
                  description: current_CategoryValue?.toUpperCase().replace(/\s/g, '')
                }).then((result) => { return result; })
              );
            }
          }
          else // New Category
          {
            promises.push(
              InsertUpdateProductCategory({
                id: 0,
                venueId: venue,
                code: current_CategoryValue?.toUpperCase().replace(/\s/g, ''),
                description: current_CategoryValue?.toUpperCase().replace(/\s/g, '')
              }).then((result) => { return result; })
            );
          }

          // Insert/Update Products
          Array.from({ length: Productscount }, async (_, product_iteration) => {
            const current_ProductID = productRefs.current[`productInput_${iteration}_${product_iteration}`]?.placeholder.split('_');
            const current_ProductValue = productRefs.current[`productInput_${iteration}_${product_iteration}`]?.value;

            if (current_ProductID && current_ProductID.length >= 1 && current_ProductID[1]) {
              if (Number(current_ProductID[1]) > 0) // Insert Product
              {
                let updateParam = {};
                updateParam.id = current_ProductID[1];
                updateParam.venueid = venue;
                updateParam.categoryId = current_CategoryID[1];
                updateParam.title = current_ProductValue;
                updateParam.description = current_ProductValue;
                updateParam.sku = "string";
                updateParam.quantity = 1;
                updateParam.price = 1;
                updateParam.discount = 0;
                updateParam.createdBy = "string";
                updateParam.createdDate = Moment().format("YYYY-MM-DD[T]HH:mm:ss");
                updateParam.updatedBy = "string";
                updateParam.updatedDate = Moment().format("YYYY-MM-DD[T]HH:mm:ss");
                updateParam.isactive = true;

                promises.push(
                  InsertUpdateProduct(updateParam).then((result) => { return result; })
                );
              }
              else // Insert Product
              {
                let insertParam = {};
                insertParam.id = current_ProductID[1];
                insertParam.venueid = venue;
                insertParam.categoryId = current_CategoryID[1];
                insertParam.title = current_ProductValue;
                insertParam.description = current_ProductValue;
                insertParam.sku = "string";
                insertParam.quantity = 1;
                insertParam.price = 1;
                insertParam.discount = 0;
                insertParam.createdBy = "string";
                insertParam.createdDate = Moment().format("YYYY-MM-DD[T]HH:mm:ss");
                insertParam.updatedBy = "string";
                insertParam.updatedDate = Moment().format("YYYY-MM-DD[T]HH:mm:ss");
                insertParam.isactive = true;

                promises.push(
                  InsertUpdateProduct(insertParam).then((result) => { return result; })
                );
              }
            }
          });
        }
      });

      Promise.all(promises)
        .then(() => {
          setLoading(false);
          toast.show({
            render: () => <Box bg="emerald.500" px="2" py="1" rounded="sm" mb={5}>Categories updated successfully!</Box>
          });
          loadVenueData();
        });
    } else {
      setLoading(false);
    }
  }

  const cancelSubmit = () => { setIsMenuVenueInformationEdit(!isMenuVenueInformationEdit) }

  return <>
    {
      isReady ? <>
        {
          !productLoader ? <>

            <Flex flexDirection="row" alignItems="center" justifyContent="space-between" my="3" w={"100%"} mt={6}>
              <Box pl={!type ? "8" : "0"}>
                {!type && <Flex flexDirection="row" alignItems="center">
                  <Text bold fontSize="xl" w="100%" pr="2">Select Venue:</Text>

                  <Select
                    selectedValue={venue}
                    minWidth="300"
                    accessibilityLabel="Select Venue"
                    placeholder="Select Venue"
                    mt={1}
                    borderColor="#000000"
                    variant="outline"
                    onValueChange={(itemValue) => setVenue(Number(itemValue))}
                  >
                    {
                      venuesListWithPagingResponse.map((venueItem, venueIteration) => {
                        return <Select.Item
                          key={`${venueItem?.venueName}_${venueIteration}`}
                          label={venueItem?.venueName}
                          value={venueItem?.id}
                        />
                      })
                    }
                  </Select>
                </Flex>}{
                }
                {type && <Box>
                  <Heading size='lg'>Venue Menu</Heading>
                </Box>}
              </Box>
              {(!type || (type && productCategoryByVenueIDReponse && productCategoryByVenueIDReponse.length) > 0) &&
                <Box pr="4">
                  <Flex flexDirection="row" alignItems="center">
                    {!isMenuVenueInformationEdit ? <Button
                      background={primaryColors.primaryRed}
                      w="150"
                      variant="solid"
                      onPress={() => setIsMenuVenueInformationEdit(true)}
                      leftIcon={<Icon as={FontAwesome} name="edit" size="6" color="white" />}
                      _text={{
                        fontSize: 15
                      }}>Edit Menu</Button>
                      : <><Button
                        mt="2"
                        mr="2"
                        bgColor="#fad295"
                        color="#000000"
                        leftIcon={<Icon as={Ionicons} name={loading ? "refresh-circle" : "ios-save"} size="xs" />}
                        _text={{
                          color: "#000000"
                        }}
                        disabled={loading}
                        onPress={handleSubmit}
                      >Submit</Button>
                        <Button
                          mt="2"
                          bgColor="#fc1a17"
                          color="primary.50"
                          leftIcon={<Icon as={Ionicons} name="backspace" size="xs" />}
                          onPress={cancelSubmit}
                        >Cancel</Button></>}
                  </Flex>
                </Box>
              }
            </Flex>

            {
              !type && <Box
                mt="1"
                px="8"
                py="2"
                bg="#000"
                _text={{
                  fontSize: "xl",
                  fontWeight: "medium",
                  color: primaryColors.primaryRed,
                  letterSpacing: "lg",
                }}
              >
                Menu
              </Box>
            }

            <SlideFade in={isReady}>
              <Box px="8" py="4">{renderHeader(headerTitleOptions || [])}</Box>
              {
                productCategoryByVenueIDReponse && productCategoryByVenueIDReponse.length > 0 ? Array.from({ length: CategoriesCount }, (_, iteration) => {
                  return <Box
                    key={`category_product_list_${iteration}`}
                    px="8"
                    py="2">
                    {renderRowItem(iteration)}
                  </Box>
                }) : <Box px="8" py="2" textAlign="center" bg="#80808014"  >
                  <Text bold fontSize="lg"
                  >No Data Found</Text>
                </Box>
              }
            </SlideFade>

          </> : <SpinnerLoading />
        }
      </> : <SpinnerLoading />
    }
  </>
};

const mapStateToProps = (state) => {
  const { venuesListWithPagingResponse } = state.venue;
  const { productCategoryByVenueIDReponse, loading } = state.productCategory;

  return {
    venuesListWithPagingResponse,
    productCategoryByVenueIDReponse,
    productLoader: loading
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    GetAllVenueDetailsWithPaging: (data, all) => dispatch(VenueActions.GetAllVenueDetailsWithPaging(data, all)).then((response) => response),
    FetchProducCategoryByVenueID: (venueID) => dispatch(ProductActions.FetchProducCategoryByVenueID(venueID)).then((response) => response),
    FetchProduct: (categoryId) => dispatch(ProductCategoryActions.FetchProduct(categoryId)).then((response) => response),
    InsertUpdateProductCategory: (data) => dispatch(ProductCategoryActions.InsertUpdateProductCategory(data)).then((response) => response),
    InsertUpdateProduct: (data) => dispatch(ProductCategoryActions.InsertUpdateProduct(data)).then((response) => response),
  }
}

MenuScreen.defaultProps = {
  headerTitleOptions: [
    { key: '0_Categories', label: "Categories", width: "15%" },
    { key: '1_SubItem_UpTo6', label: `Sub-Item (up to 6)`, width: "85%" }
  ],
  CategoriesCount: 6,
  Productscount: 6
}

MenuScreen.propTypes = {
  GetAllVenueDetailsWithPaging: PropTypes.func,
  venuesListWithPagingResponse: PropTypes.any,
  headerTitleOptions: PropTypes.any,
  CategoriesCount: PropTypes.number,
  Productscount: PropTypes.number,
  productCategoryByVenueIDReponse: PropTypes.array,
  FetchProduct: PropTypes.func,
  FetchProducCategoryByVenueID: PropTypes.func,
  InsertUpdateProductCategory: PropTypes.func,
  InsertUpdateProduct: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuScreen);


