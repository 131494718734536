import React, { useState, useEffect } from 'react';
import {
  Box,
  Icon,
  Flex,
  Button,
  Heading,
  Divider,
  FormControl,
  Input,
  useToast,
  TextArea,
} from 'native-base';
import { StyleSheet, Image } from 'react-native';
import * as ImagePicker from 'expo-image-picker';
import { connect } from 'react-redux';
import { Ionicons } from '@expo/vector-icons';
import PropTypes from 'prop-types';

// Redux
import * as AppSettingsActions from '../../../shared/redux/actions/appSettings';

const Settings = (props) => {
  const [Id, setId] = useState(1);
  const [logo, setLogo] = useState(null);
  const [image, setImage] = useState(null);
  const [googlePlaystoreURL, setGooglePlaystoreURL] = useState('');
  const [appleStoreURL, setAppleStoreURL] = useState('');
  const [facebookURL, setFacebookURL] = useState('');
  const [instagramURL, setInstagramURL] = useState('');
  const [twitterURL, setTwitterURL] = useState('');
  const [emailConfirmation, setEmailConfirmation] = useState('');
  const [disclaimer, setDisclaimer] = useState('');

  const toast = useToast();
  const { PostAppSettings, GetAppSettingsById, appsettingResponse } = props;
  useEffect(() => {
    loadAppSettingsDetail();
  }, [Id]);

  const loadAppSettingsDetail = async () => {
    if (Id && Id > 0) {
      const response = await GetAppSettingsById(Id || 0).then((result) => {
        return result;
      });
      return response;
    }
  };

  useEffect(() => {
    if (appsettingResponse) {
      const {
        id,
        title,
        mainImagePath,
        backgroundImagePath,
        appleplaystoreurl,
        googleplaystoreurl,
        facebookUrl,
        instagramUrl,
        twitterUrl,
        emailConfirmation,
        disclaimer,
      } = appsettingResponse;

      setLogo(mainImagePath || '');
      setImage(backgroundImagePath || '');
      setGooglePlaystoreURL(googleplaystoreurl || '');
      setAppleStoreURL(appleplaystoreurl || '');
      setFacebookURL(facebookUrl || '');
      setInstagramURL(instagramUrl || '');
      setTwitterURL(twitterUrl || '');
      setEmailConfirmation(emailConfirmation || '');
      setDisclaimer(disclaimer || '');
    }
  }, [appsettingResponse]);

  const logoUpload = async () => {
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: (ImagePicker.MediaTypeOptions.Images = 'Images'),
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
    });
    if (!result.cancelled) {
      setLogo(result.uri);
    }
  };

  const backgroundUpload = async () => {
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: (ImagePicker.MediaTypeOptions.Images = 'Images'),
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
    });

    if (!result.cancelled) {
      setImage(result.uri);
    }
  };

  const HandleSubmit = async () => {
    let data = {};
    data = {
      Id: 1,
      MainImagePath: logo,
      BackgroundImagePath: image,
      appleplaystoreurl: appleStoreURL,
      googleplaystoreurl: googlePlaystoreURL,
      facebookUrl: facebookURL,
      instagramUrl: instagramURL,
      twitterUrl: twitterURL,
      emailConfirmation: emailConfirmation,
      disclaimer: disclaimer,
    };
    const PostAppResponse = await PostAppSettings(data, 'lg');
    if (PostAppResponse?.data?.status === 'Success') {
      toast.show({
        render: () => (
          <Box bg="emerald.500" px="2" py="1" rounded="sm" mb={5}>
            App Settings Added Successfully!
          </Box>
        ),
      });
      Id
        ? await loadAppSettingsDetail(Id)
        : navigation.dispatch(StackActions.pop(1));
    } else {
      toast.show({
        render: () => (
          <Box bg="error.600" px="2" py="1" rounded="sm" mb={5}>
            Failed! Please try again later
          </Box>
        ),
      });
    }
  };

  return (
    <Box>
      <Box px="8" pt="4">
        <Heading size="lg">App Configuration</Heading>
        <Divider my="3" bg="muted.400" />
      </Box>

      <Box pl="5">
        {/* <Box px="8" my="4" flexDirection="row" alignItems="flex-start">
          <FormControl isRequired>
            <FormControl.Label
              _text={{
                fontSize: 'md',
                color: 'black',
                fontWeight: 'bold',
              }}
            >
              Logo
            </FormControl.Label>
            {logo ? (
              <Box maxWidth="100" flexDirection="row" alignItems="flex-start">
                <Image
                  source={{ uri: logo }}
                  alt="logo"
                  style={styles.previewlogo}
                />
                <Button
                  variant="unstyled"
                  style={styles.logoCloseBtn}
                  onPress={() => setLogo(null)}
                >
                  <Icon
                    as={Ionicons}
                    name="close-circle"
                    size="10"
                    color="black"
                  />
                </Button>
              </Box>
            ) : (
              <Button
                w="200"
                size="sm"
                leftIcon={
                  <Icon
                    as={Ionicons}
                    name="cloud-upload"
                    size="3"
                    color="white"
                  />
                }
                title="Upload Image"
                onPress={logoUpload}
              >
                Upload Image
              </Button>
            )}
            <FormControl.HelperText>
              This image will shown as application logo
            </FormControl.HelperText>
          </FormControl>
        </Box> */}

        {/* <Box px="8" my="4" flexDirection="row" alignItems="flex-start">
        <FormControl isRequired>
          <FormControl.Label
            _text={{
              fontSize: 'md',
              color: "black",
              fontWeight: "bold"
            }}
          >
            Background
          </FormControl.Label>
          {
            image ?
              <Box maxWidth="150" flexDirection="row" alignItems="flex-start">
                <Image source={{ uri: image }} alt="logo" style={styles.previewbg} />
                <Button variant="unstyled" style={[styles.logoCloseBtn]} onPress={() => setImage(null)}>
                  <Icon as={Ionicons} name="close-circle" size="10" color="black" />
                </Button>
              </Box>
              :
              <Button
                w="200"
                size="sm"
                leftIcon={<Icon as={Ionicons} name="cloud-upload" size="3" color="white" />}
                title="Upload Background"
                onPress={backgroundUpload}
              >
                Upload Background
              </Button>
          }
          <FormControl.HelperText>This image will shown as application background</FormControl.HelperText>
        </FormControl>
      </Box> */}

        <Box px="8" my="4" flexDirection="row" alignItems="flex-start">
          <FormControl isRequired>
            <FormControl.Label
              _text={{
                fontSize: 'md',
                color: 'black',
                fontWeight: 'bold',
              }}
            >
              Google Playstore URL
            </FormControl.Label>
            <Input
              variant="filled"
              value={googlePlaystoreURL || ''}
              w="lg"
              selectionColor="white"
              onChange={(event) => setGooglePlaystoreURL(event.target.value)}
            />
            <FormControl.HelperText>
              Google Playstore URL
            </FormControl.HelperText>
          </FormControl>
        </Box>

        <Box px="8" my="4" flexDirection="row" alignItems="flex-start">
          <FormControl isRequired>
            <FormControl.Label
              _text={{
                fontSize: 'md',
                color: 'black',
                fontWeight: 'bold',
              }}
            >
              Apple Playstore URL
            </FormControl.Label>
            <Input
              variant="filled"
              value={appleStoreURL || ''}
              w="lg"
              selectionColor="white"
              onChange={(event) => setAppleStoreURL(event.target.value)}
            />
            <FormControl.HelperText>
              App Store URL / TestFlight URL
            </FormControl.HelperText>
          </FormControl>
        </Box>

        {/* <Box px="8" my="4" flexDirection="row" alignItems="flex-start">
        <FormControl isRequired>
          <FormControl.Label
            _text={{
              fontSize: 'md',
              color: "black",
              fontWeight: "bold"
            }}
          >
            Facebook URL
          </FormControl.Label>
          <Input
            variant="filled"
            value={facebookURL || ""}
            w="lg"
            selectionColor="white"
            onChange={(event) => setFacebookURL(event.target.value)}
          />
        </FormControl>
      </Box> */}

        {/* <Box px="8" my="4" flexDirection="row" alignItems="flex-start">
        <FormControl isRequired>
          <FormControl.Label
            _text={{
              fontSize: 'md',
              color: "black",
              fontWeight: "bold"
            }}
          >
            Instagram URL
          </FormControl.Label>
          <Input
            variant="filled"
            value={instagramURL || ""}
            w="lg"
            selectionColor="white"
            onChange={(event) => setInstagramURL(event.target.value)}
          />
        </FormControl>
      </Box> */}
        {/* 
      <Box px="8" my="4" flexDirection="row" alignItems="flex-start">
        <FormControl isRequired>
          <FormControl.Label
            _text={{
              fontSize: 'md',
              color: "black",
              fontWeight: "bold"
            }}
          >
            Twitter URL
          </FormControl.Label>
          <Input
            variant="filled"
            value={twitterURL || ""}
            w="lg"
            selectionColor="white"
            onChange={(event) => setTwitterURL(event.target.value)}
          />
        </FormControl>
      </Box> */}

        {/* <Box px="8" my="4" flexDirection="row" alignItems="flex-start">
          <FormControl isRequired>
            <FormControl.Label
              _text={{
                fontSize: 'md',
                color: 'black',
                fontWeight: 'bold',
              }}
            >
              Email Confirmation
            </FormControl.Label>
            <TextArea
              variant="filled"
              totalLines={10}
              value={emailConfirmation || ''}
              w="100%"
              selectionColor="white"
              onChange={(event) => setEmailConfirmation(event.target.value)}
            />
          </FormControl>
        </Box> */}

        <Box px="8" my="4" flexDirection="row" alignItems="flex-start">
          <FormControl isRequired>
            <FormControl.Label
              _text={{
                fontSize: 'md',
                color: 'black',
                fontWeight: 'bold',
              }}
            >
              Disclaimer
            </FormControl.Label>
            <TextArea
              variant="filled"
              totalLines={10}
              value={disclaimer || ''}
              w="100%"
              selectionColor="white"
              onChange={(event) => setDisclaimer(event.target.value)}
            />
          </FormControl>
        </Box>

        <Flex
          flexDirection="row"
          alignItems="center"
          justifyContent="flex-start"
          w="100%"
          mx="5"
          px="2"
        >
          <Button
            mt="2"
            mr="2"
            bgColor="#fad295"
            color="#000000"
            w="100"
            leftIcon={<Icon as={Ionicons} name="ios-save" size="xs" />}
            _text={{
              color: '#000000',
            }}
            onPress={() => HandleSubmit()}
          >
            Save
          </Button>
        </Flex>
      </Box>
    </Box>
  );
};

const styles = StyleSheet.create({
  placeholderlogo: {
    borderWidth: 1,
    borderColor: 'black',
    backgroundColor: '#eee',
    width: '30%',
    height: 200,
    marginTop: 50,
  },
  button: {
    width: '80%',
    marginTop: 20,
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  previewlogo: {
    width: 100,
    height: 100,
    borderRadius: 15,
  },
  previewbg: {
    width: 150,
    height: 150,
    borderRadius: 15,
  },
  logoCloseBtn: {
    position: 'absolute',
    top: -20,
    right: -25,
    cursor: 'pointer',
  },
});
Settings.defaultProps = {
  appsettingResponse: [],
};

Settings.propTypes = {
  appsettingResponse: PropTypes.array,
};

const mapStateToProps = (state) => {
  const { appsettingResponse } = state.appsetting;

  return {
    appsettingResponse,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    GetAppSettingsById: (Id) =>
      dispatch(AppSettingsActions.GetAppSettingsById(Id)).then(
        (response) => response
      ),
    PostAppSettings: (data, type) =>
      dispatch(AppSettingsActions.PostAppSettings(data, type)).then(
        (response) => response
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
