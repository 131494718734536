/* eslint-disable no-unused-vars */
import axios from 'axios';

// Init :: Config
import { API_URL } from '../../config';

// API Endpoints
import * as API_Interface from '../../services/apiURLs';

// Redux :: Action Types
import {
  PRODUCT_IDLE,
  PRODUCT_PENDING,
  PRODUCT_FAILED,
  PRODUCT_SUCCEEDED,
  // Product Category (By Venue ID)
  PRODUCT_CATEGORY_BY_VENUE_ID_IDLE,
  PRODUCT_CATEGORY_BY_VENUE_ID_PENDING,
  PRODUCT_CATEGORY_BY_VENUE_ID_FAILED,
  PRODUCT_CATEGORY_BY_VENUE_ID_SUCCEEDED,
  // Product  (By  ID)
  PRODUCT_BY_ID_IDLE,
  PRODUCT_BY_ID_PENDING,
  PRODUCT_BY_ID_FAILED,
  PRODUCT_BY_ID_SUCCEEDED,

  // Product  (By Category ID)
  PRODUCT_BY_CATEGORY_ID_IDLE,
  PRODUCT_BY_CATEGORY_ID_PENDING,
  PRODUCT_BY_CATEGORY_ID_FAILED,
  PRODUCT_BY_CATEGORY_ID_SUCCEEDED,
  // Order item
  GET_ORDER_ITEMS_BY_RESERVATION_ID_IDLE,
  GET_ORDER_ITEMS_BY_RESERVATION_ID_PENDING,
  GET_ORDER_ITEMS_BY_RESERVATION_ID_FAILED,
  GET_ORDER_ITEMS_BY_RESERVATION_ID_SUCCEEDED,
  // Delete Order item
  DELETE_ORDER_ITEMS_IDLE,
  DELETE_ORDER_ITEMS_PENDING,
  DELETE_ORDER_ITEMS_FAILED,
  DELETE_ORDER_ITEMS_SUCCEEDED,
  // Insert Order item
  INSERT_ORDER_BY_RESERVATION_ID_IDLE,
  INSERT_ORDER_BY_RESERVATION_ID_PENDING,
  INSERT_ORDER_BY_RESERVATION_ID_FAILED,
  INSERT_ORDER_BY_RESERVATION_ID_SUCCEEDED,
  // Send Order item
  SEND_ORDER_ITEMS_IDLE,
  SEND_ORDER_ITEMS_PENDING,
  SEND_ORDER_ITEMS_FAILED,
  SEND_ORDER_ITEMS_SUCCEEDED,
} from '../constants';

// Other Redux Actions
import { setAppLoading } from '../actions/common';

const FetchProduct = (categoryId) => async (dispatch) => {
  dispatch({ type: PRODUCT_PENDING, payload: true });
  try {
    const response = await axios({
      method: "GET",
      baseURL: API_URL,
      url: `${API_Interface.GET_PRODUCT_DETAIL}` + categoryId,
    }).then((response) => response)
      .catch((error) => error.response)
    if (response.status !== 200) {
      dispatch({ type: PRODUCT_IDLE, payload: response });
    }
    else if (response.status == 417) {
      await dispatch({ type: PRODUCT_FAILED, payload: response.data });
    } else {
      await dispatch({ type: PRODUCT_SUCCEEDED, payload: response.data });
    }
    return response;
  } catch (exception) {
    dispatch({ type: PRODUCT_FAILED, payload: exception });
  }
}


const FetchProductById = (Id) => async (dispatch) => {
  dispatch({ type: PRODUCT_BY_ID_PENDING, payload: true });
  try {
    const response = await axios({
      method: "GET",
      baseURL: API_URL,
      url: `${API_Interface.GET_PRODUCT_BY_ID}` + Id,
    }).then((response) => response)
      .catch((error) => error.response)
    if (response.status !== 200) {
      dispatch({ type: PRODUCT_BY_ID_IDLE, payload: response });
    }
    else if (response.status == 417) {
      await dispatch({ type: PRODUCT_BY_ID_FAILED, payload: response.data });
    } else {
      await dispatch({ type: PRODUCT_BY_ID_SUCCEEDED, payload: response.data });
    }
    return response;
  } catch (exception) {
    dispatch({ type: PRODUCT_BY_ID_FAILED, payload: exception });
  }
}

const FetchProducCategoryByVenueID = (venueID) => async (dispatch) => {
  dispatch({ type: PRODUCT_CATEGORY_BY_VENUE_ID_PENDING, payload: true });
  try {
    const response = await axios({
      method: "GET",
      baseURL: API_URL,
      url: `${API_Interface.GET_PRODUCT_CATEGORY_BY_VENUE_ID}` + venueID,
    }).then((response) => response)
      .catch((error) => error.response);

    if (response.status !== 200) {
      dispatch({ type: PRODUCT_CATEGORY_BY_VENUE_ID_IDLE, payload: response });
    } else if (response.status == 417) {
      await dispatch({ type: PRODUCT_CATEGORY_BY_VENUE_ID_FAILED, payload: response.data });
    } else {
      await dispatch({ type: PRODUCT_CATEGORY_BY_VENUE_ID_SUCCEEDED, payload: response.data });
    }

    return response;
  } catch (exception) {
    dispatch({ type: PRODUCT_CATEGORY_BY_VENUE_ID_FAILED, payload: exception });
  }
}

const FetchProductByCategoryID = (CategoryId) => async (dispatch) => {
  dispatch({ type: PRODUCT_BY_CATEGORY_ID_PENDING, payload: true });
  try {
    const response = await axios({
      method: "GET",
      baseURL: API_URL,
      url: `${API_Interface.GET_PRODUCT_BY_CATEGORY_ID}` + CategoryId,
    }).then((response) => response)
      .catch((error) => error.response);

    if (response.status !== 200) {
      dispatch({ type: PRODUCT_BY_CATEGORY_ID_IDLE, payload: response });
    } else if (response.status == 417) {
      await dispatch({ type: PRODUCT_BY_CATEGORY_ID_FAILED, payload: response.data });
    } else {
      await dispatch({ type: PRODUCT_BY_CATEGORY_ID_SUCCEEDED, payload: response.data });
    }

    return response;
  } catch (exception) {
    dispatch({ type: PRODUCT_BY_CATEGORY_ID_FAILED, payload: exception });
  }
}

const FetchOrderItem = (reservationId) => async (dispatch) => {
  dispatch({ type: GET_ORDER_ITEMS_BY_RESERVATION_ID_PENDING, payload: true });
  try {
    const response = await axios({
      method: "GET",
      baseURL: API_URL,
      url: `${API_Interface.GET_ORDER_ITEMS_BY_RESERVATION_ID}` + reservationId,
    }).then((response) => response)
      .catch((error) => error.response)
    if (response.status !== 200) {
      dispatch({ type: GET_ORDER_ITEMS_BY_RESERVATION_ID_IDLE, payload: response });
    }
    else if (response.status == 417) {
      await dispatch({ type: GET_ORDER_ITEMS_BY_RESERVATION_ID_FAILED, payload: response.data });
    } else {
      await dispatch({ type: GET_ORDER_ITEMS_BY_RESERVATION_ID_SUCCEEDED, payload: response.data });
    }
    return response;
  } catch (exception) {
    dispatch({ type: GET_ORDER_ITEMS_BY_RESERVATION_ID_FAILED, payload: exception });
  }
}

const DeleteOrderItem = (orderItemId) => async (dispatch) => {
  dispatch(setAppLoading(true));
  dispatch({ type: DELETE_ORDER_ITEMS_PENDING, payload: true });
  try {
    const response = await axios({
      method: "POST",
      baseURL: API_URL,
      url: `${API_Interface.DELETE_ORDER_ITEMS}` + orderItemId,
    }).then((response) => response)
      .catch((error) => error.response)
    if (response.status !== 200) {
      dispatch({ type: DELETE_ORDER_ITEMS_IDLE, payload: response });
      dispatch(setAppLoading(false));
    }
    else if (response.status == 417) {
      await dispatch({ type: DELETE_ORDER_ITEMS_FAILED, payload: response.data });
      dispatch(setAppLoading(false));
    } else {
      await dispatch({ type: DELETE_ORDER_ITEMS_SUCCEEDED, payload: response.data });
      dispatch(setAppLoading(false));
    }
    return response;
  } catch (exception) {
    dispatch({ type: DELETE_ORDER_ITEMS_FAILED, payload: exception });
    dispatch(setAppLoading(false));
  }
}

const InsertOrderItem = (data) => async (dispatch) => {
  dispatch(setAppLoading(true));
  dispatch({ type: INSERT_ORDER_BY_RESERVATION_ID_PENDING, payload: true });
  try {
    const response = await axios({
      method: "POST",
      baseURL: API_URL,
      url: API_Interface.INSERT_ORDER_BY_RESERVATION,
      data: data,
    }).then((response) => response)
      .catch((error) => error.response)
    if (response.status !== 200) {
      dispatch({ type: INSERT_ORDER_BY_RESERVATION_ID_IDLE, payload: response });
      dispatch(setAppLoading(false));
    }
    else if (response.status == 417) {
      await dispatch({ type: INSERT_ORDER_BY_RESERVATION_ID_FAILED, payload: response.data });
      dispatch(setAppLoading(false));
    } else {
      await dispatch({ type: INSERT_ORDER_BY_RESERVATION_ID_SUCCEEDED, payload: response.data });
      dispatch(setAppLoading(false));
    }
    return response;
  } catch (exception) {
    dispatch({ type: INSERT_ORDER_BY_RESERVATION_ID_FAILED, payload: exception });
    dispatch(setAppLoading(false));
  }
}

const SendOrderItem = (reservationID) => async (dispatch) => {
  dispatch(setAppLoading(true));
  dispatch({ type: SEND_ORDER_ITEMS_PENDING, payload: true });
  try {
    const response = await axios({
      method: "POST",
      baseURL: API_URL,
      url: `${API_Interface.SEND_ORDER_ITEMS}id=` + reservationID,
    }).then((response) => response)
      .catch((error) => error.response)
    if (response.status !== 200) {
      dispatch({ type: SEND_ORDER_ITEMS_IDLE, payload: response });
      dispatch(setAppLoading(false));
    }
    else if (response.status == 417) {
      await dispatch({ type: SEND_ORDER_ITEMS_FAILED, payload: response.data });
      dispatch(setAppLoading(false));
    } else {
      await dispatch({ type: SEND_ORDER_ITEMS_SUCCEEDED, payload: response.data });
      dispatch(setAppLoading(false));
    }
    return response;
  } catch (exception) {
    dispatch({ type: SEND_ORDER_ITEMS_FAILED, payload: exception });
    dispatch(setAppLoading(false));
  }
}

export {
  //  FetchProductList,
  FetchProduct,
  FetchProducCategoryByVenueID,
  FetchOrderItem,
  DeleteOrderItem,
  InsertOrderItem,
  SendOrderItem,
  FetchProductById,
  FetchProductByCategoryID,
}

