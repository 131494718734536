import React, { useEffect, useState } from "react";
import { Flex, Box, Heading, HStack, Button, Icon, Text, Center, Divider, useToast, label, Switch, Input, Row, Alert } from 'native-base';
import { FontAwesome } from '@expo/vector-icons';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

/* ----- REDUX :: ACTIONS ----- */
import * as VenueActions from '../../../shared/redux/actions/venue';
import { SpinnerLoading } from '../../../shared/templates/SpinnerLoading';

const VenueDeposite = (props) => {
	const { venueDepositebyvenueId, venueDepositewithPagingResponse, venueDepositewithPagingLoading, headerTitleOptions, venueId, GetVenueDepositSettings, InsertUpdateDepositeSettings } = props;

	const toast = useToast();

	const item = venueDepositewithPagingResponse;
	const [isActiveDepositEnabled, setActiveDepositStatus] = useState(false);
	const [isUseAmount, setUseAmount] = useState(false);
	const [isUsePer, setUsePer] = useState(false);
	const [isDepositeAmt, setDepositeAmt] = useState();
	const [isPreAmt, setPreAmt] = useState();

	console.log("$TLoad", venueDepositewithPagingLoading);

	useEffect(() => {
		if (venueDepositewithPagingResponse) {
			setActiveDepositStatus(venueDepositewithPagingResponse?.requireDepositOnBooking || false);
		}
	}, [venueDepositewithPagingResponse]);


	useEffect(() => {
		if (venueDepositewithPagingResponse) {
			setUsePer(venueDepositewithPagingResponse?.isPercentage || false);
		}
	}, [venueDepositewithPagingResponse]);


	useEffect(() => {
		if (venueDepositewithPagingResponse) {
			setUseAmount(venueDepositewithPagingResponse?.isAmount || false);
		}
	}, [venueDepositewithPagingResponse])


	useEffect(() => {
		if (venueDepositewithPagingResponse) {
			setDepositeAmt(venueDepositewithPagingResponse?.depositAmount || 0);
		}
	}, [venueDepositewithPagingResponse])

	useEffect(() => {
		if (venueDepositewithPagingResponse) {
			setPreAmt(venueDepositewithPagingResponse?.depositPercentage || 0);
		}
	}, [venueDepositewithPagingResponse])


	const data = { "VenueId": venueId, "RequireDepositOnBooking": isActiveDepositEnabled, "IsAmount": isUseAmount, "IsPercentage": isUsePer, "DepositAmount": isDepositeAmt, "DepositPercentage": isPreAmt };


	useEffect(() => {
		if (venueId > 0) {
			loadDepositeSettings(venueId);
		}
	}, [venueId]);



	const updateDepositeSettings = async (data) => {

		toast.closeAll();
		if (data) {
			const UpdateDeposite_Response = await InsertUpdateDepositeSettings(data).then((response) => response);
			if (UpdateDeposite_Response?.data?.status === "Success") {
				toast.show({
					render: () => <Box bg="emerald.500" px="2" py="1" rounded="sm" mb={5}>Deposits Updated Successfully!</Box>
				});
				await loadDepositeSettings(venueId);
			}
			setTimeout(() => {
				toast.closeAll();
			}, 1000);
		}
	}

	const loadDepositeSettings = async (venueId) => {

		if (venueId) {
			await GetVenueDepositSettings(venueId);
		}
	}


	return <>

		<Flex flexDirection="row" alignItems="center" mt="4" pb="4" w="75%">
			<Heading size='lg'>Deposits</Heading>
		</Flex>
		{!venueDepositewithPagingLoading}
		<>
			<Flex
				px={3}
				py={2}
				bg="#dcdcdc"
				borderRadius={15}
				flexDirection="row"
				justifyContent="space-between"
				alignItems="center"
				w={"90%"}
			>

				<Flex flexDirection="row" alignItems="center">
					<Text bold fontSize="lg">Activate Deposits</Text>
					<Box px={2}>
						<Switch
							color="secondary"
							size="md"
							isChecked={isActiveDepositEnabled}
							onToggle={(status) => setActiveDepositStatus(status)}
						/>
					</Box>
				</Flex>

				<Flex flexDirection="row" alignItems="center">
					<Text bold fontSize="lg">Use $ Amount</Text>
					<Box px={2}>
						<Switch
							color="secondary"
							size="md"
							isChecked={isUseAmount}
							onToggle={(status) => setUseAmount(status == false ? setUsePer(true) : setUsePer(false) && status == true ? setUsePer(false) : true)}
						/>
					</Box>
					<Box px={2}>
						<Input
							type="number"
							inputMode="numeric"
							backgroundColor="white"
							size="xs"
							pl="3"
							pr="3"
							pt="1"
							pb="1"
							mr="2"
							value={(isDepositeAmt)}
							paddingRight="1px"
							paddingTop="5px"
							fontSize="14px"
							keyboardType="numeric"
							fontWeight="bold"
							style={{ marginRight: "-100px" }}
							onChange={(event) => setDepositeAmt(event.target.value)}
							InputLeftElement={<Icon as={FontAwesome} name="dollar" size="12px" color="black" pl={2} pr={2} />}
						></Input>
					</Box>
				</Flex>

				<Flex flexDirection="row" alignItems="center">
					<Text bold fontSize="lg">Use % Amount</Text>
					<Box px={2}>
						<Switch
							color="secondary"
							size="md"
							isChecked={isUsePer}
							onToggle={(status) => setUsePer(status == false ? setUseAmount(true) : setUseAmount(false) && status == true ? setUseAmount(false) : true)}
						/>
					</Box>
					<Box px={2}>
						<Input
							type="number"
							backgroundColor="white"
							inputMode="numeric"
							size="xs"
							pl="3"
							pr="3"
							pt="1"
							pb="1"
							mr="2"
							value={isPreAmt}
							keyboardType="numeric"
							fontWeight="bold"
							paddingRight="1px"
							paddingTop="5px"
							fontSize="14px"
							style={{ marginRight: "-100px" }}
							onChange={(event) => setPreAmt(event.target.value)}
							InputLeftElement={<Icon as={FontAwesome} name="percent" size="12px" color="black" pl={2} pr={2} />}
						></Input>
					</Box>
				</Flex>

				<Button.Group size="sm">
					<Button
						variant="solid"
						colorScheme='blue'
						size="xs"
						pl="3"
						pr="3"
						pt="1"
						pb="1"
						mr="2"
						borderRadius="full"
						style={{ marginRight: "80px" }}
						leftIcon={<Icon as={FontAwesome} name="edit" size="xs" />}
						onPress={() => updateDepositeSettings(data)}
						_text={{
							fontSize: 'sm',
							color: 'white'
						}}
						fontSize="14px"
					>
						Update Deposit
					</Button>
				</Button.Group>
			</Flex>
		</>
	</>;
}


VenueDeposite.propTypes = {
	venueDepositewithPagingResponse: PropTypes.object,
	venueId: PropTypes.any,
	GetVenueDepositeSettings: PropTypes.func,
	headerTitleOptions: PropTypes.array,
	venueDepositewithPagingLoading: PropTypes.bool
}


const mapStateToProps = (state) => {
	const { venueDepositewithPagingResponse, venueDepositewithPagingLoading } = state.venue;
	return {
		venueDepositewithPagingResponse,
		venueDepositewithPagingLoading,
		venueDeposite: venueDepositewithPagingResponse || []
	};
}


const mapDispatchToProps = (dispatch) => {
	return {
		GetVenueDepositSettings: (venueId) => dispatch(VenueActions.GetVenueDepositSettings(venueId)).then((response) => response),
		InsertUpdateDepositeSettings: (data) => dispatch(VenueActions.InsertOrUpdateVenueDeposite(data)).then((response) => response)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(VenueDeposite);