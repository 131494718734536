// Redux :: Action Types
import {
	CART_LOADING,
	ADD_TO_CART,
	UPDATE_CART,
	CLEAR_CART,
} from '../constants';

/* ----- INITIAL STATE ----- */
const initialState = {
	isCartLoading: false,
	cartErr: null,
	cartData: {}
};

/* ----- REDUCER ----- */
const cartReducer = (state = initialState, action) => {
	switch (action.type) {

		case CART_LOADING:
			return {
				...state,
				isCartLoading: action.payload
			}

		case ADD_TO_CART:
			return {
				...state,
				cartData: action.payload
			};

		case UPDATE_CART:
			return {
				...state,
				cartData: action.payload
			}

		case CLEAR_CART:
			return {
				...state,
				isCartLoading: false,
				cartErr: null,
				cartData: {}
			}

		default:
			return state;

	}
}

export default cartReducer;