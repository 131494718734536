// Redux :: Action Types
import {
  GET_USER_DETAIL,
  USER_SUCCEEDED,

  GET_ALL_USERS_IDLE,
  GET_ALL_USERS_PENDING,
  GET_ALL_USERS_FAILED,
  GET_ALL_USERS_SUCCEEDED,

  GET_USERS_BY_ID_IDLE,
  GET_USERS_BY_ID_PENDING,
  GET_USERS_BY_ID_FAILED,
  GET_USERS_BY_ID_SUCCEEDED,

} from '../constants';

/* ----- INITIAL STATE ----- */
const initialState = {
  userdetailsResponse: [],
  userResponse: [],

  usersListWithPagingLoading: false,
  usersListWithPagingIdleErr: [],
  usersListWithPagingResponse: [],
  usersListWithPagingErr: null,


  UserDetailLoading: false,
  vUserDetailIdleErr: [],
  UserDetailResponse: [],
  UserDetailErr: null,
};

/* ----- REDUCER ----- */
const userReducer = (state = initialState, action) => {
  switch (action.type) {

    case GET_USER_DETAIL:
      return {
        ...state,
        userdetailsResponse: action.payload
      };

    case USER_SUCCEEDED:
      return {
        ...state,
        userResponse: action.payload
      };

    case GET_ALL_USERS_PENDING:
      return {
        ...state,
        usersListWithPagingLoading: action.payload
      };

    case GET_ALL_USERS_IDLE:
      return {
        ...state,
        usersListWithPagingIdleErr: action.payload
      };

    case GET_ALL_USERS_SUCCEEDED:
      return {
        ...state,
        usersListWithPagingResponse: action.payload
      };

    case GET_ALL_USERS_FAILED:
      return {
        ...state,
        usersListWithPagingErr: action.payload
      };

    case GET_USERS_BY_ID_PENDING:
      return {
        ...state,
        UserDetailLoading: true,
        UserDetailResponse: [],
      };

    case GET_USERS_BY_ID_IDLE:
      return {
        ...state,
        UserDetailLoading: true,
        vUserDetailIdleErr: action.payload
      };

    case GET_USERS_BY_ID_SUCCEEDED:
      return {
        ...state,
        UserDetailLoading: true,
        UserDetailResponse: action.payload
      };

    case GET_USERS_BY_ID_FAILED:
      return {
        ...state,
        UserDetailLoading: true,
        UserDetailErr: action.payload
      };

    default:
      return state;

  }
}

export default userReducer;