import React, { useState, useEffect, useRef } from 'react';
import { ScrollView } from 'react-native';
import { Box, Flex, Button, Icon, Spacer, useToast } from 'native-base';
import { FontAwesome } from '@expo/vector-icons';
import { connect } from 'react-redux';
import Moment from 'moment';
import PropTypes from 'prop-types';

/* ----- COMPONENTS ----- */
import Linking from '../../../shared/templates/customLinking';
import { ReportModal } from '../../../shared/templates';
import ViewVenue from './viewVenue';
import VenueReservationList from './venueReservationList';
import VenueUsers from './venueUsers';
import VenueCategory from './venueCategory';

/* ----- UTILS ----- */
import { primaryColors } from '../../../shared/utils/colors';

/* ----- REDUX :: ACTIONS ----- */
import * as VenueActions from '../../../shared/redux/actions/venue';
import * as CommonActions from '../../../shared/redux/actions/common';

const VenueDetails = (props) => {
  const { route, GetVenueDetailsById, FetchCity, FetchState, loginResponse,
    VenuesDetailLoading, VenuesDetailResponse, GetVenueCacheReportByVenueId, DownloadCacheReportForVenue
  } = props;

  const [isReady] = useState(true);
  const [venueID, setVenueID] = useState(0);
  const [modal, setModal] = useState(false)
  const toast = useToast();
  const reportModalRef = useRef();

  useEffect(() => {
    if ((route?.params && loginResponse?.user?.id)) {
      const { id } = route.params;
      setVenueID(id || 0);
    } else{
        Linking.openURL(`/Login`);
        setVenueID(0);
    }
  }, [route?.params]);

  useEffect(() => {
    if (venueID && venueID > 0) {
      loadVenueDetail();
      loadCityLookup();
      loadStateLookup();
    }
  }, [venueID]);

  const loadVenueDetail = async () => {
    await GetVenueDetailsById(venueID || 0).then((result) => { return result; });
  }

  const loadCityLookup = async () => {
    await FetchCity().then((result) => { return result; });
  }

  const loadStateLookup = async () => {
    await FetchState().then((result) => { return result; });
  }

  const redirectURL = (redirectBaseURL, isTargetBlank) => {
    if (isTargetBlank) {
      Linking.openURL(redirectBaseURL, '_blank')
    } else {
      Linking.openURL(redirectBaseURL)
    }
  }

  const DownloadCacheReport = async (fromDate, toDate) => {
    await DownloadCacheReportForVenue((venueID || 0), fromDate, toDate).then((result) => { return result; });
  }
  const HandleToast = (msg, bg) => {
    toast.show({
      render: () => <Box bg={bg} px="2" py="1" rounded="sm" mb={5}>{msg}</Box>
    });
  }

  // const getCacheReportForVenueList = async () => {
  //   toast.closeAll();
  //   if (venueID && venueID > 0) {
  //     const GetVenueCacheReportByVenueId_Response = await GetVenueCacheReportByVenueId(venueID).then((response) => response);
  //     if (GetVenueCacheReportByVenueId_Response && GetVenueCacheReportByVenueId_Response?.status == 200) {
  //       if (GetVenueCacheReportByVenueId_Response?.data && GetVenueCacheReportByVenueId_Response?.data?.length > 0) {
  //         reportModalRef.current.toggleModal(GetVenueCacheReportByVenueId_Response?.data);
  //       } else {
  //         reportModalRef.current.toggleModal([]);
  //       }
  //     } else {
  //       toast.show({
  //         render: () => <Box bg="error.600" px="2" py="1" rounded="sm" mb={5}>Failed! Something went wrong. Please try again.</Box>
  //       });
  //     }
  //   }
  // }

  const { userRoles } = loginResponse;

  return <>
    {
      isReady ? <ScrollView contentContainerStyle={{ flexGrow: 1, backgroundColor: 'white' }}>
        {
          (venueID && venueID > 0) ?
            <Box bg="#FFF" pb="10">
              <Flex flexDirection="row" alignItems="center" justifyContent="space-between" my="1" px="6" w={"100%"} mt={6}>
                {
                  !userRoles?.includes("VenueAdmin") && (
                    <Button
                      size="sm"
                      leftIcon={<Icon as={FontAwesome} name="chevron-circle-left" size="6" color="info.600" />}
                      onPress={() => redirectURL(`/Venue`, false)}
                      bg="transparent"
                      _text={{
                        color: "info.600",
                        fontSize: 15
                      }}
                      colorScheme='info.600'
                    >
                      Back to Venues
                    </Button>
                  )
                }
                <Spacer />
                <Box>
                  <Button
                    background={primaryColors.primaryRed}
                    w="200"
                    mr="2"
                    variant="solid"
                    onPress={() => redirectURL(`/ReservationReport/${venueID}`, false)}
                    leftIcon={<Icon as={FontAwesome} name="list-alt" size="6" color="white" />}
                    _text={{
                      fontSize: 15
                    }}>
                   Venue Reports
                  </Button>
                </Box>
                <Box>
                  <Button
                    background={primaryColors.primaryRed}
                    w="200"
                    mr="2"
                    variant="solid"
                    onPress={() => redirectURL(`/VenueWebsiteOperations/${venueID}`, false)}
                    leftIcon={<Icon as={FontAwesome} name="cog" size="6" color="white" />}
                    _text={{
                      fontSize: 15
                    }}>
                    Operations
                  </Button>
                </Box>
                {/* <Box>
                  <Button
                    background={primaryColors.primaryRed}
                    w="200"
                    variant="solid"
                    onPress={() => setModal(true)}
                    leftIcon={<Icon as={FontAwesome} name="list-alt" size="6" color="white" />}
                    _text={{
                      fontSize: 15
                    }}
                  >
                    Cache Report
                  </Button>
                </Box> */}
              </Flex>

              <Box
                mt="1"
                px="8"
                py="2"
                bg="#000"
                minHeight="10"
                _text={{
                  fontSize: "xl",
                  fontWeight: "medium",
                  color: primaryColors.primaryRed,
                  letterSpacing: "lg",
                }}
              >
                {VenuesDetailResponse?.venueName}
              </Box>

              <Box px="8" py="2">
                <ViewVenue />
              </Box>

              <Box px="8" py="2">
                <VenueUsers
                  venueId={venueID || 0}
                  HandleToast={(msg, bg) => HandleToast(msg, bg)}
                />
              </Box>

              {/* {modal && <ReportModal
                // ref={reportModalRef}
                venueId={venueID}
                setCurrentDate={(todate) => setDate(todate)}
                title="Cache Report"
                downloadCSV={(fromDate, toDate) => DownloadCacheReport(fromDate, toDate)}
                onCloseReportModal={(status) => setModal(status)}
              />} */}

            </Box> : null
        }
      </ScrollView> : null
    }
  </>
};

const mapStateToProps = (state) => {
  const { loginResponse } = state.auth;
  const {
    VenuesDetailLoading,
    VenuesDetailResponse,
  } = state.venue;

  return {
    loginResponse,
    VenuesDetailLoading,
    VenuesDetailResponse
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    GetVenueDetailsById: (venueId) => dispatch(VenueActions.GetVenueDetailsById(venueId)).then((response) => response),
    FetchCity: () => dispatch(CommonActions.FetchCity()).then((response) => response),
    FetchState: () => dispatch(CommonActions.FetchState()).then((response) => response),
    GetCacheReportDetailsByDate: (venueid, fromDate, toDate) => dispatch(VenueActions.GetCacheReportDetailsByDate(venueid, fromDate, toDate)).then((response) => response),
    GetVenueCacheReportByVenueId: (Id) => dispatch(VenueActions.GetVenueCacheReportByVenueId(Id)).then((response) => response),
    DownloadCacheReportForVenue: (Id, fromDate, toDate) => dispatch(VenueActions.DownloadCacheReportForVenue(Id, fromDate, toDate)).then((response) => response),
  }
}

VenueDetails.propTypes = {
  route: PropTypes.any,
  GetVenueDetailsById: PropTypes.func,
  GetCacheReportDetailsByDate: PropTypes.func,
  VenuesDetailLoading: PropTypes.any,
  VenuesDetailResponse: PropTypes.any,
  GetVenueCacheReportByVenueId: PropTypes.func,
  loginResponse: PropTypes.any,
};

export default connect(mapStateToProps, mapDispatchToProps)(VenueDetails);
