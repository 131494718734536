// Redux :: Action Types
import {
  GET_APP_SETTINGS_DETAIL_PENDING,
  GET_APP_SETTINGS_DETAIL_IDLE,
  GET_APP_SETTINGS_DETAIL_FAILED,
  GET_APP_SETTINGS_DETAIL_SUCCEEDED,

} from '../constants';

/* ----- INITIAL STATE ----- */
const initialState = {
  loading: false,
  idleErr: null,
  appsettingErr: null,
  appsettingResponse: null,
}

/* ----- REDUCER ----- */
const appsettingReducer = (state = initialState, action) => {
  switch (action.type) {

    case GET_APP_SETTINGS_DETAIL_PENDING:
      return {
        ...state,
        loading: true,
        appsettingResponse: null
      };
    case GET_APP_SETTINGS_DETAIL_IDLE:
      return {
        ...state,
        loading: false,
        idleErr: action.payload
      };
    case GET_APP_SETTINGS_DETAIL_SUCCEEDED:
      return {
        ...state,
        loading: false,
        appsettingResponse: action.payload
      };

    case GET_APP_SETTINGS_DETAIL_FAILED:
      return {
        ...state,
        loading: false,
        appsettingErr: action.payload
      };

    default:
      return state;

  }
}





export {
  appsettingReducer
}
