import React, { useEffect, useState } from "react";
import { FormControl, Input, Text, Modal, Button, useToast, Hidden } from 'native-base';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

/* ----- REDUX :: ACTIONS ----- */
import * as ProductCategoryActions from '../../../shared/redux/actions/productCategory';
import * as ProductActions from '../../../shared/redux/actions/product';
import * as VenueActions from '../../../shared/redux/actions/venue';

const addOrEditProductModal = (props) => {
  const { GetProductById, Id, ProductCategoryDetail, PostProduct, modalType, handleClose, venueid,
    ProductId, CatId, GetProductCategoryByVenueId, route } = props;
  const [UserId, setUserId] = useState("");
  const [VenueId, setVenueId] = useState(venueid);
  const [Product, setProduct] = useState("");
  const [Title, setTitle] = useState("");
  const [CategoryId, setCategoryId] = useState(CatId);
  const [isEditModal, setEditModal] = useState(false);

  useEffect(() => {
    loadProductbyid();
  }, []);

  useEffect(() => {
    if (modalType) {
      setEditModal(modalType === "EDIT");
    }
  }, [modalType]);

  useEffect(() => {
    setVenueId(venueid || '');
    setCategoryId(CatId || '');
    setProduct(Product || '');
  }, [ProductCategoryDetail]);

  const loadProductbyid = async () => {
    const response = await GetProductById(ProductId || 0).then((result) => { return result; });
    return response;
  }
  const loadProductCategory = async () => {
    const response = await GetProductCategoryByVenueId(VenueId || 0).then((result) => { return result; });
    return response;
  }


  const ValidateFields = () => {

    if (
      (Product && Product !== "")
    ) {
      return true;
    }
    return false;
  }


  const HandleSubmit = async () => {
    toast.closeAll();


    if (ValidateFields()) {
      let param = {
        Venueid: VenueId || 0,
        CategoryId: CategoryId || 0,
        Title: Title || "",
        Description: Product || "",
        Quantity: 1,
        Isactive: true
      };
      console.log("param", param);
      const Post_Response = await PostProduct(param).then((response) => response);
      if (Post_Response?.data?.status === "Success") {
        toast.show({
          render: () => <Box bg="emerald.500" px="2" py="1" rounded="sm" mb={5}>Product Added Successfully!</Box>
        });
        loadProductCategory();
      } else {
        toast.show({
          render: () => <Box bg="error.600" px="2" py="1" rounded="sm" mb={5}>Failed! Please try again later</Box>
        });
      }
    } else {
      toast.show({
        render: () => <Box bg="error.600" px="2" py="1" rounded="sm" mb={5}>Please fill all required fields</Box>
      });
    }
  }

  const toast = useToast();

  return <Modal.Content>
    <Modal.Header>{modalType === "EDIT" ? "Product Update" : "Product Details"}</Modal.Header>
    <Modal.Body>
      <FormControl>
        <FormControl.Label><Text bold>Name</Text></FormControl.Label>
        {
          modalType === "EDIT" ? <Input
            variant="filled"
            value={Product || ""}
            onChange={(event) => {
              setProduct(event.target.value)
              setTitle(event.target.value)
            }}
            selectionColor="white"
          /> : <Text>{Product || "-"}</Text>
        }
      </FormControl>
    </Modal.Body>
    <Modal.Footer>
      <Button.Group space={2}>
        <Button
          bg="muted.300"
          borderRadius="lg"
          onPress={() => handleClose(false, "CLOSE")}
          _hover={{
            bg: "muted.300"
          }}
        >
          Close
        </Button>
        {
          modalType === "EDIT" ? <Button
            // colorScheme="muted.300"
            borderRadius="lg"
            onPress={() => {
              HandleSubmit();
              handleClose(false, "SAVE");
            }}
          >
            Save
          </Button> : <></>
        }
      </Button.Group>
    </Modal.Footer>
  </Modal.Content>;
}

addOrEditProductModal.propTypes = {
  loginResponse: PropTypes.any,
  PostProductCategory: PropTypes.func,
  navigation: PropTypes.any,
  ProductCategoryDetail: PropTypes.any,
  modalType: PropTypes.string,
  handleClose: PropTypes.func
}

const mapStateToProps = (state) => {
  const { UserDetailResponse } = state.user;

  return {
    ProductCategoryDetail: UserDetailResponse || [],
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    PostProduct: (postreq) => dispatch(ProductCategoryActions.InsertUpdateProduct(postreq)).then((response) => response),
    GetProductById: (Id) => dispatch(ProductActions.FetchProductById(Id)).then((response) => response),
    GetProductCategoryByVenueId: (VenueId) => dispatch(VenueActions.GetProductCategoryByVenueId(VenueId)).then((response) => response),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(addOrEditProductModal);