/* eslint-disable react-native/no-raw-text */
import React, { useEffect, useState } from 'react';
import {
  Flex,
  Text,
  Icon,
  Box,
  HStack,
  Button,
  Heading,
  Divider,
  Center
} from 'native-base';
import { FontAwesome } from '@expo/vector-icons';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Redux :: Components
import { ImageUpload } from '../../../shared/templates';
import VenueScript from './venueScript';
import Linking from '../../../shared/templates/customLinking';

// Redux :: Components
import { primaryColors } from '../../../shared/utils/colors';

const ViewVenue = (props) => {
  const { venueList } = props;

  const [isReady] = useState(true);
  const [VenueName, setVenueName] = useState('');
  const [Address, setAddress] = useState('');
  const [City, setCity] = useState('');
  const [State, setState] = useState('');
  const [ZipCode, setZipCode] = useState('');
  const [VenueContact, setVenueContact] = useState('');
  const [ContactEmail, setContactEmail] = useState('');
  const [phoneNumber, setphoneNumber] = useState('');
  const [venueImagePath, setvenueImagePath] = useState('');
  const [venueFilePath, setvenueFilePath] = useState('');
  const [gmEmail, setGmEmail] = useState('');
  const [gmName, setGMName] = useState('');
  const [gmPhone, setGMPhone] = useState('');

  useEffect(() => {
    setVenueName(venueList?.venueName || '');
    setAddress(venueList?.address || '');
    setCity(venueList?.cityName || '');
    setState(venueList?.stateName || '');
    setZipCode(venueList?.postCode || '');
    setVenueContact(venueList?.contactPerson || '');
    setContactEmail(venueList?.email || '');
    setphoneNumber(venueList?.mobile || '');
    setvenueImagePath(venueList?.imagePath || '');
    setvenueFilePath(venueList?.venueMapPath || '');
    setGmEmail(venueList?.gmEmail || '');
    setGMName(venueList?.gmName || '');
    setGMPhone(venueList?.gmPhone || '');
  }, [venueList]);

  const redirectURL = (redirectBaseURL, isTargetBlank) => {
    if (isTargetBlank) {
      Linking.openURL(redirectBaseURL, '_blank');
    } else {
      Linking.openURL(redirectBaseURL);
    }
  };

  return (
    <>
      {isReady ? (
        <>
          <Flex flexDirection="row" alignItems="center" mt="8" w={'100%'}>
            <Heading size="lg">Venue Information</Heading>
            <Button
              background={primaryColors.primaryRed}
              w="150"
              pl="3"
              pr="3"
              pt="1"
              pb="1"
              ml="2"
              mr="2"
              variant="outline"
              borderRadius="full"
              onPress={() =>
                redirectURL(`/AddOrEditVenue/${venueList?.id}`, false)
              }
              leftIcon={
                <Icon
                  as={FontAwesome}
                  name="edit"
                  size="xs"
                  fontWeight="bold"
                  color="white"
                />
              }
              _text={{
                fontSize: 'xs',
                fontWeight: 'bold',
                color: 'white',
              }}
            >
              Edit Details
            </Button>
          </Flex>

          <Divider my="3" bg="muted.400" />

          <Flex flexDirection="row" alignItems="flex-start">
            <Box w="80%">
              <HStack space={3} my="3" justifyContent="flex-start">
                <Box w="20%">
                  <Text bold italic fontSize="xl" mb="2">
                    Venue Name
                  </Text>
                  <Text pl="1">{VenueName || '-'}</Text>
                </Box>
                <Box w="20%">
                  <Text bold italic fontSize="xl" mb="2">
                    Address
                  </Text>
                  <Text pl="1">{Address || '-'}</Text>
                </Box>
                <Box w="20%">
                  <Text bold italic fontSize="xl" mb="2">
                    City
                  </Text>
                  <Text pl="1">{City || '-'}</Text>
                </Box>
                <Box w="20%">
                  <Text bold italic fontSize="xl" mb="2">
                    State
                  </Text>
                  <Text pl="1">{State || '-'}</Text>
                </Box>
              </HStack>
              <HStack space={3} my="3" justifyContent="flex-start">
                <Box w="20%">
                  <Text bold italic fontSize="xl" mb="2">
                    Zip Code
                  </Text>
                  <Text pl="1">{ZipCode || '-'}</Text>
                </Box>
                <Box w="20%">
                  <Text bold italic fontSize="xl" mb="2">
                    Venue GM
                  </Text>
                  <Text pl="1">{gmName || '-'}</Text>
                </Box>
                <Box w="20%">
                  <Text bold italic fontSize="xl" mb="2">
                    GM Email
                  </Text>
                  <Text pl="1">{gmEmail || '-'}</Text>
                </Box>
                <Box w="20%">
                  <Text bold italic fontSize="xl" mb="2">
                    GM Phone Number
                  </Text>
                  <Text pl="1">{gmPhone || '-'}</Text>
                </Box>
                <Box w="20%" />
              </HStack>

              <Box w="80%">
                <Divider my="3" bg="muted.400" />
                <Heading>Venue Contact</Heading>

                <HStack space={3} my="3" justifyContent="flex-start">
                  <Box w="20%">
                    <Text bold italic fontSize="xl" mb="2">
                      Name
                    </Text>
                    <Text pl="1">{VenueContact || '-'}</Text>
                  </Box>
                  <Box w="20%">
                    <Text bold italic fontSize="xl" mb="2">
                      Phone
                    </Text>
                    <Text pl="1">{phoneNumber || '-'}</Text>
                  </Box>
                  <Box w="20%">
                    <Text bold italic fontSize="xl" mb="2">
                      Email
                    </Text>
                    <Text pl="1">{ContactEmail || '-'}</Text>
                  </Box>
                </HStack>
              </Box>

            </Box>
            <Box w="15%">
              <Center>
                <ImageUpload imageuri={venueImagePath} type='image' isEdit={false} />
              </Center>
              <Center>
                <ImageUpload imageuri={venueFilePath} type='map' isEdit={false} />
              </Center>
            </Box>
          </Flex>

          <Box>
            <VenueScript />
          </Box>
        </>
      ) : null}
    </>
  );
};

const mapStateToProps = (state) => {
  const { VenuesDetailResponse } = state.venue;
  const { loginResponse } = state.auth;

  return {
    venueList: VenuesDetailResponse || [],
    loginResponse: loginResponse || '-',
  };
};

ViewVenue.propTypes = {
  loginResponse: PropTypes.any,
  navigation: PropTypes.any,
  venueList: PropTypes.any,
};

export default connect(mapStateToProps, null)(ViewVenue);
