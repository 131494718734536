/* eslint-disable react-native/no-raw-text */
import React, { useEffect, useState, useRef } from 'react';
import { StyleSheet, Text } from 'react-native';
import {
  Flex,
  Input,
  Icon,
  FormControl,
  Box,
  Center,
  Fade,
  Button,
  useToast,
  Heading,
  Divider,
  VStack
} from 'native-base';
import Moment from 'moment';
import { Ionicons } from '@expo/vector-icons';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { StackActions } from '@react-navigation/native';
import Select from 'react-select';

// Redux :: Actions
import * as VenueActions from '../../../shared/redux/actions/venue';
import * as CommonActions from '../../../shared/redux/actions/common';

//Redux :: Components
import { SpinnerLoading } from '../../../shared/templates/SpinnerLoading';
import { ImageUpload } from '../../../shared/templates';
import Linking from '../../../shared/templates/customLinking';
import * as ImagePicker from 'expo-image-picker';

// Helpers
import { toBase64 } from '../../../shared/utils/common';
import ImagePlaceholder from '../../../shared/templates/ImagePlaceholder'

const AddOrEditVenue = (props) => {
  const toast = useToast();
  const {
    PostVenue,
    navigation,
    type,
    venueList,
    route,
    loader,
    venueInformationEdit,
    GetVenueDetailsById,
    stateLookUp,
    cityLookUp,
    FetchCity,
    FetchState,
  } = props;

  const [isReady] = useState(true);
  const [VenueId, setVenueId] = useState(0);
  const [VenueName, setVenueName] = useState('');
  const [Address, setAddress] = useState('');
  const [CityId, setCityId] = useState(0);
  const [City, setCity] = useState('');
  const [State, setState] = useState('');
  const [Country, setCountry] = useState('');
  const [ZipCode, setZipCode] = useState('');
  const [VenueContact, setVenueContact] = useState('');
  const [ContactEmail, setContactEmail] = useState('');
  const [phoneNumber, setphoneNumber] = useState('');
  const [venueImagePath, setvenueImagePath] = useState('');
  const [venueFilePath, setvenueFilePath] = useState('');
  const [gmEmail, setGmEmail] = useState('');
  const [gmName, setGMName] = useState('');
  const [gmPhone, setGMPhone] = useState('');

  const ref = useRef();
  const handleFileSelect = (event) => {
    setvenueFilePath(event);
  };

  useEffect(() => {
    LoadCityLookup();
    LoadStateLookup();
  }, []);

  useEffect(() => {
    if (route?.params) {
      const { id } = route?.params;
      setVenueId(id || 0);
      loadVenueDetail(id || 0);
    }
  }, [route?.params]);


  useEffect(() => {
    setVenueId(venueList?.id || 0);
    setVenueName(venueList?.venueName || '');
    setAddress(venueList?.address || '');
    setCity(venueList?.cityName?.toString() || '');
    setCityId(venueList?.city || 0);
    setState(venueList?.state?.toString() || '');
    setZipCode(venueList?.postCode || '');
    setVenueContact(venueList?.contactPerson || '');
    setContactEmail(venueList?.email || '');
    setphoneNumber(venueList?.mobile || '');
    setCountry(venueList?.country || '');
    setvenueImagePath(venueList?.imagePath || '');
    setvenueFilePath(venueList?.venueMapPath || '');
    setGmEmail(venueList?.gmEmail || '');
    setGMName(venueList?.gmName || '');
    setGMPhone(venueList?.gmPhone || '');
  }, [venueList]);

  const ValidateFields = () => {
    if (
      VenueName &&
      VenueName !== '' &&
      Address &&
      Address !== '' &&
      City &&
      City !== '' &&
      State &&
      State !== '' &&
      ZipCode &&
      ZipCode !== '' &&
      VenueContact &&
      VenueContact !== '' &&
      ContactEmail &&
      ContactEmail !== '' &&
      phoneNumber &&
      phoneNumber !== ''
    ) {
      return true;
    }

    return false;
  };

  const LoadCityLookup = async () => {
    await FetchCity().then((result) => {
      return result;
    });
  };

  const LoadStateLookup = async () => {
    await FetchState().then((result) => {
      return result;
    });
  };

  const loadVenueDetail = async (id) => {
    if (id && id > 0) {
      await GetVenueDetailsById(id || 0).then((result) => {
        return result;
      });
    }
  };

  function generatePassword() {
    var length = 8,
      charset =
        'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789',
      retVal = '';
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  }

  const redirectURL = (redirectBaseURL, isTargetBlank) => {
    if (isTargetBlank) {
      Linking.openURL(redirectBaseURL, '_blank');
    } else {
      Linking.openURL(redirectBaseURL);
    }
  };

  const HandleSubmit = async () => {
    debugger;
    const { loginResponse } = props;
    let randompass = generatePassword();
    toast.closeAll();

    if (loginResponse && loginResponse !== '' && ValidateFields()) {
      var widgetcode1 =
        '<a id="btn_VIP_Reserve" style=""  href="http://localhost:4200/' +
        VenueId +
        '/">Book VIP Reservation</a>';
      let postVenueParam = {
        id: VenueId || 0,
        venueAdminUserid: loginResponse?.user.id || '',
        venueName: VenueName?.length > 0 ? VenueName : '',
        Name: VenueName?.length > 0 ? VenueName : '',
        description: VenueName?.length > 0 ? VenueName : '',
        address: Address?.length > 0 ? Address : '',
        city: CityId?.value || CityId,
        cityName: City?.value || City,
        state: State?.value || State,
        country: Country || 1,
        postCode: ZipCode || '',
        zipcode: ZipCode || '',
        landMark: 'string',
        telePhone: phoneNumber?.length > 0 ? phoneNumber : '',
        contactPerson: VenueContact?.length > 0 ? VenueContact.trim() : '',
        mobile: phoneNumber?.length > 0 ? phoneNumber : '',
        PhoneNumber: phoneNumber?.length > 0 ? phoneNumber : '',
        contactmobile: phoneNumber?.length > 0 ? phoneNumber : '',
        contactemail: ContactEmail?.length > 0 ? ContactEmail.trim() : '',
        Email: ContactEmail?.length > 0 ? ContactEmail?.trim() : '',
        UserName: ContactEmail?.length > 0 ? ContactEmail.trim() : '',
        Password: randompass || '',
        isactive: true,
        UserType: 3,
        lastModifiedDate: Moment().format('YYYY-MM-DD[T]HH:mm:ss'),
        date: Moment().format('YYYY-MM-DD[T]HH:mm:ss'),
        widgetcode: widgetcode1,
        ImagePath: venueImagePath,
        FilePath: venueFilePath,
        gmEmail: gmEmail?.length > 0 ? gmEmail : '',
        gmName: gmName?.length > 0 ? gmName : '',
        gmPhone: gmPhone?.length > 0 ? gmPhone : ''
      };

      const PostVenue_Response = await PostVenue(postVenueParam).then((response) => response);

      if (PostVenue_Response?.status === 200) {
        toast.show({
          render: () => (
            <Box bg="emerald.500" px="2" py="1" rounded="sm" mb={5}>
              {VenueId && VenueId > 0
                ? 'Venue Added Successfully!'
                : 'Venue Updated Successfully'}
            </Box>
          ),
        });
        navigation.reset({
          index: 0,
          routes: [{ name: 'Venue' }],
        });
      } else if (
        PostVenue_Response?.status === 417 &&
        PostVenue_Response?.data?.message
      ) {
        toast.show({
          render: () => (
            <Box bg="error.600" px="2" py="1" rounded="sm" mb={5}>
              {PostVenue_Response?.data?.message}
            </Box>
          ),
        });
      } else {
        toast.show({
          render: () => (
            <Box bg="error.600" px="2" py="1" rounded="sm" mb={5}>
              Failed! Please try again later
            </Box>
          ),
        });
      }
    } else {
      toast.show({
        render: () => (
          <Box bg="error.600" px="2" py="1" rounded="sm" mb={5}>
            Please fill all required fields
          </Box>
        ),
      });
    }
  };

  const CancelSubmit = async () => {
    if (VenueId) {
      (await loadVenueDetail(VenueId)) && venueInformationEdit(false);
    }
    navigation.reset({
      index: 0,
      routes: [{ name: 'Venue' }],
    });
  };

  const getSelectedOption = (ID, selectedFieldvalue, WholeArr) => {
    let selectedOption = WholeArr.find((arrayInstance) => {
      return String(arrayInstance[selectedFieldvalue]) === String(ID);
    });

    return selectedOption;
  };

  return (
    <>
      {isReady ? (
        <Fade in={isReady}>
          <Flex
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            w={'100%'}
            mt={6}
          >
            {VenueId ? (
              <Box px="8">
                <Heading size="lg">Update Venue</Heading>
              </Box>
            ) : (
              <Box px="8">
                <Heading size="lg">New Venue</Heading>
              </Box>
            )}
          </Flex>

          <Box mx="3" my="3">
            <Divider my="3" bg="muted.400" />
          </Box>

          <Flex flexDirection="row" alignItems="flex-start">
            <Box w="80%">
              {/* Venue Information */}
              <Box>
                <Heading size="lg" px={!type ? '8' : '0'}>
                  Venue Information
                </Heading>
                <Flex
                  flexDirection="row"
                  alignItems="center"
                  px={!type ? '8' : '0'}
                  w="100%"
                  mt={6}
                >
                  <Box style={styles.inputFieldWrapper}>
                    <FormControl isInvalid={false} isRequired>
                      <FormControl.Label>Venue Name</FormControl.Label>
                      <Input
                        variant="filled"
                        minLength={4}
                        value={VenueName || ''}
                        onChange={(event) => setVenueName(event.target.value)}
                        selectionColor="white"
                      />
                    </FormControl>
                  </Box>
                  <Box style={styles.inputFieldWrapper}>
                    <FormControl isInvalid={false} isRequired>
                      <FormControl.Label>Address</FormControl.Label>
                      <Input
                        variant="filled"
                        value={Address || ''}
                        onChange={(event) => setAddress(event.target.value)}
                        selectionColor="white"
                      />
                    </FormControl>
                  </Box>
                  <Box style={styles.inputFieldWrapper}>
                    <FormControl isInvalid={false} isRequired>
                      <FormControl.Label>City</FormControl.Label>
                      <Input
                        variant="filled"
                        value={City || ''}
                        onChange={(event) => setCity(event.target.value)}
                        selectionColor="white"
                      />
                    </FormControl>
                  </Box>
                  <Box style={styles.inputFieldWrapper}>
                    <FormControl isInvalid={false} isRequired>
                      <FormControl.Label>State</FormControl.Label>
                      <Select
                        placeholder="Select State"
                        isClearable
                        value={getSelectedOption(
                          State,
                          'value',
                          stateLookUp || []
                        )}
                        onChange={(selectedOption) => setState(selectedOption)}
                        options={stateLookUp || []}
                        getOptionLabel={(option) => option.label}
                        getOptionValue={(option) => option.value}
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                      />
                    </FormControl>
                  </Box>
                </Flex>
                <Flex
                  flexDirection="row"
                  alignItems="center"
                  px={!type ? '8' : '0'}
                  w="100%"
                  mt={6}
                >
                  <Box style={styles.inputFieldWrapper}>
                    <FormControl isInvalid={false} isRequired>
                      <FormControl.Label>Zip Code</FormControl.Label>
                      <Input
                        variant="filled"
                        value={ZipCode || ''}
                        onChange={(event) => setZipCode(event.target.value)}
                        selectionColor="white"
                      />
                    </FormControl>
                  </Box>
                  <Box style={styles.inputFieldWrapper}>
                    <FormControl isInvalid={false} isRequired>
                      <FormControl.Label>Venue GM</FormControl.Label>
                      <Input
                        variant="filled"
                        value={gmName || ''}
                        onChange={(event) => setGMName(event.target.value)}
                        selectionColor="white"
                      />
                    </FormControl>
                  </Box>
                  <Box style={styles.inputFieldWrapper}>
                    <FormControl isInvalid={false} isRequired>
                      <FormControl.Label>GM Email</FormControl.Label>
                      <Input
                        variant="filled"
                        value={gmEmail || ''}
                        onChange={(event) => setGmEmail(event.target.value)}
                        selectionColor="white"
                      />
                    </FormControl>
                  </Box>
                  <Box style={styles.inputFieldWrapper}>
                    <FormControl isInvalid={false} isRequired>
                      <FormControl.Label>GM Phone Number</FormControl.Label>
                      <Input
                        variant="filled"
                        value={gmPhone || ''}
                        maxLength={10}
                        onChange={(event) => setGMPhone(event.target.value)}
                        selectionColor="white"
                      />
                    </FormControl>
                  </Box>
                </Flex>
              </Box>

              <Box mx="3" my="3" w="85%">
                <Divider my="3" bg="muted.400" />
              </Box>

              {/* Venue Contact */}
              <Box>
                <Heading size="lg" px={!type ? '8' : '0'}>
                  Venue Contact
                </Heading>
                <Flex
                  flexDirection="row"
                  alignItems="center"
                  px={!type ? '8' : '0'}
                  w="100%"
                  mt={6}
                >
                  <Box style={styles.inputFieldWrapper}>
                    <FormControl isInvalid={false} isRequired>
                      <FormControl.Label>Name</FormControl.Label>
                      <Input
                        variant="filled"
                        value={VenueContact || ''}
                        onChange={(event) => setVenueContact(event.target.value)}
                        selectionColor="white"
                      />
                    </FormControl>
                  </Box>
                  <Box style={styles.inputFieldWrapper}>
                    <FormControl isInvalid={false} isRequired>
                      <FormControl.Label>Phone</FormControl.Label>
                      <Input
                        variant="filled"
                        value={phoneNumber || ''}
                        maxLength={10}
                        onChange={(event) => setphoneNumber(event.target.value)}
                        selectionColor="white"
                      />
                    </FormControl>
                  </Box>
                  <Box style={styles.inputFieldWrapper}>
                    <FormControl isInvalid={false} isRequired>
                      <FormControl.Label>Email</FormControl.Label>
                      <Input
                        variant="filled"
                        value={ContactEmail || ''}
                        onChange={(event) =>
                          setContactEmail(event.target.value)
                        }
                        selectionColor="white"
                      />
                    </FormControl>
                  </Box>
                </Flex>
              </Box>
              <Flex
                flexDirection="row"
                alignItems="center"
                px={!type ? '8' : '0'}
                w="100%"
                mt={6}
              >
                <Button
                  mt="2"
                  mr="2"
                  bgColor="#fad295"
                  color="#000000"
                  w="100"
                  leftIcon={<Icon as={Ionicons} name="ios-save" size="xs" />}
                  _text={{
                    color: '#000000',
                  }}
                  onPress={HandleSubmit}
                >
                  Submit
                </Button>
                <Button
                  mt="2"
                  bgColor="#fc1a17"
                  color="primary.50"
                  w="100"
                  leftIcon={<Icon as={Ionicons} name="ios-close" size="xs" />}
                  onPress={CancelSubmit}
                >
                  Cancel
                </Button>
              </Flex>
            </Box>
            <VStack>
              <Box w="15%">
                <Center>
                  <ImageUpload
                    type="image"
                    imageuri={venueImagePath}
                    isEdit={true}
                    onChange={(path) => {
                      setvenueImagePath(path);
                    }}
                  />
                </Center>
              </Box>
              <Box w="15%">
                <Center>
                  <ImageUpload
                    type="map"
                    imageuri={venueFilePath}
                    isEdit={true}
                    onChange={handleFileSelect}
                  />
                </Center>
              </Box>
            </VStack>
          </Flex>
        </Fade>
      ) : (
        <Flex
          flexDirection="row"
          alignItems="center"
          my="4"
          px={!type ? '8' : '0'}
          w="90%"
          mt={6}
          mh="100%"
        >
          <SpinnerLoading />
        </Flex>
      )}
    </>
  );
};

AddOrEditVenue.propTypes = {
  loginResponse: PropTypes.any,
  PostVenue: PropTypes.func,
  navigation: PropTypes.any,
  venueList: PropTypes.any,
  stateLookUp: PropTypes.any,
};

const styles = StyleSheet.create({
  inputFieldWrapper: {
    width: '20%',
    marginRight: 30,
  },
  uploadImage: {
    width: 320,
    height: 200,
    borderRadius: 15,
    resizeMode: 'contain'
  }
});

const mapStateToProps = (state) => {
  const { VenuesDetailResponse, VenuesDetailLoading } = state.venue;
  const { loginResponse } = state.auth;
  const { countryLoading, countryLookUp } = state.country;
  const { stateLoading, stateLookUp } = state.countryState;
  const { cityLoading, cityLookUp } = state.city;

  return {
    venueList: VenuesDetailResponse || [],
    loginResponse: loginResponse || '',
    loader: VenuesDetailLoading,
    countryLoading: countryLoading,
    countryLookUp: countryLookUp,
    stateLoading: stateLoading,
    stateLookUp: stateLookUp,
    cityLoading: cityLoading,
    cityLookUp: cityLookUp,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    PostVenue: (postreq) =>
      dispatch(VenueActions.PostVenue(postreq)).then((response) => response),
    GetVenueDetailsById: (venueId) =>
      dispatch(VenueActions.GetVenueDetailsById(venueId)).then(
        (response) => response
      ),
    FetchCity: () =>
      dispatch(CommonActions.FetchCity()).then((response) => response),
    FetchState: () =>
      dispatch(CommonActions.FetchState()).then((response) => response),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddOrEditVenue);
