/* eslint-disable react-native/no-color-literals */
/* eslint-disable react-native/no-inline-styles */
/* eslint-disable react-native/no-raw-text */
import React, { useState, useEffect } from "react";
import { Box, Flex, Heading, Button, Icon, Divider, useToast } from 'native-base';
import { connect } from 'react-redux';
import { FontAwesome } from '@expo/vector-icons';
import Moment from "moment";
import Select from 'react-select';
import PropTypes from 'prop-types';

/* ----- Redux :: Actions ----- */
import * as VenueActions from '../../../shared/redux/actions/venue';

/* ----- COMPONENTS ----- */
import { SpinnerLoading } from '../../../shared/templates/SpinnerLoading';

/* ----- UTILS ----- */
import { primaryColors } from '../../../shared/utils/colors';
import { generateTimes } from '../../../shared/utils/common';

const VenueWeekView = (props) => {

  const toast = useToast();

  const {
    venueDetail,
    venueSettingByDayLoading,
    GetVenueSettingByDay,
    venueSettingByDayResponse,
    InsertOrUpdateVenueSettingByDay,
  } = props;

  const defaultValueOptionsArr = [
    { key: 'closed', label: 'Closed', value: 0 },
    { key: 'tier_1', label: 'Tier 1', value: 1 },
    { key: 'tier_2', label: 'Tier 2', value: 2 },
    { key: 'tier_3', label: 'Tier 3', value: 3 },
    { key: 'tier_4', label: 'Tier 4', value: 4 },
  ];

  const [timesArr, setTimesArr] = useState([]);
  const [weekViewArr, setWeekViewArr] = useState([
    {
      key: '02_monday',
      tagID: 2,
      label: 'Monday',
      dayOfWeek: 2,
      selectedStartTime: null,
      selectedStartTimeValue: null,
      selectedEndTime: null,
      selectedEndTimeValue: null,
      selectedDefaultOption: defaultValueOptionsArr[1],
      ratetierid: 1,
    },
    {
      key: '03_tuesday',
      tagID: 3,
      label: 'Tuesday',
      dayOfWeek: 3,
      selectedStartTime: null,
      selectedStartTimeValue: null,
      selectedEndTime: null,
      selectedEndTimeValue: null,
      selectedDefaultOption: defaultValueOptionsArr[1],
      ratetierid: 1,
    },
    {
      key: '04_wednesday',
      tagID: 4,
      label: 'Wednesday',
      dayOfWeek: 4,
      selectedStartTime: null,
      selectedStartTimeValue: null,
      selectedEndTime: null,
      selectedEndTimeValue: null,
      selectedDefaultOption: defaultValueOptionsArr[1],
      ratetierid: 1,
    },
    {
      key: '05_thursday',
      tagID: 5,
      label: 'Thursday',
      dayOfWeek: 5,
      selectedStartTime: null,
      selectedStartTimeValue: null,
      selectedEndTime: null,
      selectedEndTimeValue: null,
      selectedDefaultOption: defaultValueOptionsArr[1],
      ratetierid: 1,
    },
    {
      key: '06_friday',
      tagID: 6,
      label: 'Friday',
      dayOfWeek: 6,
      selectedStartTime: null,
      selectedStartTimeValue: null,
      selectedEndTime: null,
      selectedEndTimeValue: null,
      selectedDefaultOption: defaultValueOptionsArr[1],
      ratetierid: 1,
    },
    {
      key: '07_saturday',
      tagID: 7,
      label: 'Saturday',
      dayOfWeek: 7,
      selectedStartTime: null,
      selectedStartTimeValue: null,
      selectedEndTime: null,
      selectedEndTimeValue: null,
      selectedDefaultOption: defaultValueOptionsArr[1],
      ratetierid: 1,
    },
    {
      key: '01_sunday',
      tagID: 1,
      label: 'Sunday',
      dayOfWeek: 1,
      selectedStartTime: null,
      selectedStartTimeValue: null,
      selectedEndTime: null,
      selectedEndTimeValue: null,
      selectedDefaultOption: defaultValueOptionsArr[1],
      ratetierid: 1,
    },
  ]);
  const [defaultValueOptions] = useState(defaultValueOptionsArr);

  useEffect(() => {
    setTimesArr(generateTimes());
  }, []);

  useEffect(() => {
    (venueDetail?.id && venueDetail?.id > 0)
      && GetVenueSettingByDay(venueDetail?.id)
  }, [venueDetail?.id]);

  useEffect(() => {
    let tempWeekViewArr = [...weekViewArr] || [];

    if (venueSettingByDayResponse && venueSettingByDayResponse?.length > 0) {
      tempWeekViewArr = (tempWeekViewArr || [])?.map((weekViewItem) => {
        let selectedItemIndx = venueSettingByDayResponse.findIndex((x) => x?.dayOfWeek === weekViewItem?.tagID);
        if (selectedItemIndx > -1) {
          weekViewItem.dayOfWeek = venueSettingByDayResponse[selectedItemIndx]?.dayOfWeek;
          weekViewItem.selectedStartTime = venueSettingByDayResponse[selectedItemIndx]?.openTimeString;
          weekViewItem.selectedStartTimeValue = venueSettingByDayResponse[selectedItemIndx]?.openTime;
          weekViewItem.selectedEndTime = venueSettingByDayResponse[selectedItemIndx]?.closeTimeString;
          weekViewItem.selectedEndTimeValue = venueSettingByDayResponse[selectedItemIndx]?.closeTime;
          weekViewItem.selectedDefaultOption = venueSettingByDayResponse[selectedItemIndx]?.isClosed ? defaultValueOptions.find((x) => x?.value === 0)
            : defaultValueOptions.find((x) => {
              if (venueSettingByDayResponse[selectedItemIndx]?.ratetierid === 0) {
                return x?.value === 1;
              }
              return x?.value === venueSettingByDayResponse[selectedItemIndx]?.ratetierid
            });
        }

        return weekViewItem;
      });
    } else {
      tempWeekViewArr = (tempWeekViewArr || [])?.map((weekViewItem) => {
        weekViewItem.selectedDefaultOption = defaultValueOptions.find((x) => x?.value === 1);

        return weekViewItem;
      });
    }

    setWeekViewArr(tempWeekViewArr);
  }, [venueSettingByDayResponse]);

  const handleSelectChange = (fieldValue, fieldName, fieldSet) => {
    let tempWeekViewArr = [...weekViewArr] || [];
    let findIndexForSelectedValue = (tempWeekViewArr || [])?.findIndex((x) => x?.dayOfWeek === fieldSet?.dayOfWeek);
    if (findIndexForSelectedValue > -1) {

      if (fieldName === 'start') {
        tempWeekViewArr[findIndexForSelectedValue].selectedStartTime = fieldValue?.label;
        tempWeekViewArr[findIndexForSelectedValue].selectedStartTimeValue = Moment(fieldValue?.value, 'HH:mm').format('HH:mm:ss');
      }

      if (fieldName === 'end') {
        tempWeekViewArr[findIndexForSelectedValue].selectedEndTime = fieldValue?.label;
        tempWeekViewArr[findIndexForSelectedValue].selectedEndTimeValue = Moment(fieldValue?.value, 'HH:mm').format('HH:mm:ss');
      }

      if (fieldName === 'default_value') {
        tempWeekViewArr[findIndexForSelectedValue].selectedDefaultOption = fieldValue;
      }

      setWeekViewArr(tempWeekViewArr);
    }
  }

  const handleSubmit = async () => {
    toast.closeAll();

    let InsertOrUpdateVenueSettingByDayParams = {};
    InsertOrUpdateVenueSettingByDayParams.VenueId = venueDetail?.id;
    InsertOrUpdateVenueSettingByDayParams.settingByDays = weekViewArr?.map((weekItem) => {

      let settingParam = {};
      settingParam.DayOfWeek = weekItem?.dayOfWeek;

      settingParam.OpenTime = weekItem?.selectedStartTimeValue;
      settingParam.CloseTime = weekItem?.selectedEndTimeValue;

      settingParam.IsClosed = weekItem?.selectedDefaultOption?.value === 0 ? true : false;
      if (settingParam.IsClosed) {
        settingParam.ratetierid = 1;
      } else {
        settingParam.ratetierid = weekItem?.selectedDefaultOption?.value;
      }

      return settingParam;
    });

    const InsertOrUpdateVenueSettingByDayResponse = await InsertOrUpdateVenueSettingByDay(InsertOrUpdateVenueSettingByDayParams).then((res) => res);
    if (InsertOrUpdateVenueSettingByDayResponse?.data?.status === "Success") {
      (venueDetail?.id && venueDetail?.id > 0)
        && GetVenueSettingByDay(venueDetail?.id)
      toast.show({
        render: () => <Box bg="emerald.500" px="2" py="1" rounded="sm" mb={5}>{InsertOrUpdateVenueSettingByDayResponse?.data?.message}</Box>
      });
    } else {
      toast.show({
        render: () => <Box bg="error.600" px="2" py="1" rounded="sm" mb={5}>Updated Failed! Please Contact Your Administrator</Box>
      });
    }
  }

  return <>
    <Box pb="4">
      <Flex flexDirection="row" alignItems="center" mt="4" pb="4">
        <Heading size='lg' minWidth="200px">Venue Week View</Heading>
        {!venueSettingByDayLoading && (
          <Button
            background={primaryColors.primaryRed}
            w="150"
            pl="3"
            pr="3"
            pt="1"
            pb="1"
            ml="2"
            mr="2"
            variant="outline"
            borderRadius="full"
            onPress={() => handleSubmit()}
            leftIcon={<Icon as={FontAwesome} name="refresh" size="xs" fontWeight="bold" color="white" />}
            _text={{
              fontSize: 'xs',
              fontWeight: "bold",
              color: 'white'
            }}
          >
            Update
          </Button>
        )}
      </Flex>
      <Divider bg="muted.400" />
      {
        !venueSettingByDayLoading ? (
          <Flex flexDirection="row" alignItems="flex-start" mt="4" pb="4">
            {
              weekViewArr?.map((weekViewItem) => {
                return <Box
                  key={`${weekViewItem?.key}_${venueDetail?.id}`}
                  w="14%"
                  h="350"
                  mr={2}
                  borderWidth={2}
                  borderColor="black"
                >
                  <Box
                    bg="muted.400"
                    h='30'
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Heading size='md'>{weekViewItem.label || ''}</Heading>
                  </Box>
                  <Box
                    h='30'
                    pt={1.5}
                    pl={1.5}
                    mb={4}
                  >
                    <Heading size='sm'>Hours Of Operation:</Heading>
                  </Box>
                  <Box mx={2} mb={2}>
                    <Heading size='12' h="6">Start:</Heading>
                    <Select
                      placeholder=""
                      value={{
                        label: weekViewItem?.selectedStartTime,
                        value: weekViewItem?.selectedStartTimeValue
                      }}
                      onChange={(selectedOption) => handleSelectChange(selectedOption, 'start', weekViewItem)}
                      options={timesArr || []}
                      getOptionLabel={(option) => option.label}
                      getOptionValue={(option) => option.value}
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                    />
                  </Box>
                  <Box mx={2} mb={2}>
                    <Heading size='12' h="6">End:</Heading>
                    <Select
                      placeholder=""
                      value={{
                        label: weekViewItem?.selectedEndTime,
                        value: weekViewItem?.selectedEndTimeValue
                      }}
                      onChange={(selectedOption) => handleSelectChange(selectedOption, 'end', weekViewItem)}
                      options={timesArr || []}
                      getOptionLabel={(option) => option.label}
                      getOptionValue={(option) => option.value}
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                    />
                  </Box>
                  <Box mx={2} mb={2}>
                    <Heading size='12' w="full" h="6">Default Value:</Heading>
                    <Select
                      placeholder=""
                      value={weekViewItem?.selectedDefaultOption}
                      onChange={(selectedOption) => handleSelectChange(selectedOption, 'default_value', weekViewItem)}
                      options={defaultValueOptions || []}
                      getOptionLabel={(option) => option.label}
                      getOptionValue={(option) => option.value}
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                    />
                  </Box>
                </Box>
              })
            }
          </Flex>
        ) : (
          <SpinnerLoading />
        )
      }
    </Box>
  </>;
};

const mapStateToProps = (state) => {
  const {
    VenuesDetailResponse,
    venueSettingByDayLoading,
    venueSettingByDayResponse,
  } = state.venue;

  return {
    venueDetail: VenuesDetailResponse || [],
    venueSettingByDayLoading,
    venueSettingByDayResponse,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    GetVenueSettingByDay: (venueID) => dispatch(VenueActions.GetVenueSettingByDay(venueID)).then((response) => response),
    InsertOrUpdateVenueSettingByDay: (reqBody) => dispatch(VenueActions.InsertOrUpdateVenueSettingByDay(reqBody)).then((response) => response),
  };
};

VenueWeekView.propTypes = {
  venueDetail: PropTypes.any,
  GetVenueSettingByDay: PropTypes.func,
  route: PropTypes.any,
  venueSettingByDayLoading: PropTypes.bool,
  venueSettingByDayResponse: PropTypes.any,
  InsertOrUpdateVenueSettingByDay: PropTypes.any,
};

export default connect(mapStateToProps, mapDispatchToProps)(VenueWeekView);