// Redux :: Action Types
import {
  LOGIN_IDLE,
  LOGIN_PENDING,
  LOGIN_FAILED,
  LOGIN_SUCCEEDED,
  SIGNUP_IDLE,
  SIGNUP_PENDING,
  SIGNUP_FAILED,
  SIGNUP_SUCCEEDED,
  RESET_LOGIN_GROUP
} from '../constants';

/* ----- INITIAL STATE ----- */
const initialState = {
  loading: false,
  idleErr: null,
  loginResponse: [],
  loginErr: null,
  signupLoading: false,
  signupIdleErr: null,
  signupResponse: null,
  signupErr: null,
};

/* ----- REDUCER ----- */
const authReducer = (state = initialState, action) => {
  switch (action.type) {

    case RESET_LOGIN_GROUP:
      return { 
        ...initialState 
      };

    case LOGIN_PENDING:
      return {
        ...state,
        loading: true,
        loginResponse: []
      };

    case LOGIN_IDLE:
      return {
        ...state,
        loading: false,
        idleErr: action.payload
      };

    case LOGIN_SUCCEEDED:
      return {
        ...state,
        loading: false,
        loginResponse: action.payload
      };

    case LOGIN_FAILED:
      return {
        ...state,
        loading: false,
        loginErr: action.payload
      };

    //SIGNUP//
    case SIGNUP_PENDING:
      return {
        ...state,
        signuploading: true,
        signupResponse: []
      };

    case SIGNUP_IDLE:
      return {
        ...state,
        signuploading: false,
        signupIdleErr: action.payload
      };

    case SIGNUP_SUCCEEDED:
      return {
        ...state,
        signuploading: false,
        signupResponse: action.payload
      };

    case SIGNUP_FAILED:
      return {
        ...state,
        signuploading: false,
        signupErr: action.payload
      };

    default:
      return state;

  }
}

export default authReducer;