import React, { useState, forwardRef, useEffect } from 'react';
import {
  Center,
  Button,
  Modal,
  FlatList,
  Avatar,
  VStack,
  HStack,
  Box,
  Spacer,
  Text,
  Icon,
  Popover,
} from 'native-base';
import Moment from 'moment';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Calendar } from 'react-date-range';
import { MaterialIcons } from '@expo/vector-icons';

/* ----- UTILS ----- */
import { primaryColors } from '../utils/colors';
/* ----- REDUX :: ACTIONS ----- */
import * as VenueActions from '../../shared/redux/actions/venue';

const ReportModal = forwardRef((props, ref) => {
  const { title, downloadCSV, GetCacheReportDetailsByDate, venueId, headerTitleOptions, onCloseReportModal } = props;
  const [showModal, setShowModal] = useState(false);
  const [reports, setReports] = useState([]);
  const [isDateTriggered, setTriggerDate] = useState(false);
  const [fromDate, setFromDate] = useState(Moment().format('MM/DD/YYYY'));
  const [toDate, setToDate] = useState(Moment().format('MM/DD/YYYY'));
  const [isToDateTriggered, setTriggerToDate] = useState(false);

  useEffect(() => {
    const currentdate = new Date;
    getCacheReportListbyDate(Moment(currentdate).format('MM/DD/YYYY'));

  }, []);

  const getCacheReportListbyDate = async (date) => {
    if (venueId && venueId > 0) {
      const GetCacheReportByDate_Response = await GetCacheReportDetailsByDate(venueId, date, date).then((response) => response);
      if (GetCacheReportByDate_Response && GetCacheReportByDate_Response?.status == 200) {
        if (GetCacheReportByDate_Response?.data && GetCacheReportByDate_Response?.data?.length > 0) {
          setReports(GetCacheReportByDate_Response?.data);
          setShowModal(true);
        } else {
          setReports([]);
          setShowModal(true);
        }
      }
    }
  }

  const renderHeader = (headers) => {
    return <HStack justifyContent="flex-start" space={2} >
      {
        headers.map((item, iteration) => {
          return <Box w={item?.width} key={`${item?.key}_${iteration}`} h="10px" display="flex" flexDirection="row" alignItems="center" 
          justifyContent={(item?.label === "Request Time" || item?.label==="Table" || item?.label==="Guest") ? "center"
          :(item?.label === "Action Item"||item?.label==="Cache Status" || item?.label==="Cache Time")?"center"
          : "flex-end"}>
            <Center>
              <Text fontWeight="bold" italic>{item?.label}</Text>
            </Center>
          </Box>;
        })
      }
    </HStack>
  }

  const renderRowItem = (rowItem, rowIteration) => {
    return <HStack justifyContent="flex-start" space={2}>
      {
        headerTitleOptions.map((item, iteration) => {
          return <Box key={`${item?.width}_${rowIteration}_v1${iteration}`} w={`${item?.width}`}>
            {item?.label === "Request Time" && <Text fontSize="md" textAlign="right" marginRight="40px" >{rowItem.requestTime}</Text>}
            {item?.label === "Table" && <Text fontSize="md" textAlign="left" marginLeft="30px">{rowItem.tableName ? rowItem.tableName : ""}</Text>}
            {item?.label === "Guest" && <Text fontSize="md" flexDirection="row" textAlign="center" marginLeft="30px">{rowItem.guest ? rowItem.guest : ""}</Text>}
            {item?.label === "Action Item" && <Text fontSize="md" textAlign="left" marginLeft="50px">{rowItem.actionItem ? rowItem.actionItem : ""}</Text>}
            {item?.label === "Cache Status" && <Text fontSize="md" textAlign="left" marginLeft="40px">{rowItem.cacheStatus ? rowItem.cacheStatus : ""}</Text>}
            {item?.label === "Cache Time" && <Text fontSize="md"  textAlign="left" marginLeft="50px" >{rowItem.cacheTime}</Text>}
          </Box>;
        })
      }

    </HStack>;
  }

  return (
    <Center>
      <Modal
        isOpen={showModal}
        onClose={() => {
          onCloseReportModal(false);
          setShowModal(false)
        }}
        _backdrop={{
          _dark: {
            bg: 'coolGray.800',
          },
          bg: 'warmGray.50',
        }}
      >
        <Modal.Content maxWidth="50%" maxH="75%">
          <Modal.CloseButton colorScheme="white" />
          <Modal.Header
            bg={primaryColors.primaryRed}
            _text={{
              color: 'white',
            }}
          >
            {title || 'Report'}
          </Modal.Header>
          <Modal.Body >
            <Box px="8" py="4" width="100%" justifyContent="center" bg="rgb(208, 208, 208)">{renderHeader(headerTitleOptions || [])}</Box>
            <Box minHeight="50">
              {
                reports && reports.length > 0 ? <>
                  {
                    (reports || []).map((item, iteration) => {
                      return <Box key={iteration} px="8" py="2">{renderRowItem(item, iteration)} </Box>
                    })
                  }
                </> : <Box px="8" py="2" fontStyle="italic" fontWeight="bold" color={primaryColors.primaryRed} fontSize={15} display='flex' justifyContent='center' alignSelf='center'> NO REPORT FOUND</Box>
              }
            </Box>
          </Modal.Body>
          <Modal.Footer>
            <Button.Group
              space={2}
              style={{
                alignItems: 'center',
              }}
            >
              {/* <Popover
                isOpen={isDateTriggered || false}
                onOpen={() => setTriggerDate(true)}
                onClose={() => setTriggerDate(false)}
                placement="bottom left"
                trigger={(triggerProps) => {
                  return (
                    <Button
                      w="130"
                      px="1"
                      py="1"
                      borderRadius="md"
                      variant="outline"
                      colorScheme="dark.50"
                      leftIcon={
                        <Icon
                          as={MaterialIcons}
                          name="calendar-today"
                          size="4"
                        />
                      }
                      onPress={() => {
                        setTriggerToDate(false);
                        setTriggerDate(!isDateTriggered);
                      }}
                      {...triggerProps}
                    >
                      {fromDate}
                    </Button>
                  );
                }}
              >
                <Popover.Content
                  accessibilityLabel="Filter By Date"
                  w="full"
                  bg="muted.300"
                >
                  <Popover.Arrow />
                  <Popover.Body>
                    <Calendar
                      className="webCalendarPicker"
                      date={Moment(fromDate, 'MM/DD/YYYY').toDate()}
                      //minDate={Moment().toDate()}
                      direction="vertical"
                      fixedHeight
                      color={primaryColors.primaryRed}
                      onChange={(selectedDate) => {
                        setFromDate(Moment(selectedDate).format('MM/DD/YYYY'));
                        setTriggerDate(false);
                        //setTimeout(() => { loadVenueData() }, 50);
                      }}
                    />
                  </Popover.Body>
                </Popover.Content>
              </Popover> */}
              <Popover
                isOpen={isToDateTriggered || false}
                onOpen={() => setTriggerToDate(true)}
                onClose={() => setTriggerToDate(false)}
                placement="bottom left"
                trigger={(triggerProps) => {
                  return (
                    <Button
                      w="130"
                      px="1"
                      py="1"
                      borderRadius="md"
                      variant="outline"
                      colorScheme="dark.50"
                      leftIcon={
                        <Icon
                          as={MaterialIcons}
                          name="calendar-today"
                          size="4"
                        />
                      }
                      onPress={() => {
                        setTriggerDate(false);
                        setTriggerToDate(!isToDateTriggered);
                      }}
                      {...triggerProps}
                    >
                      {toDate}
                    </Button>
                  );
                }}
              >
                <Popover.Content
                  accessibilityLabel="Filter By Date"
                  w="full"
                  bg="muted.300"
                >
                  <Popover.Arrow />
                  <Popover.Body>
                    <Calendar
                      className="webCalendarPicker"
                      date={Moment(toDate, 'MM/DD/YYYY').toDate()}
                      //minDate={Moment().toDate()}
                      direction="vertical"
                      fixedHeight
                      color={primaryColors.primaryRed}
                      onChange={(selectedDate) => {
                        getCacheReportListbyDate(Moment(selectedDate).format('MM/DD/YYYY'));
                        setFromDate(Moment(selectedDate).format('MM/DD/YYYY'));
                        setToDate(Moment(selectedDate).format('MM/DD/YYYY'));
                        setTriggerToDate(false);
                        //setTimeout(() => { loadVenueData() }, 50);
                      }}
                    />
                  </Popover.Body>
                </Popover.Content>
              </Popover>
              <Button
                background={primaryColors.primaryRed}
                onPress={() => {
                  downloadCSV(fromDate, toDate);
                  onCloseReportModal(false);
                  setShowModal(false);
                }}
              >
                Download
              </Button>
            </Button.Group>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
    </Center>
  );
});



const mapStateToProps = (state) => {
  const { cacheReportDetailsResponse } = state.venue;

  return { cacheReportDetailsResponse };
}

const mapDispatchToProps = (dispatch) => {
  return {
    GetCacheReportDetailsByDate: (venueid, fromDate, toDate) => dispatch(VenueActions.GetCacheReportDetailsByDate(venueid, fromDate, toDate)).then((response) => response),
  }
}

ReportModal.defaultProps = {
  headerTitleOptions: [
    { key: '0_RequestTime', label: "Request Time", width: "15%" },
    { key: '1_Table', label: "Table", width: "15%" },
    { key: '2_Guest', label: "Guest", width: "23%" },
    { key: '3_ActionItem', label: "Action Item", width: "18%" },
    { key: '4_CacheStatus', label: "Cache Status", width: "15%" },
    { key: '5_CacheTime', label: "Cache Time", width: "15%" },
  ]
}

ReportModal.propTypes = {
  title: PropTypes.string,
  downloadCSV: PropTypes.func,
  GetCacheReportDetailsByDate: PropTypes.func,
  cacheReportDetailsResponse: PropTypes.any,
  onCloseReportModal: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportModal);
