/* eslint-disable no-unused-vars */
/* eslint-disable react-native/no-color-literals */
import { StyleSheet } from 'react-native';

// Utils
import { primaryColors } from '../utils/colors';
import { isTablet } from '../utils/common';

const globalStyles = StyleSheet.create({
  center: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  imageLogo: {
    width: 350,
    resizeMode: 'contain',
    marginBottom: 20,
    padding: 10,
    marginTop: 20
  },
  dynamicImageLogo: {
    width: isTablet() ? 500 : 250,
    height: 300,
    resizeMode: 'contain',
    marginBottom: 20,
    padding: 10,
    marginTop: 40
  },
  image: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    width: '100%',
    backgroundColor: 'black'
  },
  inputBg: {
    justifyContent: 'center'
  },
  inputIcon: {
    width: isTablet() ? 20 : 18,
    resizeMode: 'contain'
  },
  buttonTextColor: {
    fontSize: isTablet() ? 18 :  14,
    // fontFamily: 'SourceSansPro_400Regular',
    fontWeight: 'bold',
    color: primaryColors.lightOrange,
  },
  link: {
    fontSize: isTablet() ? 18 : 14,
    // fontFamily: 'SourceSansPro_700Bold'
  },
  datepicker: {
    color: primaryColors.primaryBlack,
    fontWeight: 'bold'
  },
  content: {
    position: 'absolute',
    bottom: 10,
    left: 0,
    right: 0
  },
});

export default globalStyles;
