/* eslint-disable react-native/no-color-literals */
import { StyleSheet } from 'react-native';

// Utils
import { primaryColors } from '../utils/colors';
import { isTablet } from '../utils/common';

const RootStyles = StyleSheet.create({
  rootContainer: { flex: 1, backgroundColor: primaryColors.primaryBlack },
  mainContainer: { flex: 1, },
  spinnerLoaderContainer: { position: 'absolute', left: 0, top: 0, bottom: 0, right: 0 },
  headerLogo: { width: isTablet() ? 140 : 120, height: isTablet() ? 120 : 60 },
  headerSettingLogo: { width: isTablet() ? 80 : 60, height: isTablet() ? 80 : 60, marginRight: 15, resizeMode: 'contain' },
  headerLogOutLogo: { width: isTablet() ? 50 : 35, height: isTablet() ? 50 : 30, margin: 20, resizeMode: 'contain' },
  backgroundOverlayBlack: { position: 'absolute', left: 0, right: 0, top: 0, bottom: 0, zIndex: 999, backgroundColor: 'rgba(0,0,0,0.4)' },
});

export default RootStyles;
