/* eslint-disable react-native/no-color-literals */
/* eslint-disable react-native/no-inline-styles */
/* eslint-disable react/jsx-key */
/* eslint-disable react-native/no-raw-text */
import React, { useEffect, useState } from "react";
import { Dimensions } from 'react-native';
import { Flex, Box, Button, Heading, Icon, Text, Spinner, useToast } from 'native-base';
import { Calendar } from "react-multi-date-picker";
import { FontAwesome } from '@expo/vector-icons';
import { connect } from 'react-redux';
import Moment from "moment";
import PropTypes from 'prop-types';

// Redux Actions
import * as VenueActions from '../../../shared/redux/actions/venue';
import { primaryColors } from '../../../shared/utils/colors'

// Stylesheet
import "../../styles/styles.css";
import "react-multi-date-picker/styles/colors/red.css";

// Init :: Const
const weekDays = ["SU", "MO", "TU", "WE", "TH", "FR", "SA"];
const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
const windowWidth = Dimensions.get('screen').width;

const AddOrEditReservationDate = (props) => {

  const toast = useToast();

  const {
    GetBlockDatesForVenue,
    GetVenuePremiumDateRate,
    venueDetail,
    blockDates,
    calendarTags,
    InsertOrUpdateBlockDate,
    InsertOrUpdateVenuePremiumRateDates,
    DeleteBlockDate,
    blockDateLoader,
    disabledDatesColor,
    venuePremiumDatesResponse,
    venueSettingByDayResponse,
  } = props;

  const [calendarDataSet, setCalendarDataSet] = useState([]);
  const [tier1DateDataSet, setTier1DateDataSet] = useState([]);
  const [tier2DateDataSet, setTier2DateDataSet] = useState([]);
  const [tier3DateDataSet, setTier3DateDataSet] = useState([]);
  const [tier4DateDataSet, setTier4DateDataSet] = useState([]);
  const [newDateDataSet, setNewDateDataSet] = useState([]);
  const [APIUpdateKey, setAPIUpdateKey] = useState(Date.now());

  const [weekDatesStatus, setWeekDatesStatus] = useState({
    sundayWeekViewSelection: false,
    mondayWeekViewSelection: false,
    tuesdayWeekViewSelection: false,
    wednesdayWeekViewSelection: false,
    thursdayWeekViewSelection: false,
    fridayWeekViewSelection: false,
    saturdayWeekViewSelection: false,
  });

  useEffect(() => {
    (venueDetail?.id && venueDetail?.id > 0) && GetBlockDates(venueDetail?.id, 0)
  }, [venueDetail]);

  useEffect(() => {
    if (venueSettingByDayResponse && venueSettingByDayResponse?.length === 7) {
      let tempWeekDatesStatus = { ...weekDatesStatus };

      tempWeekDatesStatus.mondayWeekViewSelection = venueSettingByDayResponse[0]?.isClosed ? 0 : venueSettingByDayResponse[0]?.ratetierid;
      tempWeekDatesStatus.tuesdayWeekViewSelection = venueSettingByDayResponse[1]?.isClosed ? 0 : venueSettingByDayResponse[1]?.ratetierid;
      tempWeekDatesStatus.wednesdayWeekViewSelection = venueSettingByDayResponse[2]?.isClosed ? 0 : venueSettingByDayResponse[2]?.ratetierid;
      tempWeekDatesStatus.thursdayWeekViewSelection = venueSettingByDayResponse[3]?.isClosed ? 0 : venueSettingByDayResponse[3]?.ratetierid;
      tempWeekDatesStatus.fridayWeekViewSelection = venueSettingByDayResponse[4]?.isClosed ? 0 : venueSettingByDayResponse[4]?.ratetierid;
      tempWeekDatesStatus.saturdayWeekViewSelection = venueSettingByDayResponse[5]?.isClosed ? 0 : venueSettingByDayResponse[5]?.ratetierid;
      tempWeekDatesStatus.sundayWeekViewSelection = venueSettingByDayResponse[6]?.isClosed ? 0 : venueSettingByDayResponse[6]?.ratetierid;

      setWeekDatesStatus(tempWeekDatesStatus);
    }
  }, [venueSettingByDayResponse]);

  useEffect(() => {
    (blockDates && blockDates?.length > 0) && setCalendarDataSet(([...blockDates] || [])?.map((item) => {
      item.dateType = calendarTags[0]?.key;
      item.formattedBlockDate = Moment(item?.blockDate, "YYYY-MM-DD[T]HH:mm:ss").format("MM/DD/YYYY");
      item.rateTierID = 0;

      return item;
    }) || []);

    // Empty Cases
    (!blockDates || blockDates?.length == 0) && setCalendarDataSet([]);
  }, [blockDates]);

  useEffect(() => {
    const { tier1, tier2, tier3, tier4 } = venuePremiumDatesResponse;

    if (tier1 && tier1?.length > 0) {
      let tempTier1 = [...tier1];
      tempTier1 = (tempTier1 || [])?.map((tier1Item) => {
        tier1Item.blockDate = tier1Item.date;
        tier1Item.createdDate = Moment().format("YYYY-MM-DD[T]HH:mm:ss");
        tier1Item.tableId = 0;
        tier1Item.dateType = calendarTags[1]?.key;
        tier1Item.formattedBlockDate = Moment(tier1Item?.date, "YYYY-MM-DD[T]HH:mm:ss").format("MM/DD/YYYY");
        tier1Item.venueName = venueDetail?.venueName || '';
        tier1Item.tableName = null;

        return tier1Item;
      });

      setTier1DateDataSet(tempTier1 || []);
    }

    if (tier2 && tier2?.length > 0) {
      let tempTier2 = [...tier2];
      tempTier2 = (tempTier2 || [])?.map((tier2Item) => {
        tier2Item.blockDate = tier2Item.date;
        tier2Item.createdDate = Moment().format("YYYY-MM-DD[T]HH:mm:ss");
        tier2Item.tableId = 0;
        tier2Item.dateType = calendarTags[2]?.key;
        tier2Item.formattedBlockDate = Moment(tier2Item?.date, "YYYY-MM-DD[T]HH:mm:ss").format("MM/DD/YYYY");
        tier2Item.venueName = venueDetail?.venueName || '';
        tier2Item.tableName = null;

        return tier2Item;
      });

      setTier2DateDataSet(tempTier2 || []);
    }

    if (tier3 && tier3?.length > 0) {
      let tempTier3 = [...tier3];
      tempTier3 = (tempTier3 || [])?.map((tier3Item) => {
        tier3Item.blockDate = tier3Item.date;
        tier3Item.createdDate = Moment().format("YYYY-MM-DD[T]HH:mm:ss");
        tier3Item.tableId = 0;
        tier3Item.dateType = calendarTags[3]?.key;
        tier3Item.formattedBlockDate = Moment(tier3Item?.date, "YYYY-MM-DD[T]HH:mm:ss").format("MM/DD/YYYY");
        tier3Item.venueName = venueDetail?.venueName || '';
        tier3Item.tableName = null;

        return tier3Item;
      });

      setTier3DateDataSet(tempTier3 || []);
    }

    if (tier4 && tier4?.length > 0) {
      let tempTier4 = [...tier4];
      tempTier4 = (tempTier4 || [])?.map((tier4Item) => {
        tier4Item.blockDate = tier4Item.date;
        tier4Item.createdDate = Moment().format("YYYY-MM-DD[T]HH:mm:ss");
        tier4Item.tableId = 0;
        tier4Item.dateType = calendarTags[4]?.key;
        tier4Item.formattedBlockDate = Moment(tier4Item?.date, "YYYY-MM-DD[T]HH:mm:ss").format("MM/DD/YYYY");
        tier4Item.venueName = venueDetail?.venueName || '';
        tier4Item.tableName = null;

        return tier4Item;
      });

      setTier4DateDataSet(tempTier4 || []);
    }
  }, [venuePremiumDatesResponse]);

  const GetBlockDates = async (venueId, tableId) => {
    await setCalendarDataSet([]);
    await setTier1DateDataSet([]);
    await setTier2DateDataSet([]);
    await setTier3DateDataSet([]);
    await setTier4DateDataSet([]);
    await setNewDateDataSet([]);

    await GetBlockDatesForVenue(venueId, tableId);
    await GetVenuePremiumDateRate(venueId, tableId);
    await setAPIUpdateKey(Date.now());
  };

  const HandleDeleteBlockDate = async (blockDates) => {
    const response = await DeleteBlockDate([blockDates]).then((result) => result);
    if (response && response?.status == 200) {
      GetBlockDates(venueDetail?.id, 0);
    }
  };

  const updateCalendarPlots = async (calendarTagIteration) => {
    toast.closeAll();

    let scopedType = calendarTags[calendarTagIteration];
    let InsertOrUpdateVenuePremiumRateDatesParam = (newDateDataSet || [])
      ?.map((dataSetItem) => {
        let tempDataSetItem = {};
        tempDataSetItem.venueId = dataSetItem.venueId;
        tempDataSetItem.date = dataSetItem?.blockDate;
        tempDataSetItem.ratetierid = scopedType.rateTierID;

        return tempDataSetItem;
      });
    const InsertOrUpdateVenuePremiumRateDatesResponse = await InsertOrUpdateVenuePremiumRateDates(InsertOrUpdateVenuePremiumRateDatesParam).then((data) => data);
    if (InsertOrUpdateVenuePremiumRateDatesResponse?.data?.status === 'Success') {
      GetBlockDates(venueDetail?.id, 0)
    } else {
      toast.show({
        render: () => <Box bg="error.600" px="2" py="1" rounded="sm" mb={5}>Updated Failed! Please Contact Your Administrator</Box>
      });
    }
  }

  const updateBlockDates = async (calendarTagIteration) => {
    toast.closeAll();

    let scopedType = calendarTags[calendarTagIteration];
    if (scopedType?.rateTierID === 0 && (newDateDataSet && newDateDataSet?.length > 0)) {
      let promises = [];
      (newDateDataSet || [])?.map((newDataSetItem) => {
        promises.push(
          InsertOrUpdateBlockDate([
            {
              id: 0,
              venueId: venueDetail?.id,
              tableId: 0,
              blockDate: newDataSetItem?.blockDate,
              createdDate: Moment().format("YYYY-MM-DD[T]HH:mm:ss"),
              venueName: `string`,
              tableName: `string`,
            }
          ]).then((result) => { return result; })
        );
      });
      Promise.all(promises).then(() => GetBlockDates(venueDetail?.id, 0));
    }
  }

  const stringifiedCalendarDataSetForBlockedDates = (calendarDataSet || [])?.map((dateItem) => new Date(dateItem?.blockDate)?.toDateString());
  const stringifiedCalendarDataSetForTier1Dates = (tier1DateDataSet || [])?.map((dateItem) => new Date(dateItem?.blockDate)?.toDateString());
  const stringifiedCalendarDataSetForTier2Dates = (tier2DateDataSet || [])?.map((dateItem) => new Date(dateItem?.blockDate)?.toDateString());
  const stringifiedCalendarDataSetForTier3Dates = (tier3DateDataSet || [])?.map((dateItem) => new Date(dateItem?.blockDate)?.toDateString());
  const stringifiedCalendarDataSetForTier4Dates = (tier4DateDataSet || [])?.map((dateItem) => new Date(dateItem?.blockDate)?.toDateString());
  const stringifiedCalendarDataSetForNewDates = (newDateDataSet || [])?.map((dateItem) => new Date(dateItem?.blockDate)?.toDateString());

  return <>
    <Flex flexDirection="row" alignItems="center" w={"100%"} mb={6}>
      <Box pr="3">
        <Heading size='lg'>Venue Calendar</Heading>
      </Box>
      <Box>
        <Flex flexDirection="row" alignItems="center">
          <Flex
            p={1}
            flexDirection="row"
            borderWidth={1.5}
            borderRadius={50}
            borderColor="#dcdcdc"
          >
            {
              (calendarTags || [])
                ?.filter((x) => x?.key !== 'new')
                ?.map((item, iteration) => {
                  return <Button
                    key={`calendarTags_${item?.key}_${iteration}`}
                    variant="solid"
                    bg={item?.bgColor}
                    size="lg"
                    pl="3"
                    pr="3"
                    pt="1"
                    pb="1"
                    ml="2"
                    mr="2"
                    borderRadius="full"
                    onPress={() => (item?.key === 'block') ? updateBlockDates(iteration) : updateCalendarPlots(iteration)}
                    leftIcon={<Icon as={FontAwesome} name={item?.icon} size="xs" fontWeight="bold" color="white" />}
                    _text={{
                      fontSize: 'xs',
                      fontWeight: "bold",
                      color: item?.color
                    }}
                    _hover={{
                      background: item.bgColor,
                      borderColor: primaryColors.lightOrange
                    }}
                    _pressed={{
                      background: item.bgColor,
                    }}
                    _focus={{
                      background: item.bgColor,
                    }}
                  >
                    {item?.value}
                  </Button>
                })
            }
            <Button
              key={`calendarTags_reset_6`}
              variant="solid"
              bg='black'
              size="lg"
              pl="3"
              pr="3"
              pt="1"
              pb="1"
              ml="2"
              mr="2"
              borderRadius="full"
              onPress={() => updateCalendarPlots(5)}
              leftIcon={<Icon as={FontAwesome} name="refresh" size="xs" fontWeight="bold" color="white" />}
              _text={{
                fontSize: 'xs',
                fontWeight: "bold",
                color: 'white'
              }}
              _hover={{
                background: 'black',
                borderColor: primaryColors.lightOrange
              }}
              _pressed={{
                background: 'black',
              }}
              _focus={{
                background: 'black',
              }}
            >
              Reset
            </Button>
          </Flex>
        </Flex>
      </Box>
    </Flex>
    <Flex flexDirection="row" alignItems="flex-start">
      <Box>
        <Calendar
          key={APIUpdateKey}
          fullYear
          multiple
          format="MM/DD/YYYY"
          // minDate={new Date()}
          weekStartDayIndex={1}
          sort
          onChange={(dateObject) => {
            let tempNewDateDataSet = (dateObject || [])?.map((filterDataSetItem) => {
              let newDateDataSetParam = {};
              newDateDataSetParam.id = 0;
              newDateDataSetParam.venueId = venueDetail?.id || 0;
              newDateDataSetParam.tableId = 0;
              newDateDataSetParam.blockDate = Moment(filterDataSetItem.format(), 'MM/DD/YYYY').format("YYYY-MM-DD[T]HH:mm:ss");
              newDateDataSetParam.createdDate = Moment().format("YYYY-MM-DD[T]HH:mm:ss");
              newDateDataSetParam.venueName = venueDetail?.venueName || '';
              newDateDataSetParam.tableName = null; 
              newDateDataSetParam.dateType = calendarTags[5]?.key;
              newDateDataSetParam.formattedBlockDate = filterDataSetItem.format();
              newDateDataSetParam.rateTierID = 0;

              return newDateDataSetParam;
            });

            setNewDateDataSet(tempNewDateDataSet || []);
          }}
          weekDays={weekDays}
          months={months}
          mapDays={({ date, today, isSameDate }) => {
            let currentIteratingDate = JSON.parse(JSON.stringify(date));
            currentIteratingDate = new Date(currentIteratingDate)?.toDateString();

            let props = {};

            // Reset Bg For Same Date
            if (isSameDate(date, today)) {
              props.style = {
                backgroundColor: 'white',
                color: "black",
              }
            }


            // Default Selections
            if (date?.weekDay?.name === 'Sunday') {
              props.style = {
                backgroundColor: weekDatesStatus?.sundayWeekViewSelection === 0 ? disabledDatesColor
                  : (weekDatesStatus?.sundayWeekViewSelection === 2 || weekDatesStatus?.sundayWeekViewSelection === 3 || weekDatesStatus?.sundayWeekViewSelection === 4) ? calendarTags[weekDatesStatus?.sundayWeekViewSelection]?.bgColor
                    : calendarTags[1]?.bgColor,
                color: weekDatesStatus?.sundayWeekViewSelection === 0 ? 'black'
                  : (weekDatesStatus?.sundayWeekViewSelection === 2 || weekDatesStatus?.sundayWeekViewSelection === 3 || weekDatesStatus?.sundayWeekViewSelection === 4) ? calendarTags[weekDatesStatus?.sundayWeekViewSelection]?.color
                    : calendarTags[1]?.color,
              }
            }

            if (date?.weekDay?.name === 'Monday') {
              props.style = {
                backgroundColor: weekDatesStatus?.mondayWeekViewSelection === 0 ? disabledDatesColor
                  : (weekDatesStatus?.mondayWeekViewSelection === 2 || weekDatesStatus?.mondayWeekViewSelection === 3 || weekDatesStatus?.mondayWeekViewSelection === 4) ? calendarTags[weekDatesStatus?.mondayWeekViewSelection]?.bgColor
                    : calendarTags[1]?.bgColor,
                color: weekDatesStatus?.mondayWeekViewSelection === 0 ? 'black'
                  : (weekDatesStatus?.mondayWeekViewSelection === 2 || weekDatesStatus?.mondayWeekViewSelection === 3 || weekDatesStatus?.mondayWeekViewSelection === 4) ? calendarTags[weekDatesStatus?.mondayWeekViewSelection]?.color
                    : calendarTags[1]?.color,
              }
            }

            if (date?.weekDay?.name === 'Tuesday') {
              props.style = {
                backgroundColor: weekDatesStatus?.tuesdayWeekViewSelection === 0 ? disabledDatesColor
                  : (weekDatesStatus?.tuesdayWeekViewSelection === 2 || weekDatesStatus?.tuesdayWeekViewSelection === 3 || weekDatesStatus?.tuesdayWeekViewSelection === 4) ? calendarTags[weekDatesStatus?.tuesdayWeekViewSelection]?.bgColor
                    : calendarTags[1]?.bgColor,
                color: weekDatesStatus?.tuesdayWeekViewSelection === 0 ? 'black'
                  : (weekDatesStatus?.tuesdayWeekViewSelection === 2 || weekDatesStatus?.tuesdayWeekViewSelection === 3 || weekDatesStatus?.tuesdayWeekViewSelection === 4) ? calendarTags[weekDatesStatus?.tuesdayWeekViewSelection]?.color
                    : calendarTags[1]?.color,
              }
            }

            if (date?.weekDay?.name === 'Wednesday') {
              props.style = {
                backgroundColor: weekDatesStatus?.wednesdayWeekViewSelection === 0 ? disabledDatesColor
                  : (weekDatesStatus?.wednesdayWeekViewSelection === 2 || weekDatesStatus?.wednesdayWeekViewSelection === 3 || weekDatesStatus?.wednesdayWeekViewSelection === 4) ? calendarTags[weekDatesStatus?.wednesdayWeekViewSelection]?.bgColor
                    : calendarTags[1]?.bgColor,
                color: weekDatesStatus?.wednesdayWeekViewSelection === 0 ? 'black'
                  : (weekDatesStatus?.wednesdayWeekViewSelection === 2 || weekDatesStatus?.wednesdayWeekViewSelection === 3 || weekDatesStatus?.wednesdayWeekViewSelection === 4) ? calendarTags[weekDatesStatus?.wednesdayWeekViewSelection]?.color
                    : calendarTags[1]?.color,
              }
            }

            if (date?.weekDay?.name === 'Thursday') {
              props.style = {
                backgroundColor: weekDatesStatus?.thursdayWeekViewSelection === 0 ? disabledDatesColor
                  : (weekDatesStatus?.thursdayWeekViewSelection === 2 || weekDatesStatus?.thursdayWeekViewSelection === 3 || weekDatesStatus?.thursdayWeekViewSelection === 4) ? calendarTags[weekDatesStatus?.thursdayWeekViewSelection]?.bgColor
                    : calendarTags[1]?.bgColor,
                color: weekDatesStatus?.thursdayWeekViewSelection === 0 ? 'black'
                  : (weekDatesStatus?.thursdayWeekViewSelection === 2 || weekDatesStatus?.thursdayWeekViewSelection === 3 || weekDatesStatus?.thursdayWeekViewSelection === 4) ? calendarTags[weekDatesStatus?.thursdayWeekViewSelection]?.color
                    : calendarTags[1]?.color,
              }
            }

            if (date?.weekDay?.name === 'Friday') {
              props.style = {
                backgroundColor: weekDatesStatus?.fridayWeekViewSelection === 0 ? disabledDatesColor
                  : (weekDatesStatus?.fridayWeekViewSelection === 2 || weekDatesStatus?.fridayWeekViewSelection === 3 || weekDatesStatus?.fridayWeekViewSelection === 4) ? calendarTags[weekDatesStatus?.fridayWeekViewSelection]?.bgColor
                    : calendarTags[1]?.bgColor,
                color: weekDatesStatus?.fridayWeekViewSelection === 0 ? 'black'
                  : (weekDatesStatus?.fridayWeekViewSelection === 2 || weekDatesStatus?.fridayWeekViewSelection === 3 || weekDatesStatus?.fridayWeekViewSelection === 4) ? calendarTags[weekDatesStatus?.fridayWeekViewSelection]?.color
                    : calendarTags[1]?.color,
              }
            }

            if (date?.weekDay?.name === 'Saturday') {
              props.style = {
                backgroundColor: weekDatesStatus?.saturdayWeekViewSelection === 0 ? disabledDatesColor
                  : (weekDatesStatus?.saturdayWeekViewSelection === 2 || weekDatesStatus?.saturdayWeekViewSelection === 3 || weekDatesStatus?.saturdayWeekViewSelection === 4) ? calendarTags[weekDatesStatus?.saturdayWeekViewSelection]?.bgColor
                    : calendarTags[1]?.bgColor,
                color: weekDatesStatus?.saturdayWeekViewSelection === 0 ? 'black'
                  : (weekDatesStatus?.saturdayWeekViewSelection === 2 || weekDatesStatus?.saturdayWeekViewSelection === 3 || weekDatesStatus?.saturdayWeekViewSelection === 4) ? calendarTags[weekDatesStatus?.saturdayWeekViewSelection]?.color
                    : calendarTags[1]?.color,
              }
            }


            // Tier 1 Dates
            if (stringifiedCalendarDataSetForTier1Dates?.includes(currentIteratingDate)) {
              props.style = {
                color: 'white',
                fontWeight: 'bold',
                backgroundColor: calendarTags[1].bgColor
              }
            }

            // Tier 2 Dates
            if (stringifiedCalendarDataSetForTier2Dates?.includes(currentIteratingDate)) {
              props.style = {
                color: 'white',
                fontWeight: 'bold',
                backgroundColor: calendarTags[2].bgColor
              }
            }

            // Tier 3 Dates
            if (stringifiedCalendarDataSetForTier3Dates?.includes(currentIteratingDate)) {
              props.style = {
                color: 'white',
                fontWeight: 'bold',
                backgroundColor: calendarTags[3].bgColor
              }
            }

            // Tier 4 Dates
            if (stringifiedCalendarDataSetForTier4Dates?.includes(currentIteratingDate)) {
              props.style = {
                color: 'white',
                fontWeight: 'bold',
                backgroundColor: calendarTags[4].bgColor
              }
            }


            // Block Dates
            if (stringifiedCalendarDataSetForBlockedDates?.includes(currentIteratingDate)) {
              props.style = {
                color: 'white',
                fontWeight: 'bold',
                backgroundColor: calendarTags[0].bgColor
              }
            }

            // New Dates
            if (stringifiedCalendarDataSetForNewDates?.includes(currentIteratingDate)) {
              props.style = {
                color: 'white',
                fontWeight: 'bold',
                backgroundColor: calendarTags[5].bgColor
              }
            }

            return props;
          }}
          style={{ width: (windowWidth / 100) * 80 }}
        />
      </Box>
      <Box
        borderWidth={1.5}
        borderRadius={5}
        ml={3}
        mr={3}
        p={3}
        w={300}
        h="full"
        borderColor="#dcdcdc"
      >
        <Box pb={2} borderBottomWidth={1} borderBottomColor="#dcdcdc" mb={2}>
          <Text fontWeight="600" fontSize="md" textAlign="center">
            <Icon as={FontAwesome} name={calendarTags[0]?.icon} size="xs" fontWeight="bold" color="black" pr={2} />
            Blocked Dates
          </Text>
        </Box>
        {blockDateLoader ? (
          <Spinner />
        ) : (<>
          {
            ((calendarDataSet || [])
              ?.filter((x) => x?.dateType === 'block' && x?.id > -1) || [])
              ?.map((blockDateItem, blockDateIteration) => {
                return <Box
                  key={`ButtonDataSet_${blockDateIteration}`}
                  mb={1}
                  pl={2}
                  borderRadius="lg"
                  w="full"
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  style={{
                    backgroundColor: calendarTags[0]?.bgColor
                  }}
                >
                  <Box display="flex" flexDirection="row" alignItems="center">
                    <Icon as={FontAwesome} name="calendar" size="xs" fontWeight="bold" color="white" />
                    <Box pl={1}>
                      <Text color="white" fontWeight="bold">{blockDateItem?.formattedBlockDate}</Text>
                    </Box>
                  </Box>
                  <Button
                    variant="unstyled"
                    onPress={() => HandleDeleteBlockDate(blockDateItem)}
                  >
                    <Icon
                      as={FontAwesome}
                      name="trash"
                      size="xs"
                      fontWeight="bold"
                      color="white"
                    />
                  </Button>
                </Box>
              })
          }
        </>)}
      </Box>
    </Flex>
  </>;
}

const mapStateToProps = (state) => {
  const {
    VenuesDetailResponse,
    venueBlockDatesResponse,
    venueBlockDatesLoading,
    venuePremiumDatesResponse,
    venuePremiumDatesLoading,
    venueSettingByDayLoading,
    venueSettingByDayResponse,
  } = state.venue;

  return {
    venueDetail: VenuesDetailResponse || [],
    blockDates: venueBlockDatesResponse || [],
    blockDateLoader: venueBlockDatesLoading,
    venuePremiumDatesResponse: venuePremiumDatesResponse || [],
    venuePremiumDatesLoading: venuePremiumDatesLoading,
    venueSettingByDayLoading,
    venueSettingByDayResponse: venueSettingByDayResponse || [],
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    GetBlockDatesForVenue: (venueId, tableId) => dispatch(VenueActions.GetBlockDatesForVenue(venueId, tableId)).then((response) => response),
    GetVenuePremiumDateRate: (venueId, tableId) => dispatch(VenueActions.GetVenuePremiumDateRate(venueId, tableId)).then((response) => response),
    InsertOrUpdateBlockDate: (data) => dispatch(VenueActions.InsertOrUpdateBlockDate(data)).then((response) => response),
    InsertOrUpdateVenuePremiumRateDates: (data) => dispatch(VenueActions.InsertOrUpdateVenuePremiumRateDates(data)).then((response) => response),
    DeleteBlockDate: (data) => dispatch(VenueActions.DeleteBlockDate(data)).then((response) => response),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddOrEditReservationDate);

AddOrEditReservationDate.propTypes = {
  blockDates: PropTypes.any,
  calendarTags: PropTypes.any,
  GetBlockDatesForVenue: PropTypes.any,
  venueDetail: PropTypes.any,
  InsertOrUpdateBlockDate: PropTypes.any,
  DeleteBlockDate: PropTypes.any,
  blockDateLoader: PropTypes.any,
  tempDisabledDatesArr: PropTypes.any,
  disabledDatesColor: PropTypes.string,
  GetVenuePremiumDateRate: PropTypes.func,
  venuePremiumDatesResponse: PropTypes.any,
  InsertOrUpdateVenuePremiumRateDates: PropTypes.func,
  venueSettingByDayResponse: PropTypes.any,
};

AddOrEditReservationDate.defaultProps = {
  calendarTags: [
    { key: 'block', value: 'Block', bgColor: '#ff1616', color: 'white', icon: 'ban', rateTierID: 0 },
    { key: 'tier_1', value: 'Tier 1', bgColor: '#5ce1e6', color: 'white', icon: 'refresh', rateTierID: 1 },
    { key: 'tier_2', value: 'Tier 2', bgColor: '#38b6ff', color: 'white', icon: 'refresh', rateTierID: 2 },
    { key: 'tier_3', value: 'Tier 3', bgColor: '#5271ff', color: 'white', icon: 'refresh', rateTierID: 3 },
    { key: 'tier_4', value: 'Tier 4', bgColor: '#004aad', color: 'white', icon: 'refresh', rateTierID: 4 },
    { key: 'new', value: 'New', bgColor: 'black', color: 'white', icon: '', rateTierID: -1 },
  ],
  disabledDatesColor: '#d9d9d9',
};