const primaryColors = {
  // Background Colors
  primaryBlack: "#111111",
  primaryRed: '#ff1515',
  primaryYellow: '#ffc433',
  primaryGreen: '#00ac40',
  primaryGrey: '#c2c2c2',
  secondaryGrey: '#7b7b7b',
  lightOrange: '#ffc433',
  PictonBlue: '#17BED0',
  vividCyan: '#03DAC6',
  luigiGreen: '#51b916',
}

export {
  primaryColors
}
