import React from 'react';
import { Box, Text } from 'native-base';
import PropTypes from 'prop-types';

const ImagePlaceholder = (props) => {
  const { placeholderText } = props;

  return <>
    <Box
      w="320"
      h="200"
      bg="muted.400"
      mb="2"
      mt="3"
      borderRadius="5"
      display="flex"
      alignItems="center"
      justifyContent="center"
      shadow={2}
      _text={{
        fontSize: "md",
        fontWeight: "bold",
        color: "white"
      }}>
      <Text bold fontSize="xl">{placeholderText || ""}</Text>
    </Box>

  </>;
};

ImagePlaceholder.propTypes = {
  placeholderText: PropTypes.string
}

export default ImagePlaceholder
