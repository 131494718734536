// Redux :: Action Types
import {
  FORGOT_PASSWORD_IDLE,
  FORGOT_PASSWORD_PENDING,
  FORGOT_PASSWORD_FAILED,
  FORGOT_PASSWORD_SUCCEEDED,
  OTP_IDLE,
  OTP_PENDING,
  OTP_FAILED,
  OTP_SUCCEEDED,
  RESET_PASSWORD_IDLE,
  RESET_PASSWORD_PENDING,
  RESET_PASSWORD_FAILED,
  RESET_PASSWORD_SUCCEEDED,
} from '../constants';

/* ----- INITIAL STATE ----- */
const initialState = {
  loading: false,
  idleErr: null,
  forgotPasswordResponse: null,
  forgotPasswordErr: null,
};

/* ----- REDUCER ----- */
const forgotPasswordReducer = (state = initialState, action) => {
  switch (action.type) {

    case FORGOT_PASSWORD_PENDING:
      return {
        ...state,
        loading: action.payload
      };

    case FORGOT_PASSWORD_IDLE:
      return {
        ...state,
        idleErr: action.payload
      };

    case FORGOT_PASSWORD_SUCCEEDED:
      return {
        ...state,
        forgotPasswordResponse: action.payload
      };

    case FORGOT_PASSWORD_FAILED:
      return {
        ...state,
        forgotPasswordErr: action.payload
      };
    default:
      return state;

  }
}

/* ----- OTP INITIAL STATE ----- */
const OTPInitialState = {
  loading: false,
  idleErr: null,
  OTPResponse: null,
  OTPErr: null,
};

/* ----- OTP REDUCER ----- */

const OTPReducer = (state = OTPInitialState, action) => {
  switch (action.type) {

    case OTP_PENDING:
      return {
        ...state,
        loading: action.payload
      };

    case OTP_IDLE:
      return {
        ...state,
        idleErr: action.payload
      };

    case OTP_SUCCEEDED:
      return {
        ...state,
        OTPResponse: action.payload
      };

    case OTP_FAILED:
      return {
        ...state,
        OTPErr: action.payload
      };
    default:
      return state;

  }
}


/* ----- RESET INITIAL STATE ----- */
const resetPasswordInitialState = {
  loading: false,
  idleErr: null,
  resetPasswordResponse: null,
  resetPasswordErr: null,
};

/* ----- reset Password REDUCER ----- */

const resetPasswordReducer = (state = resetPasswordInitialState, action) => {
  switch (action.type) {

    case RESET_PASSWORD_PENDING:
      return {
        ...state,
        loading: action.payload
      };

    case RESET_PASSWORD_IDLE:
      return {
        ...state,
        idleErr: action.payload
      };

    case RESET_PASSWORD_SUCCEEDED:
      return {
        ...state,
        resetPasswordResponse: action.payload
      };

    case RESET_PASSWORD_FAILED:
      return {
        ...state,
        resetPasswordErr: action.payload
      };
    default:
      return state;

  }
}


export { forgotPasswordReducer, OTPReducer, resetPasswordReducer };