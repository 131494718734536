import React, { useEffect, useState } from "react";
import { Flex, Box, Heading, Divider, Button, Icon, Text, HStack, Center, useToast } from 'native-base';
import { Ionicons } from "@expo/vector-icons";
import { FontAwesome } from '@expo/vector-icons';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Redux :: Components
import { SpinnerLoading } from '../../../shared/templates/SpinnerLoading';
import { primaryColors } from '../../../shared/utils/colors';
import ModalWrapper from '../../Modal';
import AlertDialog from '../../AlertDialog';

/* ----- REDUX :: ACTIONS ----- */
import * as VenueActions from '../../../shared/redux/actions/venue';

const VenueUsers = (props) => {
  const { venueUsersResponse, venueUsersLoading, headerTitleOptions, venueId, GetVenueUsersByVenueId, DeleteVenueUser, venueDetail, HandleToast } = props;
  const [showModal, setShowModal] = useState(false);
  const [showAlert, setshowAlert] = useState(false);
  const [modalType, setModalType] = useState("VIEW");
  const [UserId, setUserId] = useState("");
  const [userName, setuserName] = useState("");
  const [activeItem, setActiveItem] = useState(null);

  const toast = useToast();

  useEffect(() => {
    if (venueDetail?.id) {
      loadUsersData(venueDetail?.id);
    }
  }, [venueDetail]);

  const loadUsersData = async (venueId) => {
    await GetVenueUsersByVenueId(venueId);
  }

  const HandleDeleteVenue = async (id) => {
    toast.closeAll();

    if (id !== "") {
      const PostVenue_Response = await DeleteVenueUser(id).then((response) => response);
      if (PostVenue_Response?.data?.status === "Success") {
        toast.show({
          render: () => <Box bg="emerald.500" px="2" py="1" rounded="sm" mb={5}>User Deleted Successfully!</Box>
        });
        if (venueId && venueId > 0) {
          await loadUsersData(venueId);
        }
      } else {
        toast.show({
          render: () => <Box bg="error.600" px="2" py="1" rounded="sm" mb={5}>Failed! Please try again later</Box>
        });
      }
    } else {
      toast.show({
        render: () => <Box bg="error.600" px="2" py="1" rounded="sm" mb={5}>Something went wrong!</Box>
      });
    }
  }

  const renderRowItem = (rowItem, rowIteration) => {
    return <HStack justifyContent="flex-start" space={2}>
      {
        headerTitleOptions.map((item, iteration) => {
          return <Box key={`${item?.width}_${rowIteration}_v1${iteration}`} w={`${item?.width}`}>
            {item?.label === "User Name" && <Text fontSize="md">{rowItem.firstName ? rowItem.firstName : ""}</Text>}
            {item?.label === "User Email" && <Button
              variant="ghost"
              colorScheme='blue'
              size="xs"
              justifyContent="flex-start"
              leftIcon={rowItem.email ? <Icon as={FontAwesome} name="envelope" size="xs" color="yellow.400" /> : null}
              onPress={() => alert("Development InProgress")}
            >
              <Text fontSize="md" underline>{rowItem.email ? rowItem.email : "-"}</Text>
            </Button>}
            {item?.label === "User Phone" && <Button
              variant="ghost"
              colorScheme='blue'
              size="xs"
              justifyContent="flex-start"
              leftIcon={rowItem.phoneNumber ? <Icon as={FontAwesome} name="phone" size="xs" color="tertiary.700" /> : null}
              onPress={() => alert("Development InProgress")}
            >
              <Text fontSize="md" underline>{rowItem.phoneNumber ? rowItem.phoneNumber : ""}</Text>
            </Button>}
            {item?.label === "User Actions" && <Center>
              <Button.Group size="sm">
                <Button
                  variant="solid"
                  colorScheme='blue'
                  size="xs"
                  pl="3"
                  pr="3"
                  pt="1"
                  pb="1"
                  mr="2"
                  borderRadius="full"
                  leftIcon={<Icon as={FontAwesome} name="edit" size="xs" />}
                  onPress={() => {
                    setModalType("EDIT");
                    setUserId(rowItem?.id)
                    setShowModal(true);
                  }}
                  _text={{
                    fontSize: 'sm',
                    color: 'white'
                  }}
                >
                  Edit
                </Button>
                <Button
                  variant="solid"
                  colorScheme='red'
                  size="xs"
                  borderRadius="full"
                  pl="3"
                  pr="3"
                  pt="1"
                  pb="1"
                  mr="2"
                  leftIcon={<Icon as={Ionicons} name="trash" size="xs" />}
                  onPress={() => {
                    setshowAlert(true);
                    setActiveItem(null);
                    setActiveItem(rowItem);
                  }}
                  _text={{
                    fontSize: 'sm',
                    color: 'white'
                  }}
                >
                  Delete
                </Button>
              </Button.Group>
            </Center>}
          </Box>;
        })
      }
    </HStack>;
  }


  return <>
    <Flex flexDirection="row" alignItems="center" mt="8" w={"100%"}>
      <Heading size='lg'>Venue Users</Heading>
      <Button
        background={primaryColors.primaryRed}
        w="150"
        pl="3"
        pr="3"
        pt="1"
        pb="1"
        ml="2"
        mr="2"
        variant="outline"
        borderRadius="full"
        onPress={() => {
          setUserId('');
          setModalType("ADD_USER");
          setActiveItem(null);
          setTimeout(() => { setShowModal(true) }, 500);
        }}
        leftIcon={<Icon as={FontAwesome} name="plus-circle" size="xs" fontWeight="bold" color="white" />}
        _text={{
          fontSize: 'xs',
          fontWeight: "bold",
          color: 'white'
        }}
      >
        Add Users
      </Button>
    </Flex>

    <Divider my="3" bg="muted.400" />

    <Box minHeight="50">
      {
        !venueUsersLoading ? <>
          {
            venueUsersResponse && venueUsersResponse.length > 0 ?
              <>
                {
                  venueUsersResponse.map((item, iteration) => <Box
                    key={`venueUsersList_${item.id}_${iteration}`}
                    py="2">
                    {renderRowItem(item, iteration)}
                  </Box>)
                }
              </>
              :
              <Box my={2}>
                <Text bold fontSize="lg">No User Found!</Text>
              </Box>
          }
        </> : <SpinnerLoading />
      }


      {
        showModal == true ?
          <ModalWrapper
            Id={UserId}
            venueid={venueId}
            modalType={modalType || "VIEW"}
            IsOpen={showModal}
            handleClose={(status)=>setShowModal(status)}
            HandleToast={(msg, bg) => typeof HandleToast === 'function' && HandleToast(msg, bg)}
          />
          : null
      }

      <AlertDialog
        showAlert={showAlert || false}
        Header='Delete User'
        Body={<Center>
          <Text fontSize="lg">Are you sure want to delete user?</Text>
          <Text bold fontSize="md">{activeItem?.firstName}</Text>
        </Center>}
        Name={userName}
        handleClose={
          async (status, deleteType) => {
            if (deleteType == "DELETE") {
              await HandleDeleteVenue(activeItem?.id);
              await loadUsersData(venueDetail?.id);
              await setActiveItem(null);
              await setshowAlert(status);
            } else {
              await setActiveItem(null);
              await setshowAlert(status);
            }
          }}
      />

    </Box>
  </>;
}

VenueUsers.defaultProps = {
  venueUsersResponse: [],
  headerTitleOptions: [
    { key: '0_UserName', label: "User Name", width: "25%" },
    { key: '1_UserEmail', label: "User Email", width: "25%" },
    { key: '2_UserActions', label: "User Actions", width: "50%" }
  ]
}

const mapStateToProps = (state) => {
  const {
    venueUsersResponse,
    venueUsersLoading,
    venueUsersIdleErr,
    venueUsersErr,
    VenuesDetailResponse
  } = state.venue;

  return {
    venueUsersResponse,
    venueUsersLoading,
    venueDetail: VenuesDetailResponse || []
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    GetVenueUsersByVenueId: (venueId) => dispatch(VenueActions.GetVenueUsersByVenueId(venueId)).then((response) => response),
    DeleteVenueUser: (venueId) => dispatch(VenueActions.DeleteVenueUserById(venueId)).then((response) => response),
  };
}

VenueUsers.propTypes = {
  venueUsersResponse: PropTypes.array,
  venueId: PropTypes.any,
  GetVenueUsersByVenueId: PropTypes.func,
  headerTitleOptions: PropTypes.array,
  venueUsersLoading: PropTypes.bool,
  DeleteVenueUser: PropTypes.func,
  venueDetail: PropTypes.any
}

export default connect(mapStateToProps, mapDispatchToProps)(VenueUsers);