/* eslint-disable no-unused-vars */
/* eslint-disable react-native/no-color-literals */
import { StyleSheet, Platform } from 'react-native';

// Utils
import { primaryColors } from '../utils/colors';

const webStyles = StyleSheet.create({
  appBG: { flex: 1, justifyContent: "center" },
  readOnlyInput: {
    ...Platform.select({
      web: {
        border: 'none',
        pointerEvents: 'none'
      }
    })
  }
});

export default webStyles;