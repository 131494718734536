import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { FormControl, Input, Text, Modal, Button, useToast, Select, CheckIcon } from 'native-base';
import { connect } from 'react-redux';
import Moment from 'moment';
import PropTypes from 'prop-types';

/* ----- STYLESHEET ----- */
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

/* ----- REDUX :: ACTIONS ----- */
import * as UserActions from '../../../shared/redux/actions/user';
import * as VenueActions from '../../../shared/redux/actions/venue';

const AddOrEditUsers = forwardRef((props, ref) => {
  const {
    GetUserDetailsById,
    Id,
    UserDetail,
    PostUser,
    IsOpen,
    modalType,
    handleClose,
    venueid,
    GetVenueUsersByVenueId,
    HandleToast,
  } = props;

  // const [UserId, setUserId] = useState('');
  const [VenueId, setVenueId] = useState(venueid);
  const [UserName, setUserName] = useState('');
  const [UserMail, setUserMail] = useState('');
  const [UserPhone, setUserPhone] = useState('');
  const [UserType, setUserType] = useState(modalType === "ADD_USER" ? "4" : "2");

  const toast = useToast();

  const handleReset = () => {
    setUserName("");
    setUserMail("");
    setUserPhone("");
    setVenueId(0);
    handleClose(false, 'close')
  }

  useImperativeHandle(ref, () => ({
    handleReset: handleReset
  }));

  useEffect(() => {
    Id !== "" && modalType === "EDIT" && loadUserDetail();
  }, []);

  useEffect(() => {
    if (modalType === 'EDIT') {
      // setUserId(UserDetail?.id || '');
      setUserName(UserDetail?.firstName || '');
      setUserMail(UserDetail?.email || '');
      setUserPhone(UserDetail?.phoneNumber || '');
      setVenueId(venueid || '');
      if (venueid && venueid > 0) {
        setUserType("4");
      }
    }
  }, [UserDetail]);

  const loadUserDetail = async () => {
    const response = await GetUserDetailsById(Id || '').then((result) => result);
    return response;
  };

  const loadUsersData = async (venueId) => {
    const response = await GetVenueUsersByVenueId(venueId);
    return response;
  };


  const HandleSubmit = async () => {
    toast.closeAll();

    let isInputsValid = false;
    if (UserType === "2" || UserType === "4") {
      if (
        (UserName && UserName !== "")
        && (UserMail && UserMail !== "")
      ) {
        isInputsValid = true;
      } else if (
        (UserName && UserName !== "")
        && (UserPhone && UserPhone !== "")
        && (UserMail && UserMail !== "")
      ) {
        isInputsValid = true;
      }
    } else if (UserType === "1" && (
      (UserName && UserName !== "")
      && (UserPhone && UserPhone !== "")
      && (UserMail && UserMail !== "")
    )) {
      isInputsValid = true;
    }

    if (isInputsValid === false) {
      typeof HandleToast === 'function' &&
        HandleToast('Please fill all required fields', 'error.600');
      return false;
    } else {
      const PostUser_Response = await PostUser({
        name: UserName?.length > 0 ? UserName.trim() : '',
        email: UserMail?.length > 0 ? UserMail.trim() : '',
        phoneNumber: UserPhone?.length > 0 ? UserPhone.trim() : '',
        dateofBirth: Moment().format('YYYY-MM-DD[T]hh:mm:ss') || '',
        userId: Id ? Id : "",
        venueId: VenueId || 0,
        Password: generatePassword(),
        UserType: UserType,
        isactive: true,
      }).then((response) => response);

      if (PostUser_Response?.data?.status === 'Success' || PostUser_Response?.status === 'Success') {
        typeof HandleToast === 'function' && HandleToast(PostUser_Response?.data?.message?.concat('!') || 'User Added Successfully!', 'emerald.500');
        if ((venueid && venueid > 0) || (VenueId && VenueId > 0)) {
          await loadUsersData(venueid);
        }
      } else if (PostUser_Response?.status == 417) {
        typeof HandleToast === 'function' &&
          HandleToast(PostUser_Response?.data?.message, 'error.600');
      } else {
        typeof HandleToast === 'function' &&
          HandleToast('Failed! Please try again later', 'error.600');
      }
    }
  };

  const renderHeader = () => {
    if (VenueId && VenueId > 0) {
      return "Venue User";
    } else if (modalType === 'EDIT') {
      return "Update User";
    }

    return "New User";
  }

  return <Modal.Content >

    {/* Modal Header */}
    <Modal.Header>
      {renderHeader()}
    </Modal.Header>

    {/* Modal Body */}
    <Modal.Body >
      {modalType === 'ADD' ? <FormControl mt={3} pb={1}>
        <FormControl.Label>
          <Text bold>User type</Text>
        </FormControl.Label>
        <Select
          placeholder="Select User"
          maxW={150}
          selectedValue={UserType}
          _selectedItem={{
            bg: 'teal.600',
            endIcon: <CheckIcon size="5" />,
          }}
          mt={1}
          onValueChange={(usertypeValue) => {
            setUserType(usertypeValue)
          }}
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
        >
          <Select.Item label="Admin User" value={"2"} />
          <Select.Item label="End User" value={"1"} />
        </Select>
      </FormControl> : null}

      <FormControl>
        <FormControl.Label>
          <Text bold>Name</Text>
        </FormControl.Label>
        {modalType === 'EDIT' ?
          <Input
            variant="filled"
            value={UserName || ''}
            selectionColor="white"
            onChange={(event) => setUserName(event.target.value)}
          />
          :
          <Input
            variant="filled"
            value={UserName || ''}
            selectionColor="white"
            onChange={(event) => setUserName(event.target.value)}
          />
        }
      </FormControl>

      <FormControl mt="3">
        <FormControl.Label>
          <Text bold>Email</Text>
        </FormControl.Label>
        {modalType === 'EDIT' ?
          <Input
            variant="filled"
            value={UserMail || ''}
            selectionColor="white"
            onChange={(event) => setUserMail(event.target.value)}
          />
          :
          <Input
            variant="filled"
            value={UserMail || ''}
            selectionColor="white"
            onChange={(event) => setUserMail(event.target.value)}
          />
        }
      </FormControl>

      {
        (VenueId && VenueId > 0 || UserType === "2" || UserType === "4") ? null :
          <FormControl mt="3">
            <FormControl.Label>
              <Text bold>Phone</Text>
            </FormControl.Label>
            {modalType === 'EDIT' ? (
              <Input
                variant="filled"
                value={UserPhone || ''}
                maxLength={10}
                selectionColor="white"
                onChange={(event) => setUserPhone(event.target.value)}
              />
            ) : (
              <Input
                variant="filled"
                value={UserPhone || ''}
                maxLength={10}
                selectionColor="white"
                onChange={(event) => setUserPhone(event.target.value)}
              />
            )}
          </FormControl>
      }
    </Modal.Body>

    {/* Modal Footer */}
    <Modal.Footer>
      <Button.Group space={2}>
        <Button
          bg="muted.300"
          borderRadius="lg"
          onPress={handleReset}
          _hover={{
            bg: 'muted.300',
          }}
        >
          Close
        </Button>
        {modalType === 'EDIT' ?
          <Button
            borderRadius="lg"
            onPress={() => {
              HandleSubmit();
              handleClose(false, 'save');
            }}
          >
            Save
          </Button>
          :
          <Button
            borderRadius="lg"
            onPress={() => {
              HandleSubmit();
              handleClose(false, 'save');
            }}
          >
            Add
          </Button>
        }
      </Button.Group>
    </Modal.Footer>

  </Modal.Content>;
});

const mapStateToProps = (state) => {
  const { UserDetailResponse } = state.user;
  return {
    UserDetail: UserDetailResponse || [],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    PostUser: (postreq) => dispatch(UserActions.PostUserDetails(postreq)).then((response) => response),
    GetUserDetailsById: (UserId) => dispatch(UserActions.GetUserDetailsByUserId(UserId)).then((response) => response),
    GetAllUserDetailsWithPaging: (data) => dispatch(UserActions.GetAllUserDetailsWithPaging(data)).then((response) => response),
    GetVenueUsersByVenueId: (venueId) => dispatch(VenueActions.GetVenueUsersByVenueId(venueId)).then((response) => response),
  };
};

AddOrEditUsers.propTypes = {
  loginResponse: PropTypes.any,
  PostUser: PropTypes.func,
  navigation: PropTypes.any,
  UserDetail: PropTypes.any,
  modalType: PropTypes.string,
  handleClose: PropTypes.func,
  HandleToast: PropTypes.any,
  createForUserType: PropTypes.any,
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(AddOrEditUsers);

const generatePassword = () => {
  var length = 8,
    charset =
      'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789',
    retVal = '';
  for (var i = 0, n = charset.length; i < length; ++i) {
    retVal += charset.charAt(Math.floor(Math.random() * n));
  }

  return retVal;
}
