// Redux :: Action Types
import {
  PRODUCT_IDLE,
  PRODUCT_PENDING,
  PRODUCT_FAILED,
  PRODUCT_SUCCEEDED,

  // Product Category (By Venue ID)
  PRODUCT_CATEGORY_BY_VENUE_ID_IDLE,
  PRODUCT_CATEGORY_BY_VENUE_ID_PENDING,
  PRODUCT_CATEGORY_BY_VENUE_ID_FAILED,
  PRODUCT_CATEGORY_BY_VENUE_ID_SUCCEEDED,

  INSERT_UPDATE_PRODUCT_IDLE,
  INSERT_UPDATE_PRODUCT_PENDING,
  INSERT_UPDATE_PRODUCT_FAILED,
  INSERT_UPDATE_PRODUCT_SUCCEEDED,

  // Order item
  GET_ORDER_ITEMS_BY_RESERVATION_ID_IDLE,
  GET_ORDER_ITEMS_BY_RESERVATION_ID_PENDING,
  GET_ORDER_ITEMS_BY_RESERVATION_ID_FAILED,
  GET_ORDER_ITEMS_BY_RESERVATION_ID_SUCCEEDED,

  // Product  (By  ID)
  PRODUCT_BY_ID_FAILED,
  PRODUCT_BY_ID_SUCCEEDED,
} from '../constants';


/* ----- PRODUCT CATEGORY INITIAL STATE ----- */
const productCategoryInitialState = {
  loading: false,
  idleErr: null,

  productCategoryErr: null,
  productCategoryResponse: [],
  productCategoryByVenueIDErr: null,
  productCategoryByVenueIDReponse: [],

  productByIdErr: null,
  productByIdResponse: [],
 

  insertUpdateProductLoading: false,
  insertUpdateProductIdleErr: null,
  insertUpdateProductErr: null,
  insertUpdateProductReponse: [],
}

/* ----- PRODUCT CATEGORY REDUCER ----- */
const productCategoryReducer = (state = productCategoryInitialState, action) => {
  switch (action.type) {

    case PRODUCT_CATEGORY_BY_VENUE_ID_PENDING:
      return {
        ...state,
        loading: true,
        productCategoryByVenueIDReponse: []
      };

    case PRODUCT_CATEGORY_BY_VENUE_ID_IDLE:
      return {
        ...state,
        loading: false,
        idleErr: action.payload
      };

    case PRODUCT_CATEGORY_BY_VENUE_ID_SUCCEEDED:
      return {
        ...state,
        loading: false,
        productCategoryByVenueIDReponse: action.payload
      };

    case PRODUCT_CATEGORY_BY_VENUE_ID_FAILED:
      return {
        ...state,
        loading: false,
        productCategoryByVenueIDErr: action.payload
      };

    case INSERT_UPDATE_PRODUCT_PENDING:
      return {
        ...state,
        insertUpdateProductReponse: []
      };

    case INSERT_UPDATE_PRODUCT_IDLE:
      return {
        ...state,
        insertUpdateProductIdleErr: action.payload
      };

    case INSERT_UPDATE_PRODUCT_SUCCEEDED:
      return {
        ...state,
        insertUpdateProductReponse: action.payload
      };

    case INSERT_UPDATE_PRODUCT_FAILED:
      return {
        ...state,
        insertUpdateProductErr: action.payload
      };

      

    default:
      return state;

  }
}

/* ----- PRODUCT INITIAL STATE ----- */
const initialState = {
  loading: false,
  idleErr: null,
  productErr: null,
  productResponse: [],
}

/* ----- PRODUCT REDUCER ----- */
const productReducer = (state = initialState, action) => {
  switch (action.type) {

    case PRODUCT_PENDING:
      return {
        ...state,
        loading: true,
        productResponse: []
      };

    case PRODUCT_IDLE:
      return {
        ...state,
        loading: false,
        idleErr: action.payload
      };

    case PRODUCT_SUCCEEDED:
      return {
        ...state,
        loading: false,
        productResponse: action.payload
      };

    case PRODUCT_FAILED:
      return {
        ...state,
        loading: false,
        productErr: action.payload
      };
      case PRODUCT_BY_ID_FAILED:
        return {
          ...state,
          loading: false,
          productByIdErr: action.payload
        };
        case PRODUCT_BY_ID_SUCCEEDED:
          return {
            ...state,
            loading: false,
            productByIdResponse: action.payload
          };

    default:
      return state;
  }
}

/* ----- ORDER ITEM INITIAL STATE ----- */
const orderItemInitialState = {
  loading: false,
  idleErr: null,
  orderItemErr: null,
  orderItemResponse: [],
}

/* ----- ORDER ITEM REDUCER ----- */
const OrderItemReducer = (state = orderItemInitialState, action) => {
  switch (action.type) {

    case GET_ORDER_ITEMS_BY_RESERVATION_ID_PENDING:
      return {
        ...state,
        loading: true,
        orderItemResponse: []
      };

    case GET_ORDER_ITEMS_BY_RESERVATION_ID_IDLE:
      return {
        ...state,
        loading: false,
        idleErr: action.payload
      };

    case GET_ORDER_ITEMS_BY_RESERVATION_ID_SUCCEEDED:
      return {
        ...state,
        loading: false,
        orderItemResponse: action.payload
      };

    case GET_ORDER_ITEMS_BY_RESERVATION_ID_FAILED:
      return {
        ...state,
        loading: false,
        orderItemErr: action.payload
      };

    default:
      return state;

  }
}

export { productReducer, productCategoryReducer, OrderItemReducer };