// Redux :: Action Types
import {
  UPDATE_PROFILE_IDLE,
  UPDATE_PROFILE_PENDING,
  UPDATE_PROFILE_FAILED,
  UPDATE_PROFILE_SUCCEEDED,
} from '../constants';

/* ----- INITIAL STATE ----- */
const initialState = {
  loading: false,
  idleErr: null,
  profileErr: null,
  profileResponse: [],
}

/* ----- profile REDUCER ----- */
const profileReducer = (state = initialState, action) => {
  switch (action.type) {

    case UPDATE_PROFILE_PENDING:
      return {
        ...state,
        loading: true,
        profileResponse: []
      };
    case UPDATE_PROFILE_IDLE:
      return {
        ...state,
        loading: false,
        idleErr: action.payload
      };
    case UPDATE_PROFILE_SUCCEEDED:
      return {
        ...state,
        loading: false,
        profileResponse: action.payload
      };

    case UPDATE_PROFILE_FAILED:
      return {
        ...state,
        loading: false,
        profileErr: action.payload
      };

    default:
      return state;

  }
}

export { profileReducer };
