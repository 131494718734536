import React, { useState, useRef, useEffect } from 'react';
import { AlertDialog, Center, Button } from 'native-base';

const AlertDialogBox = (props) => {
  const { Body, showAlert, handleClose } = props;
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(showAlert);
  }, [showAlert]);

  const onClose = (deleteType) => {
    setIsOpen(false);
    handleClose(false, deleteType);
  }

  const cancelRef = useRef(null);

  return <Center>
    <AlertDialog leastDestructiveRef={cancelRef} isOpen={isOpen} onClose={onClose}>
      <AlertDialog.Content>
        <AlertDialog.Body pt="5">
          {Body}
        </AlertDialog.Body>
        <AlertDialog.Footer>
          <Button.Group space={2}>
            <Button variant="unstyled" colorScheme="coolGray" onPress={()=> onClose("CANCEL")} ref={cancelRef}>
              Cancel
            </Button>
            <Button colorScheme="danger" onPress={()=> onClose("DELETE")}>
              Delete
            </Button>
          </Button.Group>
        </AlertDialog.Footer>
      </AlertDialog.Content>
    </AlertDialog>
  </Center>;
};

export default AlertDialogBox;