import React, { useState } from "react";
import { Box, Text, Icon, Flex, Button, Heading, Divider, FormControl, Input } from "native-base";
import { StyleSheet, Image } from "react-native";
import * as ImagePicker from "expo-image-picker";
import { Ionicons } from "@expo/vector-icons";

const Widget = () => {

  return <Box>

    <Box px="8" pt="4">
      <Heading size='lg'>Widget</Heading>
      <Divider my="3" bg="muted.400" />
    </Box>

    <Box pl="5">

     

      <Flex
        flexDirection="row"
        alignItems="center"
        justifyContent="flex-start"
        w="100%"
        mx="5"
        px="2"
      >
       
      </Flex>

    </Box>

  </Box>;
};


export default Widget;
