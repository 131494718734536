/* eslint-disable react-native/no-raw-text */
import React, { useState } from "react";
import { Link, Text, HStack, Flex, Popover, Box, useToast } from "native-base";
import PropTypes from 'prop-types';
import { FontAwesome } from '@expo/vector-icons';

/* ----- CONFIG ----- */
import * as CONFIG from '../config';

/* ----- UTILS ----- */
import { primaryColors } from '../utils/colors';
import { purgePersistStorage } from '../utils/common';

const MenuLinks = (props) => {
  const toast = useToast();
  const [isOpen, setIsOpen] = useState(false);
  const { menuLinks, link, navigation, route, isVenueAdmin } = props;

  let tempMenuLinks = [];
  if (menuLinks && menuLinks?.length > 0) {
    tempMenuLinks = menuLinks;
    if (isVenueAdmin) {
      tempMenuLinks = menuLinks.filter((x) => x?.route === "STATIC_LOGOUT");
    }
  }

  return <HStack px="3" py="3" alignItems='center'>
    {
      (tempMenuLinks || [])?.map((item, iteration) => {
        return <Link
          key={`${item.id}_${iteration}`}
          mx={12}
          href={link}
          display="flex"
          alignItems="center"
        >
          <Flex flexDirection={"row"} alignItems="center">
            {item?.subMenuOptions.length > 0 ? <Popover
              isOpen={isOpen}
              trapFocus
              onOpen={() => setIsOpen(true)}
              onClose={() => setIsOpen(false)}
              trigger={(triggerProps) => {
                return <Text fontSize="lg" fontWeight="normal" color={primaryColors?.primaryRed}
                  underline={item.route === route.name}
                  onPress={
                    async () => {
                      toast.closeAll();
                      if (item?.route === "STATIC_LOGOUT") {
                        try {
                          await setIsOpen(false);
                          await purgePersistStorage();
                          await navigation.navigate("Login");
                        } catch (exception) {
                          return exception;
                        }
                      } else {
                        navigation.navigate(item?.route);
                        setIsOpen(false);
                      }
                    }
                  }
                  {...triggerProps}
                >
                  {item?.name}
                  <Box pl="2">
                    <FontAwesome name={'chevron-down'} size={15} />
                  </Box>
                </Text>
              }}
            >
              <Popover.Content accessibilityLabel={item?.name} borderRadius={0}>
                <Popover.Body>
                  {
                    item?.subMenuOptions.map((item, iteration) => <Text
                      key={`${item?.name}_${iteration}`}
                      fontSize="lg"
                      fontWeight="normal"
                      color={primaryColors?.primaryRed}
                      underline={item.route === route.name}
                      onPress={
                        async () => {
                          toast.closeAll();
                          if (item?.route === "STATIC_LOGOUT") {
                            try {
                              await setIsOpen(false);
                              await purgePersistStorage();
                              // toast.show({
                              //   render: () => <Box bg="emerald.500" px="2" py="1" rounded="sm" mb={5}>Logout Success!</Box>
                              // });
                              await navigation.navigate("Login");
                            } catch (exception) {
                              return exception;
                            }
                          } else {
                            navigation.navigate(item?.route);
                            setIsOpen(false);
                          }
                        }
                      }
                    >
                      {item?.name}
                    </Text>)
                  }
                </Popover.Body>
              </Popover.Content>

            </Popover> : <Text fontSize="lg" fontWeight="normal" color={primaryColors?.primaryRed}
              underline={item.route === route.name}
              onPress={
                async () => {
                  toast.closeAll();
                  if (item?.route === "STATIC_LOGOUT") {
                    try {
                      await setIsOpen(false);
                      await purgePersistStorage();
                      // toast.show({
                      //   render: () => <Box bg="emerald.500" px="2" py="1" rounded="sm" mb={5}>Logout Success!</Box>
                      // });
                      await navigation.navigate("Login");
                    } catch (exception) {
                      console.log(exception);
                    }
                  } else {
                    navigation.navigate(item?.route);
                    setIsOpen(false);
                  }
                }
              }
            >
              {item?.name}
            </Text>}
          </Flex>
        </Link>
      }
      )
    }
  </HStack>;
}

MenuLinks.propTypes = {
  menuLinks: PropTypes.array.isRequired,
  link: PropTypes.string,
  navigation: PropTypes.any,
  route: PropTypes.any,
  isVenueAdmin: PropTypes.bool,
}

MenuLinks.defaultProps = {
  menuLinks: CONFIG.HEADER_MENU_OPTIONS
}

export default MenuLinks;
