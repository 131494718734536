import AsyncStorage from '@react-native-async-storage/async-storage';
import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
// import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

/* ----- REDUCERS ----- */
import authReducer from '../reducers/authReducer';
import { commonReducer, CountryLookUpReducer, stateLookUpReducer, cityLookUpReducer } from '../reducers/commonReducer';
import countReducer from '../reducers/countReducer';
import { venueReducer, CheckInReservationReducer, CancelReservationReducer, venueUserReducer } from '../reducers/venueReducer';
import { forgotPasswordReducer, OTPReducer, resetPasswordReducer } from '../reducers/forgotPasswordReducer';
import userReducer from '../reducers/UserReducer';
import { reservationReducer, reservationDetailReducer, userSearchReducer, createReservationReducer, tableDetailReducer, webReservationReducer, reservationFilterReducer, onlineWidgetReservationFilterStateReducer, onlineWidgetFilterReducer, onlineHeaderWidgetFilterReducer } from '../reducers/reservationReducer';
import { productReducer, productCategoryReducer, OrderItemReducer } from '../reducers/productReducer';
import { profileReducer } from '../reducers/profileReducer';
import { QueueReservationReducer, queueReservationStateReducer, QueueProductReducer, QueueSubProductReducer } from '../reducers/queueReducer';
import { activityReducer, insertOrUpdateActivityReducer } from '../reducers/activityReducer';
import { appsettingReducer } from '../reducers/appsettingReducer';
import cartReducer from '../reducers/cartReducer';

const rootReducer = combineReducers({
	auth: authReducer,
	venue: venueReducer,
	common: commonReducer,
	count: countReducer,
	forgotPassword: forgotPasswordReducer,
	OTP: OTPReducer,
	resetPassword: resetPasswordReducer,
	checkInReservation: CheckInReservationReducer,
	cancelReservation: CancelReservationReducer,
	user: userReducer,
	reservation: reservationReducer,
	reservationDetail: reservationDetailReducer,
	userSearch: userSearchReducer,
	createReservation: createReservationReducer,
	product: productReducer,
	productCategory: productCategoryReducer,
	profile: profileReducer,
	queueReservation: QueueReservationReducer,
	queueReservationState: queueReservationStateReducer,
	activity: activityReducer,
	updateActivity: insertOrUpdateActivityReducer,
	venueUser: venueUserReducer,
	tableDetail: tableDetailReducer,
	orderItem: OrderItemReducer,
	country: CountryLookUpReducer,
	countryState: stateLookUpReducer,
	city: cityLookUpReducer,
	webReservation: webReservationReducer,
	reservationFilter: reservationFilterReducer,
	QueueProduct: QueueProductReducer,
	QueueSubProduct: QueueSubProductReducer,
	appsetting: appsettingReducer,
	onlineWidgetReservationFilter: onlineWidgetReservationFilterStateReducer,
	onlineWidgetReservation: onlineWidgetFilterReducer,
	cart: cartReducer,
	onlineHeaderWidgetFilter: onlineHeaderWidgetFilterReducer
});

// Middleware: Redux Persist Config
const persistConfig = {
	key: 'root', // Root?
	storage: AsyncStorage,
	whitelist: ["auth", "appsetting","common"]
}

// Middleware: Redux Persist Persisted Reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Redux: Store
const middleware = [thunkMiddleware];
const store = createStore(persistedReducer, applyMiddleware(...middleware));

// Middleware: Redux Persist Persister
const persistor = persistStore(store);

export { store, persistor };




