import React, { useState, forwardRef, useEffect } from 'react';
import {
	Center,
	Button,
	Heading,
	Divider,
	FlatList,
	Avatar,
	VStack,
	HStack,
	Box,
	Spacer,
	Text,
	Icon,
	Popover,
	Flex,
} from 'native-base';
import Moment from 'moment';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Calendar } from 'react-date-range';
import { MaterialIcons } from '@expo/vector-icons';

/*Components */
import { SpinnerLoading } from '../../../shared/templates/SpinnerLoading';

/* ----- UTILS ----- */
import { primaryColors } from '../../../shared/utils/colors';
/* ----- REDUX :: ACTIONS ----- */
import * as VenueActions from '../../../shared/redux/actions/venue';


const CacheReport = (props) => {
	const { GetCacheReportDetailsByDate, venueId, headerTitleOptions, DownloadCacheReportForVenue, cacheReportDetailsLoading } = props;
	const [isDateTriggered, setTriggerDate] = useState(false);
	const [reports, setReports] = useState([]);
	const [fromDate, setFromDate] = useState(Moment().format('MM/DD/YYYY'));
	const [toDate, setToDate] = useState(Moment().format('MM/DD/YYYY'));
	const [isToDateTriggered, setTriggerToDate] = useState(false);

	useEffect(() => {
		const currentdate = new Date;
		getCacheReportListbyDate(Moment(currentdate).format('MM/DD/YYYY'));
	}, []);

	const getCacheReportListbyDate = async (date) => {
		if (venueId && venueId > 0) {
			const GetCacheReportByDate_Response = await GetCacheReportDetailsByDate(venueId, date, date).then((response) => response);
			if (GetCacheReportByDate_Response && GetCacheReportByDate_Response?.status == 200) {
				if (GetCacheReportByDate_Response?.data && GetCacheReportByDate_Response?.data?.length > 0) {
					setReports(GetCacheReportByDate_Response?.data);
				} else {
					setReports([]);
				}
			}
		}
	}

	const renderHeader = (headers) => {
		return <HStack justifyContent="flex-start" space={2} >
			{
				headers.map((item, iteration) => {
					return <Box w={item?.width} key={`${item?.key}_${iteration}`} h="10px" display="flex" flexDirection="row" alignItems="center"
						justifyContent={(item?.label === "Guest") ? "center"
							: " center"}>
						<Center>
							<Text fontWeight="bold" italic>{item?.label}</Text>
						</Center>
					</Box>;
				})
			}
		</HStack>
	}

	const renderRowItem = (rowItem, rowIteration) => {
		return <HStack justifyContent="flex-start" space={2}>
			{
				headerTitleOptions.map((item, iteration) => {
					return <Box key={`${item?.width}_${rowIteration}_v1${iteration}`} w={`${item?.width}`} >
						{item?.label === "Request Time" && <Text fontSize="md" textAlign="center" >{rowItem.requestTime}</Text>}
						{item?.label === "Table" && <Text fontSize="md" textAlign="left" marginLeft="15px">{rowItem.tableName ? rowItem.tableName : ""}</Text>}
						{item?.label === "Guest" && <Text fontSize="md" flexDirection="row" textAlign="center" >{rowItem.guest ? rowItem.guest : ""}</Text>}
						{item?.label === "Action Item" && <Text fontSize="md" textAlign="center" >{rowItem.actionItem ? rowItem.actionItem : ""}</Text>}
						{item?.label === "Cache Status" && <Text fontSize="md" textAlign="center" >{rowItem.cacheStatus ? rowItem.cacheStatus : ""}</Text>}
						{item?.label === "Cache Time" && <Text fontSize="md" textAlign="center"  >{rowItem.cacheTime}</Text>}
					</Box>;
				})
			}
		</HStack>;
	}

	return <>
		<Flex flexDirection="row" alignItems="center" w={"100%"}>
			<Heading size='lg'>Cache Report</Heading>
		</Flex>
		<Divider my="3" bg="muted.400" />
		<Flex
			flexDirection="row"
			alignItems="center"
			justifyContent="space-between"
			my="3"
			w={'100%'}
			mt={6}
		>
			<Flex
				flexDirection="row"
				alignItems="center"
				justifyContent="space-around"
			>
				<Text bold w="100%" pr="2" pl="1" fontSize="md">
					Date
				</Text>
				<Box pr={1} />
				<Popover
					isOpen={isToDateTriggered || false}
					onOpen={() => setTriggerToDate(true)}
					onClose={() => setTriggerToDate(false)}
					placement="bottom left"
					trigger={(triggerProps) => {
						return (
							<Button
								w="130"
								px="1"
								py="1"
								borderRadius="md"
								variant="outline"
								colorScheme="dark.50"
								leftIcon={
									<Icon
										as={MaterialIcons}
										name="calendar-today"
										size="4"
									/>
								}
								onPress={() => {
									setTriggerDate(false);
									setTriggerToDate(!isToDateTriggered);
								}}
								{...triggerProps}
							>
								{toDate}
							</Button>
						);
					}}
				>
					<Popover.Content
						accessibilityLabel="Filter By Date"
						w="full"
						bg="muted.300"
					>
						<Popover.Arrow />
						<Popover.Body>
							<Calendar
								className="webCalendarPicker"
								date={Moment(toDate, 'MM/DD/YYYY').toDate()}
								direction="vertical"
								fixedHeight
								color={primaryColors.primaryRed}
								onChange={(selectedDate) => {
									getCacheReportListbyDate(Moment(selectedDate).format('MM/DD/YYYY'));
									setFromDate(Moment(selectedDate).format('MM/DD/YYYY'));
									setToDate(Moment(selectedDate).format('MM/DD/YYYY'));
									setTriggerToDate(false);
								}}
							/>
						</Popover.Body>
					</Popover.Content>
				</Popover>

			</Flex>

			<Button
				backgroundColor={primaryColors.primaryRed}
				onPress={() => DownloadCacheReportForVenue(venueId, toDate, toDate)}
				leftIcon={<Icon as={MaterialIcons} name="file-download" size="5" />}
			>
				Download
			</Button>

		</Flex>
		<Box px="8" py="4" width="100%" justifyContent="center" bg="rgb(208, 208, 208)">{renderHeader(headerTitleOptions || [])}</Box>
		<Box minHeight="50">
			{
				!cacheReportDetailsLoading ? <>
					{
						reports && reports.length > 0 ? <>
							{
								(reports || []).map((item, iteration) => {
									return <Box key={iteration} px="8" py="2">{renderRowItem(item, iteration)} </Box>
								})
							}
						</> : <Box px="8" py="2" fontStyle="italic" fontWeight="bold" color="red" fontSize={15} display='flex' justifyContent='center' alignSelf='center'> NO REPORT FOUND</Box>
					}
				</> : <SpinnerLoading />
			}
		</Box>
	</>;
}

const mapStateToProps = (state) => {
	const { cacheReportDetailsResponse, cacheReportDetailsLoading } = state.venue;
	return { cacheReportDetailsResponse, cacheReportDetailsLoading };
}

const mapDispatchToProps = (dispatch) => {
	return {
		GetCacheReportDetailsByDate: (venueId, fromDate, toDate) => dispatch(VenueActions.GetCacheReportDetailsByDate(venueId, fromDate, toDate)).then((response) => response),
		DownloadCacheReportForVenue: (venueId, fromDate, toDate) => dispatch(VenueActions.DownloadCacheReportForVenue(venueId, fromDate, toDate)).then((response) => response),
	}
}


CacheReport.defaultProps = {
	headerTitleOptions: [
		{ key: '0_RequestTime', label: "Request Time", width: "12%" },
		{ key: '1_Table', label: "Table", width: "12%" },
		{ key: '2_Guest', label: "Guest", width: "12%" },
		{ key: '3_ActionItem', label: "Action Item", width: "15%" },
		{ key: '4_CacheStatus', label: "Cache Status", width: "12%" },
		{ key: '5_CacheTime', label: "Cache Time", width: "12%" },
	]
}

CacheReport.propTypes = {
	GetCacheReportDetailsByDate: PropTypes.func,
	cacheReportDetailsResponse: PropTypes.any,
	cacheReportDetailsLoading: PropTypes.any
};

export default connect(mapStateToProps, mapDispatchToProps)(CacheReport);


