import React, { useEffect, useState } from "react";
import { FormControl, Input, Text, Modal, Button, useToast } from 'native-base';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

/* ----- REDUX :: ACTIONS ----- */
import * as ProductCategoryActions from '../../../shared/redux/actions/productCategory';
import * as VenueActions from '../../../shared/redux/actions/venue';

const addOrEditProductCategory = (props) => {
  const { GetProductCategoryById, GetProductCategoryByVenueId, Id, ProductCategoryDetail, PostProductCategory, modalType, handleClose, venueid, CategoryId } = props;
  const [VenueId, setVenueId] = useState(0);
  const [PCategory, setPCategory] = useState("");
  const [isEditModal, setEditModal] = useState(false);

  useEffect(() => {
    setVenueId(venueid || 0);
  }, [venueid]);

  useEffect(() => {
    setEditModal(CategoryId && CategoryId > 0);
    if (CategoryId && CategoryId > 0) {
      loadProductCategorybyid();
    }
  }, [CategoryId]);

  useEffect(() => {
    setVenueId(venueid || '');
  }, [ProductCategoryDetail]);

  const loadProductCategorybyid = async () => {
    const response = await GetProductCategoryById(CategoryId || 0).then((result) => { return result; });
    if (response?.status == 200 && response?.statusText === "OK") {
      setPCategory(response?.data?.description);
    }

    return response;
  }

  const loadProductCategory = async () => {
    const response = await GetProductCategoryByVenueId(VenueId || 0).then((result) => { return result; });
    return response;
  }

  const ValidateFields = () => {
    if ((PCategory && PCategory !== "")) {
      return true;
    }

    return false;
  }

  const HandleSubmit = async () => {
    toast.closeAll();
    if (ValidateFields()) {

      let param = {};
      param.Description = PCategory || "";
      param.venueId = VenueId || 0;
      param.Code = PCategory || "";
      param.Isactive = true;
      if(isEditModal) {
        param.Id = CategoryId;
      }

      const PostUser_Response = await PostProductCategory(param).then((response) => response);
      if (PostUser_Response?.data?.status === "Success") {
        toast.show({
          render: () => <Box bg="emerald.500" px="2" py="1" rounded="sm" mb={5}>Product Category Added Successfully!</Box>
        });
        await loadProductCategory();
      } else {
        toast.show({
          render: () => <Box bg="error.600" px="2" py="1" rounded="sm" mb={5}>Failed! Please try again later</Box>
        });
      }
    } else {
      toast.show({
        render: () => <Box bg="error.600" px="2" py="1" rounded="sm" mb={5}>Please fill all required fields</Box>
      });
    }
  }

  const toast = useToast();

  return <Modal.Content>
    <Modal.Header>{isEditModal ? "Update Category" : "New Category"}</Modal.Header>
    <Modal.Body>
      <FormControl>
        <FormControl.Label>
          <Text bold>Category name</Text>
        </FormControl.Label>
        {
          modalType === "EDIT" ?
            <Input
              variant="filled"
              value={PCategory || ""}
              onChange={(event) => setPCategory(event.target.value)}
              selectionColor="white"
            />
            :
            <Text>{PCategory || "-"}</Text>
        }
      </FormControl>
    </Modal.Body>
    <Modal.Footer>
      <Button.Group space={2}>
        <Button
          bg="muted.300"
          borderRadius="lg"
          onPress={() => handleClose(false)}
          _hover={{
            bg: "muted.300"
          }}
        >
          Close
        </Button>
        {
          modalType === "EDIT" ? <Button
            // colorScheme="muted.300"
            borderRadius="lg"
            onPress={() => {
              HandleSubmit();
              handleClose(false);
            }}
          >
            Save
          </Button> : <></>
        }
      </Button.Group>
    </Modal.Footer>
  </Modal.Content>;
}

addOrEditProductCategory.propTypes = {
  loginResponse: PropTypes.any,
  PostProductCategory: PropTypes.func,
  navigation: PropTypes.any,
  ProductCategoryDetail: PropTypes.any,
  modalType: PropTypes.string,
  handleClose: PropTypes.func
}

const mapStateToProps = (state) => {
  const { UserDetailResponse } = state.user;

  return {
    ProductCategoryDetail: UserDetailResponse || [],
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    PostProductCategory: (postreq) => dispatch(ProductCategoryActions.InsertUpdateProductCategory(postreq)).then((response) => response),
    GetProductCategoryById: (CategoryId) => dispatch(ProductCategoryActions.GetProductCategoryById(CategoryId)).then((response) => response),
    GetProductCategoryByVenueId: (VenueId) => dispatch(VenueActions.GetProductCategoryByVenueId(VenueId)).then((response) => response),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(addOrEditProductCategory);