import RootStyles from './root';
import globalStyles from './global';
import webStyles from './web';
import { primaryColors } from '../utils/colors';

export {
  RootStyles,
  globalStyles,
  webStyles,
  primaryColors
}