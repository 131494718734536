/* eslint-disable react-native/no-raw-text */
import React, { useEffect, useState } from "react";
import { ImageBackground } from 'react-native';
import { Box, Heading, VStack, Link, FormControl, Input, Spinner, Button, Center, Text, Icon, Flex, WarningOutlineIcon, useToast } from "native-base";
import { Ionicons } from "@expo/vector-icons";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Redux :: Actions
import * as AuthActions from '../../../shared/redux/actions/auth';

// Utils
import * as IMAGES from '../../../shared/utils/images';
import { purgePersistStorage } from '../../../shared/utils/common';
import Linking from '../../../shared/templates/customLinking';

// Stylesheet
import { webStyles } from '../../../shared/styles';
import { primaryColors } from '../../../shared/utils/colors'

const LoginScreen = (props) => {
  const { navigation, loginResponse } = props;
  const toast = useToast();

  const [isLoading, setIsLoading] = useState(false);
  const [formSubmitted, setFormSubmit] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const renderValidationMessage = (msg, fieldName) => {
    let isValidFields = false;
    if ((fieldName && fieldName !== "")) {
      isValidFields = true;
    }

    if (formSubmitted && !isValidFields) {
      return <Flex mt="1.5" direction="row">
        <WarningOutlineIcon size="xs" color="warning.500" pr="2" />
        <Text color="warning.500" italic>{msg || ""}</Text>
      </Flex>;
    }

    return null;
  }


  useEffect(() => {
    const { userRoles } = loginResponse;
    if (userRoles?.includes('Admin')) {
      Linking.openURL(`/Venue`);
    } else if (userRoles?.includes('VenueAdmin')) {
      Linking.openURL(`/Venue/${loginResponse?.venueId}`);
    }
  }, [])



  const handleSubmit = async () => {
    const { login } = props;

    toast.closeAll();
    setIsLoading(true);
    setFormSubmit(true);

    if ((username && username !== "") && (password && password !== "")) {
      const loginCredentials = { username, password };
      const loginResponse = await login(loginCredentials).then((response) => response);

      if (loginResponse?.status === 200) {
        const { userRoles } = loginResponse.data;
        if (userRoles?.includes("Admin") || userRoles?.includes("VenueAdmin")) {
          toast.show({
            render: () => <Box bg="emerald.500" px="2" py="1" rounded="sm" mb={5}>Login Success!</Box>
          });
          setTimeout(() => {
            Linking.openURL(`/Venue`);
          }, 500)
        } else {
          await purgePersistStorage();
          toast.show({
            render: () => <Box bg="error.600" px="2" py="1" rounded="sm" mb={5}>Login Failed! Please check your credentials and try again.</Box>
          });
        }
        setIsLoading(false);
      } else {
        toast.show({
          render: () => <Box bg="error.600" px="2" py="1" rounded="sm" mb={5}>Login Failed! Please check your credentials and try again.</Box>
        });
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  }

  const RedirectLink = () => navigation.navigate("ForgotPassword");

  return <ImageBackground source={IMAGES.BG_WEB_IMG} resizeMode="cover" style={webStyles.appBG}>
    <Center flex={1} px="3">
      <Box width="400" bg="rgba(255,255,255,0.1)" rounded="lg" px="10" py="10">
        <Center flex={1} px="3">
          <Heading size="lg" fontWeight="600" color={primaryColors.lightOrange}>Welcome to REVLR</Heading>
          <Heading mt="3" color="#FFF" fontWeight="bold" size="xs">Sign in to continue!</Heading>
          <VStack space={3} mt="10" width="300">
            <FormControl isRequired>
              <FormControl.Label>
                <Text color="#ffedd5">Username</Text>
              </FormControl.Label>
              <Input
                type="email-address"
                bg="#FFFFFF"
                selectionColor="white"
                placeholder="Please enter your username here"
                defaultValue={username || ""}
                onChange={(event) => setUsername(event.target.value)}
              />
              {renderValidationMessage("Field is required", username)}
            </FormControl>
            <FormControl isRequired>
              <FormControl.Label>
                <Text color="#ffedd5">Password</Text>
              </FormControl.Label>
              <Input
                type="password"
                bg="#FFFFFF"
                selectionColor="white"
                placeholder="Please enter your password here"
                defaultValue={password || ""}
                onChange={(event) => setPassword(event.target.value)}
              />
              {renderValidationMessage("Field is required", password)}
              <Flex direction="row" justifyContent="flex-end" mt="1">
                <Link
                  _text={{ fontSize: "xs", color: primaryColors.lightOrange }}
                  // href="#"
                  isUnderlined
                  _hover={{
                    _text: { color: "#ffffff" }
                  }}
                  onPress={RedirectLink}
                >
                  Forgot Password?
                </Link>
              </Flex>
            </FormControl>
            <Button
              mt="2"
              bgColor="#fc1a17"
              color="primary.50"
              leftIcon={
                isLoading ? <Spinner color="primary.50" />
                  : <Icon as={Ionicons} name="log-in" size="sm" />
              }
              onPress={handleSubmit}
            >Sign in</Button>
          </VStack>
        </Center>
      </Box>
    </Center>
  </ImageBackground>;
}

const mapStateToProps = (state) => {
  const { auth } = state;
  const { loginResponse } = auth;
  return {
    loading: auth.loading,
    loginResponse: loginResponse
  };
}

const mapDispatchToProps = (dispatch) => ({
  login: (credentials) => dispatch(AuthActions.login(credentials)).then((response) => response)
});

LoginScreen.propTypes = {
  login: PropTypes.func,
  navigation: PropTypes.any
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginScreen);