/* eslint-disable react-native/no-inline-styles */
/* eslint-disable react-native/no-color-literals */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react-native/no-raw-text */
import React, { useEffect, useState } from "react";
import { Flex, Center, Box, Heading, Divider, Text, Input, Button, Icon, useToast, navigate } from 'native-base';
import { FontAwesome } from '@expo/vector-icons';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Ionicons } from "@expo/vector-icons";
import Moment from "moment";

// Redux :: Components
import { SpinnerLoading } from '../../../shared/templates/SpinnerLoading';
import { primaryColors } from '../../../shared/utils/colors';
import ModalWrapper from '../../Modal';
import Linking from '../../../shared/templates/customLinking';
import { AccordionCtrl } from '../../../shared/templates';
import AlertDialog from '../../AlertDialog';

/* ----- REDUX :: ACTIONS ----- */
import * as VenueActions from '../../../shared/redux/actions/venue';
import * as ProductCategoryActions from '../../../shared/redux/actions/productCategory';

const VenueCategory = (props) => {
	const [updatedCategoryProducts, setUpdatedCategoryProducts] = useState([]);
	const { navigation, productCategoryResponse, productCategoryLoading, venueId, GetProductCategoryByVenueId, CategoriesCount, Productscount,
		InsertUpdateProductCategory, InsertUpdateProduct, DeleteProduct,
		DeleteProductCategory,
		loginResponse, isCategoryAccordion, CategoryAccordionList, viewType, venueDetail
	} = props;

	const [showAlert, setshowAlert] = useState(false);
	const [activeItem, setActiveItem] = useState(null);
	const [showModal, setShowModal] = useState(false);
	const [isCategoryEdit, setCategoryEdit] = useState(false);
	const [modalType, setModalType] = useState("VIEW");
	const [venueMenuOperationType, setVenueMenuOperationType] = useState("VIEW_TYPE");
	const [type, setType] = useState("");
	const [productId, setproductId] = useState(0);
	const [categoryId, setcategoryId] = useState(0);
	const [activeProductItem, setActiveProductItem] = useState(null);
	const [showProductAlert, setShowProductAlert] = useState(false);

	const toast = useToast();

	useEffect(() => {
		if (venueDetail?.id) {
			loadVenueCategory(venueDetail?.id);
		}
	}, [venueDetail]);

	useEffect(() => {
		let productCategoryResponseArr = Array.from({ length: CategoriesCount }, (_, categoryIteration) => { // Framed Dummy Data at initial
			return {
				categoryId: productCategoryResponse && productCategoryResponse?.length > 0 ?
					productCategoryResponse[categoryIteration]?.id : 0,
				categoryName: productCategoryResponse && productCategoryResponse?.length > 0 ?
					productCategoryResponse[categoryIteration]?.description : "",
				updatedCategoryId: productCategoryResponse && productCategoryResponse?.length > 0 ?
					productCategoryResponse[categoryIteration]?.id : 0,
				updatedCategoryName: productCategoryResponse && productCategoryResponse?.length > 0 ?
					productCategoryResponse[categoryIteration]?.description : "",
				categorySequence: categoryIteration,
				products: Array.from({ length: Productscount }, (_, productIteration) => {
					return {
						productCategoryId: productCategoryResponse && productCategoryResponse?.length > 0 ?
							productCategoryResponse[categoryIteration]?.id : 0,
						productCategoryName: productCategoryResponse && productCategoryResponse?.length > 0 ?
							productCategoryResponse[categoryIteration]?.description : "",
						productId: productCategoryResponse && productCategoryResponse?.length > 0 && productCategoryResponse[categoryIteration]?.products?.length > 0 ?
							productCategoryResponse[categoryIteration]?.products[productIteration]?.id : 0,
						productName: productCategoryResponse && productCategoryResponse?.length > 0 && productCategoryResponse[categoryIteration]?.products?.length > 0 ?
							productCategoryResponse[categoryIteration]?.products[productIteration]?.title : "",
						UpdatedProductId: productCategoryResponse && productCategoryResponse?.length > 0 && productCategoryResponse[categoryIteration]?.products?.length > 0 ?
							productCategoryResponse[categoryIteration]?.products[productIteration]?.id : 0,
						UpdatedProductName: productCategoryResponse && productCategoryResponse?.length > 0 && productCategoryResponse[categoryIteration]?.products?.length > 0 ?
							productCategoryResponse[categoryIteration]?.products[productIteration]?.title : "",
						productSequence: productIteration
					}
				})
			};
		});

		setUpdatedCategoryProducts(productCategoryResponseArr ? productCategoryResponseArr : []);
	}, [productCategoryResponse])

	const loadVenueCategory = async (venueId) => {
		let GetVenueTableByVenueId_Response = await GetProductCategoryByVenueId(venueId);
		return GetVenueTableByVenueId_Response;
	}

	const HandleDelete = async (id) => {
		let delete_reponse = await DeleteProduct(id);
		if (delete_reponse?.data?.status === "Success") {
			toast.show({
				render: () => <Box bg="emerald.500" px="2" py="1" rounded="sm" mb={5}>Product deleted Successfully!</Box>
			});
			await loadVenueCategory(venueDetail?.id);
		} else {
			toast.show({
				render: () => <Box bg="error.600" px="2" py="1" rounded="sm" mb={5}>Failed! Please try again later</Box>
			});
		}
	}

	const HandleCategoryDelete = async (id) => {
		let delete_reponse = await DeleteProductCategory(id);
		if (delete_reponse?.data?.status === "Success") {
			toast.show({
				render: () => <Box bg="emerald.500" px="2" py="1" rounded="sm" mb={5}>Category deleted Successfully!</Box>
			});
			await loadVenueCategory(venueDetail?.id);
		} else {
			toast.show({
				render: () => <Box bg="error.600" px="2" py="1" rounded="sm" mb={5}>Failed! Please try again later</Box>
			});
		}
	}

	const HandleSubmit = () => {
		toast.closeAll();

		if (updatedCategoryProducts && updatedCategoryProducts?.length > 0) {
			let promises = [];

			// Update Category
			updatedCategoryProducts?.map((updatedCategoryItem, updatedProductIteration) => {

				if (updatedCategoryItem?.updatedCategoryName !== updatedCategoryItem?.categoryName) { // Proceeds only if value changes
					promises.push(
						InsertUpdateProductCategory({
							id: updatedCategoryItem?.categoryId,
							venueId: venueId,
							code: updatedCategoryItem?.updatedCategoryName?.toUpperCase().replace(/\s/g, ''),
							description: updatedCategoryItem?.updatedCategoryName?.toUpperCase().replace(/\s/g, '')
						}).then((result) => { return result; })
					);
				}

				// Update Product
				updatedCategoryItem?.products?.map((productItem, productIteration) => {
					if (productItem?.UpdatedProductName !== productItem?.productName) { // Proceeds only if value changes
						let updateParam = {};
						updateParam.id = productItem?.productId;
						updateParam.venueid = venueDetail?.id;
						updateParam.title = productItem?.UpdatedProductName;
						updateParam.description = productItem?.UpdatedProductName?.toUpperCase().replace(/\s/g, '')
						updateParam.sku = productItem?.UpdatedProductName?.toUpperCase().replace(/\s/g, '');
						// updateParam.quantity = 1;
						// updateParam.price = 79.99;
						// updateParam.discount = 0;
						updateParam.categoryId = productItem?.productCategoryId;
						updateParam.createdBy = loginResponse?.user?.firstName;
						updateParam.createdDate = Moment().format("YYYY-MM-DD[T]HH:mm:ss");
						updateParam.updatedBy = loginResponse?.user?.firstName;
						updateParam.updatedDate = Moment().format("YYYY-MM-DD[T]HH:mm:ss");
						updateParam.isactive = true;

						promises.push(
							InsertUpdateProduct(updateParam).then((result) => { return result; })
						);
					}
				});

			});

			Promise.all(promises).then((res) => {
				redirectURL(`/Venue/${venueDetail?.id}`, false)
			});

		}
	}

	const UpdateProductItem = (value, updateCategoryIndex, updateProductIndex) => {
		let updatedCategoryProductsArr = [...updatedCategoryProducts];
		updatedCategoryProductsArr[updateCategoryIndex].products[updateProductIndex].UpdatedProductName = value;

		setUpdatedCategoryProducts(updatedCategoryProductsArr);
	}

	const UpdateCategoryItem = (value, updateCategoryIndex) => {
		let updatedCategoryProductsArr = [...updatedCategoryProducts];
		updatedCategoryProductsArr[updateCategoryIndex].updatedCategoryName = value;

		setUpdatedCategoryProducts(updatedCategoryProductsArr);
	}

	const AddProductHandler = (item) => redirectURL(`/AddOrEditProduct/${venueDetail?.id}/${item?.id}/0`, false);

	const UpdateProductHandler = (item) => redirectURL(`/AddOrEditProduct/${venueDetail?.id}/${item?.categoryId}/${item?.id}`, false);

	const redirectURL = (redirectBaseURL, isTargetBlank) => {
		if (isTargetBlank) {
			Linking.openURL(redirectBaseURL, '_blank')
		} else {
			Linking.openURL(redirectBaseURL)
		}
	}

	return <Box pb="10">
		<Flex flexDirection="row" alignItems="center" mt="4" pb="4" w="75%">
			<Heading size='lg' minWidth="200px">Venue Menu</Heading>
			{
				!isCategoryEdit && <Button
					background={primaryColors.primaryRed}
					w="150"
					pl="3"
					pr="3"
					pt="1"
					pb="1"
					ml="2"
					mr="2"
					variant="outline"
					borderRadius="full"
					onPress={() => {
						if (viewType === "CATEGORY_IN_WEBSITE_OPERATIONS_VIEW") {
							setModalType("EDIT");
							setShowModal(true);
							setType('productcategory')
						}
						if (!isCategoryAccordion) {
							setCategoryEdit(!isCategoryEdit);
						}
					}}
					leftIcon={<Icon as={FontAwesome} name={isCategoryAccordion ? "plus-circle" : "edit"} size="xs" fontWeight="bold" color="white" />}
					_text={{
						fontSize: 'xs',
						fontWeight: "bold",
						color: 'white'
					}}
					style={{
						visibility: isCategoryAccordion ? "block"
							: (!isCategoryAccordion && !productCategoryLoading && productCategoryResponse?.length > 0) ? "block"
								: "hidden"
					}}
				>
					{isCategoryAccordion ? "Add Category" : "Edit Menu"}
				</Button>
			}
		</Flex>

		{!isCategoryAccordion && <Divider my="3" bg="muted.400" w={"100%"} />}

		{
			productCategoryResponse?.length > 0 ?
				<div>
					{
						!productCategoryLoading ? <>
							{
								isCategoryAccordion ?
									<AccordionCtrl
										key={Math.random()}
										data={CategoryAccordionList}
										aw={"80%"}
										btnTitle={"Product"}
										AddHandler={(item) => AddProductHandler(item)}
										EditCategoryHandler={(item) => {
											if (viewType === "CATEGORY_IN_WEBSITE_OPERATIONS_VIEW") {
												setModalType("EDIT");
												setShowModal(true);
												setType('productcategory');
												setcategoryId(item?.id || 0);
											}
										}}
										DeleteHandler={(item) => {
											setShowProductAlert(true);
											setActiveProductItem(item);
										}}
										DeleteCategoryHandler={(item) => {
											setshowAlert(true);
											setActiveItem(item);
										}}
										EditHandler={(item) => { UpdateProductHandler(item) }}
									/>
									:
									<Box>
										{
											(updatedCategoryProducts && updatedCategoryProducts.length > 0 && viewType === "CATEGORY_IN_DETAIL_VIEW")
												|| viewType === "CATEGORY_IN_WEBSITE_OPERATIONS_VIEW"
												? <>
													{
														updatedCategoryProducts && updatedCategoryProducts.map((item, iteration) => {

															if (venueMenuOperationType === "VIEW_TYPE") {
																if (item?.categoryName == null || item?.categoryName == undefined || item?.categoryName === "") {
																	return null;
																}

																return <Flex
																	key={`category_product_list_${iteration}_${item?.categoryId}`}
																	flexDirection="row"
																	alignItems="center"
																	mt="4"
																	w={"100%"}
																>
																	{/* category Headings */}
																	<Box key={`CategoryItem_${iteration}_${item?.categoryId}`}>
																		<Box
																			variant="outline"
																			// mx="2"
																			minHeight={35}
																			w="150"
																			px="2"
																			py="1"
																			borderRadius={5}
																			style={{
																				background: '#A8A8A8'
																			}}
																			_text={{
																				fontWeight: 'bold',
																				textAlign: 'center',
																				textTransform: 'uppercase'
																			}}
																		>
																			{
																				isCategoryEdit ?
																					<Input
																						key={`categoryInput_${iteration}`}
																						// ref={(el) => (categoryRefs.current[`categoryInput_${rowIteration}`] = el)}
																						type="default"
																						bg="#FFFFFF"
																						placeholder={item?.updatedCategoryName?.toUpperCase() || ""}
																						defaultValue={item?.updatedCategoryName?.toUpperCase() || ""}
																						value={item?.updatedCategoryName?.toUpperCase() || ""}
																						onChange={(event) => UpdateCategoryItem(event.target.value?.toUpperCase(), iteration)}
																					/>
																					:
																					item?.updatedCategoryName?.toUpperCase() || ""
																			}
																		</Box>
																	</Box>

																	<Box ml="2" key={`CategoryIndicator_${iteration}_${item?.categoryId}`}>
																		<Icon
																			as={FontAwesome}
																			name="arrow-right"
																			size="sm"
																			fontWeight="bold"
																			color="darkText"
																		/>
																	</Box>
																	{/* category items */}
																	{
																		item?.products?.map((productItem, productIteration) => {
																			return <Box key={`ProductItem_${iteration}_${productIteration}_${productItem?.productId}`}>
																				<Box
																					variant="outline"
																					mx="2"
																					minHeight={35}
																					w="150"
																					px="2"
																					py="1"
																					borderRadius={5}
																					style={{
																						background: '#D0D0D0'
																					}}
																					_text={{
																						textAlign: 'center'
																					}}
																				>
																					{
																						isCategoryEdit ?
																							<Input
																								key={`categoryInput_${iteration}`}
																								// ref={(el) => (categoryRefs.current[`categoryInput_${rowIteration}`] = el)}
																								type="default"
																								bg="#FFFFFF"
																								placeholder={productItem?.UpdatedProductName || ""}
																								defaultValue={productItem?.UpdatedProductName || ""}
																								value={productItem?.UpdatedProductName || ""}
																								onChange={(event) => UpdateProductItem(event.target.value, iteration, productIteration)}
																							/>
																							:
																							productItem?.UpdatedProductName || ""
																					}
																				</Box>
																			</Box>
																		})
																	}

																</Flex>
															} else if (venueMenuOperationType === "EDIT_TYPE") {
																if (item?.categoryName == null || item?.categoryName == undefined || item?.categoryName === "") {
																	return null;
																}

																return <Flex
																	key={`category_product_list_${iteration}_${item?.categoryId}`}
																	flexDirection="row"
																	alignItems="center"
																	mt="8"
																	w={"100%"}
																>

																	<Box key={`CategoryItem_${iteration}_${item?.categoryId}`}>
																		<Input
																			variant="outline"
																			mx="3"
																			value={item?.updatedCategoryName || ""}
																			placeholder="Category"
																			w="150"
																			style={{
																				borderWidth: 1,
																				borderColor: '#000000'
																			}}
																		/>
																	</Box>

																	<Box key={`CategoryIndicator_${iteration}_${item?.categoryId}`}>
																		<Icon
																			as={FontAwesome}
																			name="arrow-right"
																			size="sm"
																			fontWeight="bold"
																			color="darkText"
																		/>
																	</Box>

																	{
																		item?.products?.map((productItem, productIteration) => {
																			return <Box key={`ProductItem_${iteration}_${productIteration}_${productItem?.productId}`}>
																				<Input
																					variant="outline"
																					mx="3"
																					value={productItem?.UpdatedProductName || ""}
																					placeholder={`Product ${productIteration + 1}`}
																					w="150"
																					style={{
																						borderWidth: 1,
																						borderColor: '#000000'
																					}}
																				/>
																			</Box>
																		})
																	}

																</Flex>
															}
														})
													}
												</> : <Box my={2}>
													<Text bold fontSize="lg">No categories found!</Text>
												</Box>
										}

										{
											isCategoryEdit ?
												<Flex flexDirection="row" alignItems="center" w="100%" px="2" mt="4">
													<Button
														mt="2"
														mr="2"
														bgColor="#f97316"
														color="#FFFFFF"
														w="100"
														leftIcon={<Icon as={Ionicons} name="ios-save" size="xs" />}
														_text={{
															color: "#FFFFFF"
														}}
														onPress={HandleSubmit}
													>
														Save
													</Button>
													<Button
														mt="2"
														mr="2"
														bgColor="#fc1a17"
														color="primary.50"
														w="100"
														leftIcon={<Icon as={Ionicons} name="ios-close" size="xs" />}
														onPress={() => setCategoryEdit(false)}
													>
														Cancel
													</Button>
												</Flex> : null
										}

									</Box>
							}
						</> : <SpinnerLoading />
					}
				</div> : <Box my={2}>
					{
						viewType !== "CATEGORY_IN_WEBSITE_OPERATIONS_VIEW" ?
							<Text bold fontSize="lg">No categories found! Please add more categories in website operations section</Text>
							: <Text bold fontSize="lg">No categories found! Please add a category</Text>
					}
				</Box>
		}

		{
			showModal == true ?
				<ModalWrapper
					CatId={categoryId}
					prodId={productId}
					type={type}
					page='user'
					venueid={venueDetail?.id}
					modalType={modalType || "VIEW"}
					IsOpen={showModal}
					handleClose={(status) => {
						setcategoryId(0);
						setModalType("VIEW");
						setShowModal(status);
					}}
				/>
				: null
		}

		<AlertDialog
			showAlert={showAlert || false}
			Header="Delete Item"
			Body={
				<Center>
					<Text fontSize="lg">Are you sure want to delete this item?</Text>
					<Text bold fontSize="md">{activeItem?.title || ""}</Text>
				</Center>
			}
			Name={""}
			handleClose={
				async (status, deleteType) => {
					if (deleteType == "DELETE") {
						if (activeItem?.id && activeItem?.categoryId) {
							await HandleDelete(activeItem?.id);
						} else {
							if (venueDetail?.id) {
								await HandleCategoryDelete(activeItem?.id);
								await loadVenueCategory(venueDetail?.id);
							}
						}
						await setActiveItem(null);
						await setshowAlert(status);
					} else {
						await setActiveItem(null);
						await setshowAlert(status);
					}
				}
			}
		/>

		<AlertDialog
			showAlert={showProductAlert || false}
			Header='Delete Product'
			Body={
				<Center>
					<Text fontSize="lg">Are you sure want to delete this Product?</Text>
					<Text bold fontSize="md">{activeProductItem?.title || ""}</Text>
				</Center>
			}
			Name={""}
			handleClose={
				async (status, deleteType) => {
					if (deleteType == "DELETE") {
						await HandleDelete(activeProductItem?.id);
						await setActiveProductItem(null);
						await setShowProductAlert(status);
					} else {
						await setActiveProductItem(null);
						await setShowProductAlert(status);
					}
				}}
		/>

	</Box>;
}

VenueCategory.defaultProps = {
	productCategoryResponse: [],
	CategoryAccordionList: [],
	CategoriesCount: 6,
	Productscount: 6,
}

VenueCategory.propTypes = {
	venueId: PropTypes.any,
	GetProductCategoryByVenueId: PropTypes.func,
	productCategoryResponse: PropTypes.any,
	CategoryAccordionList: PropTypes.any,
	CategoriesCount: PropTypes.number,
	Productscount: PropTypes.number,
	loginResponse: PropTypes.any,
	productCategoryLoading: PropTypes.bool,
	isCategoryAccordion: PropTypes.any,
	navigation: PropTypes.any,
	venueDetail: PropTypes.any,
	DeleteProductCategory: PropTypes.func,
}

const mapStateToProps = (state) => {
	const { productCategoryResponse, productCategoryLoading, CategoryAccordionList, VenuesDetailResponse } = state.venue;
	const { loginResponse } = state.auth;

	return {
		productCategoryResponse,
		CategoryAccordionList,
		productCategoryLoading,
		loginResponse,
		venueDetail: VenuesDetailResponse || []
	};
}

const mapDispatchToProps = (dispatch) => {
	return {
		GetProductCategoryByVenueId: (venueId) => dispatch(VenueActions.GetProductCategoryByVenueId(venueId)).then((response) => response),
		InsertUpdateProductCategory: (data) => dispatch(ProductCategoryActions.InsertUpdateProductCategory(data)).then((response) => response),
		InsertUpdateProduct: (data) => dispatch(ProductCategoryActions.InsertUpdateProduct(data)).then((response) => response),
		DeleteProduct: (Id) => dispatch(ProductCategoryActions.DeleteProduct(Id)).then((response) => response),
		DeleteProductCategory: (Id) => dispatch(ProductCategoryActions.DeleteProductCategory(Id)).then((response) => response),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(VenueCategory);