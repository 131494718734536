/* eslint-disable react-native/no-color-literals */
/* eslint-disable react-native/no-inline-styles */
/* eslint-disable react-native/no-raw-text */
import React, { useEffect, useState, useRef } from "react";
import { Icon, Box, Button, Heading, Divider, TextArea, useToast, Flex } from "native-base";
import { FontAwesome } from '@expo/vector-icons';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Redux :: Components
import { primaryColors } from '../../../shared/utils/colors';

const VenueScript = (props) => {
  const { venueDetail } = props;

  const [widgetScript, setwidgetScript] = useState("");

  const textAreaRef = useRef(null);
  const toast = useToast();

  useEffect(() => {
    setwidgetScript(venueDetail?.widgetScript || '');
  }, [venueDetail]);

  const copyToClipboard = (e) => {
    textAreaRef.current.select();
    document.execCommand('copy');
    e.target.focus();
    toast.show({
      render: () => <Box bg="emerald.500" px="2" py="1" rounded="sm" mb={5}>Script Copied Successfully!</Box>
    });
  };

  return <>
    <Box mt="8" w={"100%"}>
      <Flex flexDirection="row" alignItems="center" w={"100%"}>
        <Heading size='lg'>Script</Heading>
        <Button
          background={primaryColors.primaryRed}
          w="100"
          pl="3"
          pr="3"
          pt="1"
          pb="1"
          ml="2"
          mr="2"
          mt="2"
          variant="outline"
          borderRadius="full"
          onPress={copyToClipboard}
          leftIcon={<Icon as={FontAwesome} name="copy" size="xs" fontWeight="bold" color="white" />}
          _text={{
            fontSize: 'xs',
            fontWeight: "bold",
            color: 'white'
          }}
        >
          Copy
        </Button>
      </Flex>

      <Divider my="3" bg="muted.400" />
      <TextArea
        ref={textAreaRef}
        shadow={2}
        style={{ width: "100%", height: '180px', maxHeight: "200px", backgroundColor: 'black', color: 'rgb(166, 166, 166)' }}
        value={widgetScript || "-"}
        placeholder="" w="200"
      />
    </Box>
  </>;
}

const mapStateToProps = (state) => {
  const { VenuesDetailResponse } = state.venue;

  return {
    venueDetail: VenuesDetailResponse || []
  };
}

VenueScript.propTypes = {
  venueDetail: PropTypes.any,
}

export default connect(mapStateToProps)(VenueScript);