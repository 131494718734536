/* eslint-disable react-native/no-raw-text */
import React, { useState, useRef, useEffect } from 'react';
import {
  Dimensions,
  Pressable,
  ScrollView,
} from 'react-native';
import {
  Box,
  Flex,
  Spacer,
  Button,
  Switch,
  Text,
  Fade,
  Icon,
  SlideFade,
  HStack,
  Center,
  useToast,
  Popover,
} from 'native-base';
import { DataTable } from 'react-native-paper';
import { FontAwesome } from '@expo/vector-icons';
import { MaterialIcons } from '@expo/vector-icons';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import Moment from 'moment';
import { Calendar } from 'react-date-range';
import PropTypes from 'prop-types';


/* ----- COMPONENTS ----- */
import { ReportModal } from '../../../shared/templates';
import { SpinnerLoading } from '../../../shared/templates/SpinnerLoading';
import Linking from '../../../shared/templates/customLinking';

/* ----- REDUX :: ACTIONS ----- */
import * as VenueActions from '../../../shared/redux/actions/venue';
import * as CommonActions from '../../../shared/redux/actions/common';

/* ----- UTILS ----- */
import { primaryColors } from '../../../shared/utils/colors';


const VenueList = (props) => {
  const {
    navigation,
    loginResponse,
    GetAllVenueDetailsWithPaging,
    venuesListWithPagingResponse,
    headerTitleOptions,
    ResetVenueDetailForm,
    ToggleVenueState,
  } = props;

  const [isReady] = useState(true);
  const [startdate, setStartDate] = useState(Moment().subtract(7, 'days').format('MM/DD/YYYY'));
  const [enddate, setEndDate] = useState(Moment().format('MM/DD/YYYY'));
  const [searchText] = useState('');
  const [sortField, setSortField] = useState('venueName');
  const [sortDir, setSortDir] = useState('Ascending');
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize] = useState(25);
  const [totalNumberOfPages, setTotalNumberOfPages] = useState(5);
  const [id] = useState(0);
  const [defaultSorting, setSorting] = useState(null);
  const [isDateTriggered, setTriggerDate] = useState(false);
  const [initLoading, setInitialLoading] = useState(true);
  const [openStartDate, setOpenStartDate] = useState(false);
  const [openEndDate, setOpenEndDate] = useState(false);

  const toast = useToast();
  const reportModalRef = useRef();

  useEffect(() => {
    toast.closeAll();
    loadInitData();
  }, []);

  useEffect(() => {
    !initLoading && loadVenueData();
  }, [pageNumber]);

  useEffect(() => {
    !initLoading && loadVenueData();
  }, [sortDir]);

  useEffect(() => {
    if (startdate) {
      setTimeout(() => loadVenueData(), 50);
    }
  }, [startdate]);

  useEffect(() => {
    if (enddate) {
      setTimeout(() => loadVenueData(), 50);
    }
  }, [enddate]);

  const loadInitData = () => {
    const { userRoles } = loginResponse;
    if (userRoles?.includes('VenueAdmin')) {
       Linking.openURL(`/Venue/${loginResponse?.venueId}`);
      loadVenueData();
      setInitialLoading(false);
    } else if(isEmpty(loginResponse)) {
      Linking.openURL(`/Login`);
      loadVenueData();
      setInitialLoading(false);
    }
  };

  const loadVenueData = async () => {
    let tmpParam = {
      searchText: searchText || '',
      sortField: sortField || '',
      sortDir: sortDir || '',
      pageNumber: (pageNumber + 1) || 1,
      pageSize: pageSize || 5,
      id: id || 0,
      startdate: Moment(startdate, 'MM/DD/YYYY').format('YYYY-MM-DD[T]hh:mm:ss') || '',
      enddate: Moment(enddate, 'MM/DD/YYYY').format('YYYY-MM-DD[T]hh:mm:ss') || ''
    };

    const response = await GetAllVenueDetailsWithPaging(tmpParam, false).then(
      (result) => {
        return result;
      }
    );
    if (response && response?.status == 200) {
      setTotalNumberOfPages(Math.ceil(response?.data?.totalCount / 25) - 1);
    }

    return response;
  };

  const renderHeader = (headers) => {
    return (
      <HStack space={3}>
        {headers.map((item, iteration) => {
          return (
            <Box
              w={item?.width}
              key={`${item?.key}_${iteration}`}
              alignItems="flex-start"
            >
              <Pressable
                onPress={() => {
                  if (item?.label == 'Venue' || item?.label === 'City') {
                    setSortDir(
                      sortDir == 'Descending' ? 'Ascending' : 'Descending'
                    );
                    setSorting(item?.label);
                    setSortField(item?.apiKey);
                  }
                }}
              >
                <Flex flexDirection="row" alignItems="flex-start">
                  <Text fontWeight="semibold" italic>
                    {item?.label}
                  </Text>
                  {(item?.label == 'Venue' || item?.label === 'City') && (
                    <Box pt="1" pl="2">
                      <Icon
                        as={FontAwesome}
                        name={
                          item?.apiKey === sortField && sortDir === 'Ascending'
                            ? 'chevron-up'
                            : 'chevron-down'
                        }
                        size="3"
                        color="#000"
                      />
                    </Box>
                  )}
                </Flex>
              </Pressable>
            </Box>
          );
        })}
      </HStack>
    );
  };

  const redirectVenueDetails = (item) => Linking.openURL(`/Venue/${item?.id}`);

  const updateVenueState = async (venueDetail, status) => {
    const response = await ToggleVenueState(venueDetail).then(
      (result) => result
    );
    if (response && response?.status == 200) {
      loadVenueData();
    }
  };

  const renderRowItem = (rowItem, rowIteration) => {
    return (
      <HStack justifyContent="flex-start" space={2}>
        {headerTitleOptions.map((item, iteration) => {
          return (
            <Box
              key={`${item?.width}_${rowIteration}_v1${iteration}`}
              w={`${item?.width}`}
            >
              {item?.label === 'Venue' && (
                <Pressable onPress={() => redirectVenueDetails(rowItem)}>
                  <Text color="primary.600" numberOfLines={1} bold underline>
                    {rowItem?.venueName}
                  </Text>
                </Pressable>
              )}
              {item?.label === 'Active' && (
                <Switch
                  color="secondary"
                  size="sm"
                  isChecked={rowItem?.isactive}
                  onToggle={(status) => updateVenueState(rowItem, status)}
                />
              )}
              {item?.label === 'City' && (
                <Text numberOfLines={1}>{rowItem?.cityName}</Text>
              )}
              {item?.label === 'Searches' && (
                <Center>
                  <Text>{rowItem.searches ? rowItem?.searches?.toLocaleString("en-US") : 0}</Text>
                </Center>
              )}
              {item?.label === 'Table Clicks' && (
                <Center>
                  <Text>
                    {rowItem.tableClicks ? rowItem?.tableClicks?.toLocaleString("en-US") : 0}
                  </Text>
                </Center>
              )}
              {item?.label === 'Product Clicks' && (
                <Center>
                  <Text>{rowItem.productClicks ? rowItem?.productClicks?.toLocaleString("en-US") : 0}</Text>
                </Center>
              )}
              {item?.label === 'Bookings' && (
                <Center>
                  <Text>{rowItem.bookings ? rowItem?.bookings?.toLocaleString("en-US") : 0}</Text>
                </Center>
              )}
              {item?.label === 'Booking %' && (
                <Center>
                  <Text>{rowItem.bookingPercent ? rowItem.bookingPercent + ' %' : '0 %'}</Text>
                </Center>
              )}
              {item?.label === 'Total Minimum' && (
                <Center>
                  <Text>{rowItem.sales ? '$' + rowItem?.sales?.toLocaleString("en-US", { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) : 0.00}</Text>
                </Center>
              )}
            </Box>
          );
        })}
      </HStack>
    );
  };

  const redirectURL = (redirectBaseURL, isTargetBlank) => {
    if (isTargetBlank) {
      Linking.openURL(redirectBaseURL, '_blank');
    } else {
      Linking.openURL(redirectBaseURL);
    }
  };

  const { Pagination } = DataTable;

  return (
    <>
      <ScrollView
        contentContainerStyle={{ flexGrow: 1, backgroundColor: 'white' }}
      >
        <Fade in={isReady} pb={15}>
          {venuesListWithPagingResponse &&
            venuesListWithPagingResponse.length > 0 ? (
            <>
              <Flex
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
                my="3"
                px="8"
                w={'100%'}
                mt={6}
              >
                <Flex flexDirection="row" alignItems="center">
                  <Text bold w="100%" pr="2" pl="1" fontSize="md">
                    Start Date
                  </Text>
                  <Popover
                    isOpen={openStartDate}
                    onOpen={() => {
                      setOpenStartDate(true)
                    }}
                    onClose={() => setOpenStartDate(false)}
                    placement="bottom left"
                    trigger={(triggerProps) => {
                      return (
                        <Button
                          w="130"
                          px="1"
                          py="1"
                          borderRadius="md"
                          variant="outline"
                          colorScheme="dark.50"
                          leftIcon={
                            <Icon
                              as={MaterialIcons}
                              name="calendar-today"
                              size="4"
                            />
                          }
                          onPress={() => setOpenStartDate(!openStartDate)}
                          {...triggerProps}
                        >
                          {startdate}
                        </Button>
                      );
                    }}
                  >
                    <Popover.Content
                      accessibilityLabel="Filter By Date"
                      w="full"
                      bg="muted.300"
                    >
                      <Popover.Arrow />
                      <Popover.Body>
                        <Calendar
                          className="webCalendarPicker"
                          startdate={Moment(startdate, 'MM/DD/YYYY').toDate()}
                          maxDate={Moment(enddate, 'MM/DD/YYYY').toDate()}
                          direction="vertical"
                          fixedHeight
                          color={primaryColors.primaryRed}
                          onChange={(startdate) => {
                            setStartDate(Moment(startdate).format('MM/DD/YYYY'));
                            setOpenStartDate(false);
                          }}
                        />
                      </Popover.Body>
                    </Popover.Content>
                  </Popover>
                </Flex>
                <Flex flexDirection="row" alignItems="center">
                  <Text bold w="100%" pr="2" pl="1" fontSize="md">
                    End Date
                  </Text>
                  <Popover
                    isOpen={openEndDate}
                    onOpen={() => {
                      setOpenEndDate(true)
                    }}
                    onClose={() => setOpenEndDate(false)}
                    placement="bottom left"
                    trigger={(triggerProps) => {
                      return (
                        <Button
                          w="130"
                          px="1"
                          py="1"
                          borderRadius="md"
                          variant="outline"
                          colorScheme="dark.50"
                          leftIcon={
                            <Icon
                              as={MaterialIcons}
                              name="calendar-today"
                              size="4"
                            />
                          }
                          onPress={() => setOpenStartDate(!openEndDate)}
                          {...triggerProps}
                        >
                          {enddate}
                        </Button>
                      );
                    }}
                  >
                    <Popover.Content
                      accessibilityLabel="Filter By Date"
                      w="full"
                      bg="muted.300"
                    >
                      <Popover.Arrow />
                      <Popover.Body>
                        <Calendar
                          className="webCalendarPicker"
                          enddate={Moment(enddate, 'MM/DD/YYYY').toDate()}
                          direction="vertical"
                          fixedHeight
                          color={primaryColors.primaryRed}
                          onChange={(enddate) => {
                            console.log('%%%%', enddate);
                            setEndDate(Moment(enddate).format('MM/DD/YYYY'));
                            setOpenEndDate(false);
                          }}
                        />
                      </Popover.Body>
                    </Popover.Content>
                  </Popover>
                </Flex>

                <Spacer />
                <Box>
                  <Pressable>
                    <Button
                      background={primaryColors.primaryRed}
                      w="125"
                      variant="solid"
                      leftIcon={
                        <Icon
                          as={FontAwesome}
                          name="plus-circle"
                          size="4"
                          color="white"
                        />
                      }
                      onPress={() => redirectURL(`/AddOrEditVenue/0`, false)}
                    >
                      Add Venue
                    </Button>
                  </Pressable>
                </Box>
              </Flex>
              <Box
                mt="1"
                px="8"
                py="2"
                bg="#000"
                _text={{
                  fontSize: 'lg',
                  fontWeight: 'medium',
                  color: primaryColors.primaryRed,
                  letterSpacing: 'lg',
                }}
              >
                Venues
              </Box>
              <SlideFade in={isReady}>
                <Box px="8" py="2" bg="#a6a6a6">
                  {renderHeader(headerTitleOptions || [])}
                </Box>
                {venuesListWithPagingResponse &&
                  venuesListWithPagingResponse.length > 0 ? (
                  <>
                    {venuesListWithPagingResponse.map((item, iteration) => (
                      <Box
                        key={`venueList_${item.id}_${iteration}`}
                        px="8"
                        py="2"
                      >
                        {renderRowItem(item, iteration)}
                      </Box>
                    ))}
                  </>
                ) : null}
                <Pagination
                  page={pageNumber}
                  numberOfPages={totalNumberOfPages + 1}
                  onPageChange={(page) => setPageNumber(page)}
                  label={`${pageNumber + 1}-${totalNumberOfPages + 1} of ${totalNumberOfPages + 1
                    }`}
                />
              </SlideFade>
              <ReportModal ref={reportModalRef} title="Cache Report" />
            </>
          ) : (
            <Box
              w={Dimensions.get('window').width}
              h={Dimensions.get('window').height - 84}
            >
              <SpinnerLoading />
            </Box>
          )}
        </Fade>
      </ScrollView>

    </>
  );
};

const mapStateToProps = (state) => {
  const { loginResponse } = state.auth;
  const {
    venuesListWithPagingResponse,
    VenuesDetailResponse,
    venuesReservationListWithPagingLoading,
    venuesReservationListWithPagingResponse,
  } = state.venue;

  return {
    loginResponse,
    venuesListWithPagingResponse,
    VenuesDetailResponse,
    venuesReservationListWithPagingLoading,
    venuesReservationListWithPagingResponse,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    GetAllVenueDetailsWithPaging: (data, all) =>
      dispatch(VenueActions.GetAllVenueDetailsWithPaging(data, all)).then(
        (response) => response
      ),
    GetVenueCacheReportByVenueId: (venueID) =>
      dispatch(VenueActions.GetVenueCacheReportByVenueId(venueID)).then(
        (response) => response
      ),
    ResetVenueDetailForm: () =>
      dispatch(VenueActions.ResetVenueDetailForm()).then(
        (response) => response
      ),
    GetVenueDetailsById: (venueId) =>
      dispatch(VenueActions.GetVenueDetailsById(venueId)).then(
        (response) => response
      ),
    FetchCity: () =>
      dispatch(CommonActions.FetchCity()).then((response) => response),
    FetchState: () =>
      dispatch(CommonActions.FetchState()).then((response) => response),
    GetReservationDetailsWithPaging: (data, all) =>
      dispatch(VenueActions.GetReservationDetailsWithPaging(data, all)).then(
        (response) => response
      ),
    ToggleVenueState: (data) =>
      dispatch(VenueActions.ToggleVenueState(data)).then(
        (response) => response
      ),
  };
};

VenueList.defaultProps = {
  headerTitleOptions: [
    { key: '0_Venue', apiKey: 'venueName', label: 'Venue', width: '15%' },
    { key: '1_ActiveStatus', apiKey: 'isactive', label: 'Active', width: '10%' },
    { key: '2_City', apiKey: 'cityName', label: 'City', width: '10%' },
    {
      key: '3_Searches',
      apiKey: 'searches',
      label: 'Searches',
      width: '8%'
    },
    {
      Key: '4_TableClicks',
      apiKey: 'tableClicks',
      label: 'Table Clicks',
      width: '8%'
    },
    {
      Key: '5_ProductClicks',
      apiKey: 'productClicks',
      label: 'Product Clicks',
      width: '8%'
    },
    {
      Key: '6_Bookings',
      apiKey: 'bookings',
      label: 'Bookings',
      width: '8%'
    },
    {
      Key: '7_Booking%',
      apiKey: 'bookingPercent',
      label: 'Booking %',
      width: '8%'
    },
    {
      Key: '8_Sales',
      apiKey: 'sales',
      label: 'Total Minimum',
      width: '8%'
    }
  ],
  reservationHeaderTitleOptions: [
    { key: '0_Date', label: 'Date', width: '15%' },
    { key: '1_Reservations_Set', label: 'Reservations Set', width: '15%' },
    {
      key: '2_Reservations_Confirmed',
      label: 'Reservations Confirmed',
      width: '15%',
    },
    { key: '3_Reservations_Queued', label: 'Items Queued', width: '15%' },
    { key: '4_Reservations_Completed', label: 'Items Completed', width: '15%' },
    { key: '5_Reservations_Cleared', label: 'Items Cleared', width: '20%' },
  ],
};

VenueList.propTypes = {
  GetAllVenueDetailsWithPaging: PropTypes.func,
  GetVenueCacheReportByVenueId: PropTypes.func,
  venuesListWithPagingResponse: PropTypes.any,
  headerTitleOptions: PropTypes.any,
  navigation: PropTypes.any,
  ResetVenueDetailForm: PropTypes.func,
  GetVenueDetailsById: PropTypes.func,
  FetchCity: PropTypes.func,
  FetchState: PropTypes.func,
  venuesReservationListWithPagingLoading: PropTypes.any,
  GetReservationDetailsWithPaging: PropTypes.func,
  ToggleVenueState: PropTypes.func,
  reservationHeaderTitleOptions: PropTypes.any,
  loginResponse: PropTypes.any,
};

export default connect(mapStateToProps, mapDispatchToProps)(VenueList);
