/* eslint-disable react-native/no-raw-text */
/* eslint-disable react-native/no-color-literals */
/* eslint-disable react-native/no-inline-styles */
import React, { useState, useEffect } from 'react';
import {
  Dimensions,
  Pressable,
  ScrollView,
} from 'react-native';
import {
  Box,
  Flex,
  Text,
  SlideFade,
  Icon,
  Input,
  HStack,
  Center,
  useToast,
  Button,
  Select,
  Switch,
  Badge,
} from 'native-base';
import { DataTable } from 'react-native-paper';
import { connect } from 'react-redux';
import { FontAwesome } from '@expo/vector-icons';
import Moment from 'moment';
import { MaterialIcons } from '@expo/vector-icons';
import PropTypes from 'prop-types';

/* ----- COMPONENTS ----- */
import ModalWrapper from '../../Modal';
import { SpinnerLoading } from '../../../shared/templates/SpinnerLoading';
import AlertDialog from '../../AlertDialog';

/* ----- REDUX :: ACTIONS ----- */
import * as UserActions from '../../../shared/redux/actions/user';

/* ----- UTILS ----- */
import { primaryColors } from '../../../shared/utils/colors';

const UsersScreen = (props) => {
  const {
    type,
    GetAllUserDetailsWithPaging,
    usersListWithPagingLoading,
    usersListWithPagingResponse,
    headerTitleOptions,
    DeleteUser,
  } = props;
  const [isReady] = useState(true);
  const [User, setUser] = useState('');
  const [searchText, setSearchText] = useState('');
  const [sortField] = useState('UserName');
  const [sortDir, setSortDir] = useState('Ascending');
  const [pageNumber, setPageNumber] = useState(0);
  const [totalNumberOfPages, setTotalNumberOfPages] = useState(5);
  const [pageSize] = useState(25);
  const [id] = useState(0);
  const toast = useToast();
  const [showModal, setShowModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [activeItem, setActiveItem] = useState(null);
  const [modalType, setModalType] = useState('VIEW');
  const [UserId, setUserId] = useState('');
  const [showAdminUsersOnly, setShowAdminUsersOnly] = useState(false);

  const [defaultSorting, setSorting] = useState(null);
  const [initLoading, setInitialLoading] = useState(true);

  useEffect(() => {
    loadInitData(initLoading, showAdminUsersOnly);
  }, []);

  useEffect(() => {
    !initLoading && loadUsersData(!initLoading, showAdminUsersOnly);
  }, [pageNumber]);

  useEffect(() => {
    !initLoading && loadUsersData(!initLoading, showAdminUsersOnly);
  }, [sortDir]);

  const loadInitData = () => {
    loadUsersData(false, showAdminUsersOnly);
    setInitialLoading(false);
  };


  const loadUsersData = async (hideLoader, showAdminOnly) => {
    await setShowAdminUsersOnly(showAdminOnly)
    let loadParams = {
      searchText: searchText || '',
      sortField: sortField || '',
      sortDir: sortDir || '',
      pageNumber: pageNumber + 1 || 1,
      pageSize: pageSize || 5,
      userRole: showAdminOnly === true ? 2 : 0,
      id: id || 0,
    };

    const response = await GetAllUserDetailsWithPaging(
      loadParams,
      hideLoader
    ).then((result) => {
      return result;
    });

    if (response && response?.status == 200) {
      setTotalNumberOfPages(Math.ceil(response?.data?.totalCount / 25) - 1);
    }

    return response;
  };
  const HandleToast = (msg, bg) => {
    toast.show({
      render: () => (
        <Box bg={bg} px="2" py="1" rounded="sm" mb={5}>
          {msg}
        </Box>
      ),
    });
  };

  const HandleDelete = async (id) => {
    toast.closeAll();

    if (id !== '') {
      const PostVenue_Response = await DeleteUser(id).then(
        (response) => response
      );
      if (PostVenue_Response?.data?.status === 'Success') {
        toast.show({
          render: () => (
            <Box bg="emerald.500" px="2" py="1" rounded="sm" mb={5}>
              User Deleted Successfully!
            </Box>
          ),
        });
        await loadUsersData(true, showAdminUsersOnly);
      } else {
        toast.show({
          render: () => (
            <Box bg="error.600" px="2" py="1" rounded="sm" mb={5}>
              Failed! Please try again later
            </Box>
          ),
        });
      }
    } else {
      toast.show({
        render: () => (
          <Box bg="error.600" px="2" py="1" rounded="sm" mb={5}>
            Something went wrong!
          </Box>
        ),
      });
    }
  };
  const renderHeader = (headers) => {
    return (
      <HStack space={3}>
        {headers.map((item, iteration) => {
          return (
            <Box w={item?.width} key={`${item?.key}_${iteration}`}>
              {item?.label === 'Profile Actions' ? (
                <Center>
                  <Text fontWeight="semibold" italic>
                    {item?.label}
                  </Text>
                </Center>
              ) : (
                <Pressable
                  onPress={() => {
                    if (item?.label == 'Name') {
                      sortDir == 'Descending'
                        ? setSortDir('Ascending')
                        : setSortDir('Descending');
                      setSorting(item?.label);
                    }
                  }}
                >
                  <Flex flexDirection="row" alignItems="flex-start">
                    {(showAdminUsersOnly === true && item?.label === "Phone Number") ? null : <Text fontWeight="semibold" italic>
                      {item?.label}
                    </Text>}
                    {item?.label == 'Name' && (
                      <Box pt="1" pl="2">
                        <Icon
                          as={FontAwesome}
                          name={
                            defaultSorting === item?.label
                              ? sortDir === 'Descending'
                                ? 'chevron-down'
                                : 'chevron-up'
                              : 'chevron-down'
                          }
                          size="3"
                          color="#000"
                        />
                      </Box>
                    )}
                  </Flex>
                </Pressable>
              )}
            </Box>
          );
        })}
      </HStack>
    );
  };

  const renderRowItem = (rowItem, rowIteration) => {
    return (
      <HStack justifyContent="flex-start" space={2}>
        {headerTitleOptions.map((item, iteration) => {
          return (
            <Box
              key={`${item?.width}_${rowIteration}_v1${iteration}`}
              w={`${item?.width}`}
            >
              {item?.label === 'Name' && (
                <Flex flexDirection="row" alignItems="center">
                  <Icon
                    as={FontAwesome}
                    name="user"
                    size="3"
                    pr={4}
                    color="primary.600"
                  />
                  <Text
                    color="primary.600"
                    numberOfLines={1}
                    bold
                    underline
                    style={{ textTransform: 'capitalize' }}
                  >
                    {rowItem?.name}
                  </Text>
                </Flex>
              )}
              {item?.label === 'User Type' && (
                <Box pl={1}>
                  <Text
                    numberOfLines={1}
                    style={{ textTransform: 'capitalize' }}
                  >
                    {rowItem?.userRole || ''}
                  </Text>
                </Box>
              )}
              {(item?.label === 'Phone Number' && showAdminUsersOnly === false) && (
                <Flex flexDirection="row" alignItems="center">
                  {rowItem.phoneNumber ? <Icon as={FontAwesome} name="phone" size="3" pr={4} /> : null}
                  <Text
                    numberOfLines={1}
                    underline
                    style={{ textTransform: 'lowercase' }}
                  >
                    {rowItem.phoneNumber ? rowItem.phoneNumber : ''}
                  </Text>
                </Flex>
              )}
              {item?.label === 'Email Address' && (
                <Flex flexDirection="row" alignItems="center">
                  {rowItem.email ? <Icon as={FontAwesome} name="envelope" size="3" pr={4} /> : null}
                  <Text
                    numberOfLines={1}
                    underline
                    style={{ textTransform: 'lowercase' }}
                  >
                    {rowItem.email ? rowItem.email : ''}
                  </Text>
                </Flex>
              )}
              {item?.label === 'Profile Actions' && (
                <Flex
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Box
                    mr={3}
                    borderRadius={10}
                    w={'80px'}
                    bgColor={primaryColors.PictonBlue}
                  >
                    <Pressable
                      onPress={() => {
                        setModalType('EDIT');
                        setUserId(rowItem?.id);
                        setShowModal(true);
                      }}
                    >
                      <Text
                        fontWeight="normal"
                        textAlign="center"
                        color="white"
                      >
                        <Icon
                          as={FontAwesome}
                          name="edit"
                          size="3"
                          color="#FFFFFF"
                          pr={2}
                        />
                        Edit
                      </Text>
                    </Pressable>
                  </Box>
                  <Box
                    mr={3}
                    borderRadius={10}
                    w={'80px'}
                    bgColor={primaryColors.primaryRed}
                  >
                    <Pressable
                      onPress={() => {
                        setActiveItem(rowItem);
                        setShowConfirmationModal(!showConfirmationModal);
                      }}
                    >
                      <Text
                        fontWeight="normal"
                        textAlign="center"
                        color="white"
                      >
                        <Icon
                          as={FontAwesome}
                          name="trash"
                          size="3"
                          color="#FFFFFF"
                          pr={2}
                        />
                        Delete
                      </Text>
                    </Pressable>
                  </Box>
                </Flex>
              )}
            </Box>
          );
        })}
      </HStack>
    );
  };

  const { Pagination } = DataTable;

  return (
    <>
      {isReady ? (
        <>
          <ScrollView
            contentContainerStyle={{ flexGrow: 1, backgroundColor: 'white' }}
          >
            {!usersListWithPagingLoading ? (
              <>
                {!type ? (
                  <>
                    <Box
                      my={4}
                      px="8"
                      display="flex"
                      flexDirection="row"
                      justifyContent="space-between"
                    >
                      <Input
                        placeholder="Search"
                        value={User || ''}
                        onChange={(event) => {
                          setUser(event.target.value);
                          setSearchText(event.target.value);
                        }}
                        onSubmitEditing={() => {
                          loadUsersData(false, showAdminUsersOnly);
                          setPageNumber(0)
                          setInitialLoading(false);
                        }}
                        width="350px"
                        borderRadius="5"
                        py="2"
                        px="3"
                        fontSize="14"
                        InputRightElement={
                          <Icon
                            m="2"
                            mr="3"
                            size="6"
                            color="gray.400"
                            as={<MaterialIcons name="search" />}
                            onPress={() => {
                              loadUsersData(false, showAdminUsersOnly);
                              setPageNumber(0)
                              setInitialLoading(false);
                            }}
                          />
                        }
                      />

                      <Button
                        onPress={() => {
                          setUserId('')
                          setModalType('ADD');
                          setShowModal(true);
                        }}
                        background={primaryColors.primaryRed}
                        w="125"
                        variant="solid"
                        leftIcon={
                          <Icon
                            as={FontAwesome}
                            name="plus-circle"
                            size="4"
                            color="white"
                          />
                        }
                      >
                        Add User
                      </Button>
                    </Box>

                    <Box
                      mt="1"
                      px="8"
                      py="2"
                      bg="#000"
                      _text={{
                        fontSize: 'xl',
                        fontWeight: 'medium',
                        color: primaryColors.primaryRed,
                        letterSpacing: 'lg',
                      }}
                      display="flex"
                      flexDirection="row"
                      justifyContent="space-between"
                    >
                      Users
                      <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                      >
                        <Text color={primaryColors.primaryRed}>
                          Show Admin Users Only &nbsp;
                        </Text>
                        <Switch
                          size="md"
                          isChecked={showAdminUsersOnly}
                          onToggle={(value) => {
                            setShowAdminUsersOnly(value);
                            loadUsersData(false, value);
                          }}
                        />
                      </Box>
                    </Box>
                  </>
                ) : null}
                <SlideFade in={isReady}>
                  <Box px="8" py="2" bg="#a6a6a6">
                    {renderHeader(headerTitleOptions || [])}
                  </Box>
                  {usersListWithPagingResponse &&
                    usersListWithPagingResponse.length > 0 ? (
                    <>
                      {usersListWithPagingResponse.map((item, iteration) => (
                        <Box
                          key={`usersListList_${item.id}_${iteration}`}
                          px="8"
                          py="2"
                        >
                          {renderRowItem(item, iteration)}
                        </Box>
                      ))}
                      <Pagination
                        page={pageNumber}
                        numberOfPages={totalNumberOfPages + 1}
                        onPageChange={(page) => setPageNumber(page)}
                        label={`${pageNumber + 1}-${totalNumberOfPages + 1
                          } of ${totalNumberOfPages + 1}`}
                      />
                    </>
                  ) : (
                    <Box
                      w={Dimensions.get('window').width}
                      h={Dimensions.get('window').height - 84}
                      py="5"
                    >
                      <Text
                        color={primaryColors.primaryRed}
                        fontSize="2xl"
                        textAlign="center"
                      >
                        No User Found
                      </Text>
                    </Box>
                  )}
                </SlideFade>
              </>
            ) : (
              <Box
                w={Dimensions.get('window').width}
                h={Dimensions.get('window').height - 84}
              >
                <SpinnerLoading />
              </Box>
            )}
          </ScrollView>
          {showModal == true ? (
            <ModalWrapper
              Id={UserId}
              modalType={modalType || 'VIEW'}
              IsOpen={showModal}
              createForUserType={3}
              page="user"
              HandleToast={(msg, bg) => HandleToast(msg, bg)}
              handleClose={(status, type) => {
                setModalType("VIEW")
                setShowModal(status)
                setTimeout(() => {
                  type == 'save' && loadUsersData(true, showAdminUsersOnly);
                }, 100);
              }}
            />
          ) : null}
          <AlertDialog
            showAlert={showConfirmationModal || false}
            Header="Delete User"
            Body={
              <Center>
                <Text fontSize="lg">Are you sure want to delete user?</Text>
                <Text bold fontSize="md">
                  {activeItem?.name} ({activeItem?.email})
                </Text>
              </Center>
            }
            Name={''}
            handleClose={async (status, deleteType) => {
              if (deleteType == 'DELETE') {
                await HandleDelete(activeItem?.id);
              }
              await setShowConfirmationModal(false);
              await setActiveItem(null);
            }}
          />
        </>
      ) : (
        <SpinnerLoading />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  const { usersListWithPagingResponse, usersListWithPagingLoading } =
    state.user;

  return {
    usersListWithPagingResponse,
    usersListWithPagingLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    GetAllUserDetailsWithPaging: (data, hideLoader) =>
      dispatch(UserActions.GetAllUserDetailsWithPaging(data, hideLoader)).then(
        (response) => response
      ),
    DeleteUser: (Id) =>
      dispatch(UserActions.DeleteUserById(Id)).then((response) => response),
  };
};

UsersScreen.defaultProps = {
  headerTitleOptions: [
    { key: '0_Name', label: 'Name', width: '25%' },
    { key: '1_UserType', label: 'User Type', width: '10%' },
    { key: '2_PhoneNumber', label: 'Phone Number', width: '15%' },
    { key: '3_EmailAddress', label: 'Email Address', width: '15%' },
    { key: '4_ProfileActions', label: 'Profile Actions', width: '35%' },
  ],
};

UsersScreen.propTypes = {
  GetAllUserDetailsWithPaging: PropTypes.func,
  usersListWithPagingResponse: PropTypes.any,
  headerTitleOptions: PropTypes.any,
  DeleteUser: PropTypes.func,
  type: PropTypes.any,
  usersListWithPagingLoading: PropTypes.any,
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersScreen);
