/* eslint-disable react-native/no-raw-text */
import React, { useState } from "react";
import { ImageBackground } from 'react-native';
import { Box, Heading, VStack, Link, FormControl, Input, Spinner, Button, Center, Text, Icon, Flex, WarningOutlineIcon, useToast } from "native-base";
import { Ionicons } from "@expo/vector-icons";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Redux :: Actions
import * as ForgotPasswordActions from '../../../shared/redux/actions/forgotPassword';

// Utils
import * as IMAGES from '../../../shared/utils/images';

// Stylesheet
import { webStyles } from '../../../shared/styles';
import { primaryColors } from '../../../shared/utils/colors'

const ForgotPasswordScreen = (props) => {
  const { navigation, ForgotPassword, forgotPasswordResponse } = props;
  const toast = useToast();

  const [isLoading, setIsLoading] = useState(false);
  const [formSubmitted, setFormSubmit] = useState(false);
  const [username, setUsername] = useState("");

  const renderValidationMessage = (msg, fieldName) => {
    let isValidFields = false;
    if ((fieldName && fieldName !== "")) {
      isValidFields = true;
    }

    if (formSubmitted && !isValidFields) {
      return <Flex mt="1.5" direction="row">
        <WarningOutlineIcon size="xs" color="warning.500" pr="2" />
        <Text color="warning.500" italic>{msg || ""}</Text>
      </Flex>;
    }

    return null;
  }

  const validateFields = () => {
    if (username && username !== "") {
      return true;
    }

    return false;
  }

  const handleSubmit = async () => {
    toast.closeAll();
    setIsLoading(true);
    setFormSubmit(true);

    if (validateFields()) {
      const ForgotPasswordResponse = await ForgotPassword({ email: username }).then((response) => response);

      setIsLoading(false);
      if (ForgotPasswordResponse?.status === 200) {
        toast.show({
          render: () => <Box bg="emerald.500" px="2" py="1" rounded="sm" mb={5}>
            Please check your email and click on the provided link to reset your password
          </Box>
        });
        RedirectLink("Login")
      } else {
        toast.show({
          render: () => <Box bg="error.900" px="2" py="1" rounded="sm" mb={5}>Please fill all required fields</Box>
        });
      }

    } else {
      toast.show({
        render: () => <Box bg="error.900" px="2" py="1" rounded="sm" mb={5}>Please fill all required fields</Box>
      });
      setIsLoading(false);
    }
  }

  const RedirectLink = (link) => navigation.navigate(link);

  return <ImageBackground source={IMAGES.BG_WEB_IMG} resizeMode="cover" style={webStyles.appBG}>
    <Center flex={1} px="3">
      <Box width="400" bg="rgba(255,255,255,0.1)" rounded="lg" px="10" py="10">
        <Center flex={1} px="3">
          <Heading size="lg" fontWeight="600" color={primaryColors.lightOrange}>Forgot Your Password?</Heading>
          <Center>
            <Heading mt="3" color="#FFF" fontWeight="bold" size="xs" textAlign="center">Enter your email address to retreive your password</Heading>
          </Center>
          <VStack space={3} mt="10" width="300">
            <FormControl isRequired>
              <FormControl.Label>
                <Text color="#ffedd5">Email Address</Text>
              </FormControl.Label>
              <Input
                type="email-address"
                bg="#FFFFFF"
                selectionColor="white"
                placeholder="Please enter your email address here"
                defaultValue={username || ""}
                onChange={(event) => setUsername(event.target.value)}
              />
              {renderValidationMessage("Field is required", username)}
            </FormControl>
            <Button
              mt="2"
              bgColor="#fc1a17"
              color="primary.50"
              leftIcon={
                isLoading ? <Spinner color="primary.50" />
                  : <Icon as={Ionicons} name="refresh" size="sm" />
              }
              onPress={handleSubmit}
            >Reset Password?</Button>
            <Flex direction="row" justifyContent="center" mt="2">
              <Link
                _text={{ fontSize: "xs", color: primaryColors.lightOrange }}
                isUnderlined
                _hover={{
                  _text: {
                    color: "#ffffff"
                  }
                }}
                onPress={() => RedirectLink("Login")}
              >
                Back to login?
              </Link>
            </Flex>
          </VStack>
        </Center>
      </Box>
    </Center>
  </ImageBackground>;
}

ForgotPasswordScreen.propTypes = {
  navigation: PropTypes.any,
  loading: PropTypes.any,
  forgotPasswordResponse: PropTypes.any,
}

const mapStateToProps = (state) => {
  const { forgotPassword } = state;
  return {
    loading: forgotPassword.loading,
    forgotPasswordResponse: forgotPassword.forgotPasswordResponse,
  };
}

const mapDispatchToProps = (dispatch) => ({
  ForgotPassword: (data) => dispatch(ForgotPasswordActions.forgotPassword(data)).then((response) => response)
});


export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordScreen);

