/* eslint-disable react-native?no-raw-text */
import React, { useState, useEffect } from "react";
import { Box, HStack, Text, Center, Flex, Heading, Divider, Popover, Button, Icon } from "native-base";
import { connect } from "react-redux";
import { DataTable } from "react-native-paper";
import Moment from "moment";
import { Calendar } from 'react-date-range';
import { MaterialIcons } from "@expo/vector-icons";
import PropTypes from 'prop-types';

import VenueSummary from './ReservationReport';


/*Components */
import { SpinnerLoading } from '../../../shared/templates/SpinnerLoading';

/*---UTILS---*/
import { primaryColors } from '../../../shared/utils/colors';

/* ----- REDUX :: ACTIONS ----- */
import * as VenueActions from '../../../shared/redux/actions/venue';
import { id } from "date-fns/locale";






const venueGuestSummarys = (props) => {
	const { venueReservationSummary, venuesGuestSummaryWithPagingResponse, venuesGuestSummaryWithPagingLoading, headerTitleOptions,
		GetGuestSummarybyusers, venueId, DownloadReservationSummaryReport } = props;
	const [startDate, setStartDate] = useState(Moment().subtract(7, 'days').format('MM/DD/YYYY'));
	const [endDate, setEndDate] = useState(Moment().format('MM/DD/YYYY'));
	const [openStartDate, setOpenStartDate] = useState(false);
	const [openEndDate, setOpenEndDate] = useState(false);


	const data = { 'VenueId': venueId, 'StartDate': startDate, 'EndDate': endDate };

	useEffect(() => {
		if (venueId > 0) {
			loadReservationSummary(venueId);
		}
	}, [venueId]);

	useEffect(() => {
		if (startDate) {
			setTimeout(() => loadReservationSummary(venueId), 50);
		}
	}, [startDate]);

	useEffect(() => {
		if (endDate) {
			setTimeout(() => loadReservationSummary(venueId), 50);
		}
	}, [endDate]);

	const loadReservationSummary = async (venueId) => {
		await GetGuestSummarybyusers(
			{
				venueId: venueId,
				startDate: Moment(startDate, "MM/DD/YYYY").format("YYYY-MM-DD[T]HH:mm:ss") || '',
				endDate: Moment(endDate, "MM/DD/YYYY").format("YYYY-MM-DD[T]HH:mm:ss") || '',
			},
			false
		);
	}

	const renderHeader = (headers) => {
		return <HStack space={3}>
			{
				headers.map((item, iteration) => {
					return <Box w={item?.width} key={`${item?.key}_${iteration}`} h="10px" display="flex" flexDirection="row"  alignItems="center"
						justifyContent={(item?.label === "Email" || item?.label === "Name" || item?.label === "Phone Number") ? "center"
							: (item?.label === "Total Minimum" || item?.label === "Total Order" || item?.label === "Reservations Made") ? "center"
								: "flex-end"} >
						{(item?.label === "Total Order" || item?.label === "Total Minimum") ? <Text fontWeight="bold" italic>{item?.label}</Text>
							: <Center><Text fontWeight="bold" italic >{item?.label}</Text></Center>
						}
					</Box>;
				})
			}
		</HStack>
	}

	const renderRowItem = (rowItem, rowIteration) => {
		return <HStack justifyContent="flex-start" space={2}>
			{
				headerTitleOptions.map((item, iteration) => {
					return <Box key={`${item?.width}_${rowIteration}_v1${iteration}`} w={`${item?.width}`} >
						{item?.label === "Name" && <Text textAlign="center"  fontSize="md">{rowItem.name}</Text>}
						{item?.label === "Phone Number" && <Text fontSize="md"  textAlign="center">{rowItem.phoneNumber ? rowItem.phoneNumber : 0}</Text>}
						{item?.label === "Email" && <Text fontSize="md" textAlign="center" >{rowItem.email}</Text>}
						{item?.label === "Reservations Made" && <Text fontSize="md" textAlign="center" >{rowItem.reservationMade ? rowItem.reservationMade : 0}</Text>}
						{item?.label === "Total Minimum" && <><Text textAlign="center" fontSize="md" marginLeft="35px">{rowItem.totalMinimum ? rowItem.totalMinimum.toLocaleString("en-US", { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) : 0.00}</Text></>}
						{item?.label === "Total Order" && <Text textAlign="center" fontSize="md" marginLeft="35px">{rowItem.totalOrder ? rowItem.totalOrder.toLocaleString("en-US", { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) : 0.00}</Text>}
					</Box>;
				})
			}

		</HStack>;

	}

	return <Box>
		<Flex flexDirection="row" alignItems="center" w={"100%"}>
			<Heading size='lg'>Guest Summary</Heading>
		</Flex>
		<Divider my="3" bg="muted.400" />
		<Flex
			flexDirection="row"
			alignItems="center"
			justifyContent="space-between"
			my="3"
			w={'100%'}
			mt={6}
		>

			<Flex
				flexDirection="row"
				alignItems="center"
				justifyContent="space-around"
			>
				<Text whiteSpace="nowrap" bold w="100%" pr="2" pl="1" fontSize="md">
					Start Date
				</Text>
				<Box pr={1} />
				<Popover
					isOpen={openStartDate}
					onOpen={() => {
						setOpenStartDate(true)
					}}
					onClose={() => setOpenEndDate(false)}
					placement="bottom left"
					trigger={(triggerProps) => {
						return (
							<Button
								w="130"
								px="1"
								py="1"
								borderRadius="md"
								variant="outline"
								colorScheme="dark.50"
								leftIcon={
									<Icon
										as={MaterialIcons}
										name="calendar-today"
										size="4"
									/>
								}
								onPress={() => setOpenStartDate(!openStartDate)}
								{...triggerProps}
							>
								{startDate}
							</Button>
						);
					}}
				>
					<Popover.Content
						accessibilityLabel="Filter By Date"
						w="full"
						bg="muted.300"
					>
						<Popover.Arrow />
						<Popover.Body>
							<Calendar
								className="webCalendarPicker"
								startDate={Moment(startDate, 'MM/DD/YYYY').toDate()}
								maxDate={Moment(endDate, 'MM/DD/YYYY').toDate()}
								direction="vertical"
								fixedHeight
								color={primaryColors.primaryRed}
								onChange={(startDate) => {
									console.log('SatrtDate%%', startDate);
									setStartDate(Moment(startDate).format('MM/DD/YYYY'));
									setOpenStartDate(false);
								}}
							/>
						</Popover.Body>
					</Popover.Content>
				</Popover>

				<Box pr={3} />

				<Text whiteSpace="nowrap" bold w="100%" pr="2" pl="1" fontSize="md">
					End Date
				</Text>
				<Box pr={1} />
				<Popover
					isOpen={openEndDate}
					onOpen={() => {
						setOpenEndDate(true)
					}}
					onClose={() => setOpenEndDate(false)}
					placement="bottom left"
					trigger={(triggerProps) => {
						return (
							<Button
								w="130"
								px="1"
								py="1"
								borderRadius="md"
								variant="outline"
								colorScheme="dark.50"
								leftIcon={
									<Icon
										as={MaterialIcons}
										name="calendar-today"
										size="4"
									/>
								}
								onPress={() => setOpenEndDate(!openEndDate)}
								{...triggerProps}
							>
								{endDate}
							</Button>
						);
					}}
				>
					<Popover.Content
						accessibilityLabel="Filter By Date"
						w="full"
						bg="muted.300"
					>
						<Popover.Arrow />
						<Popover.Body>
							<Calendar
								className="webCalendarPicker"
								endDate={Moment(endDate, 'MM/DD/YYYY').toDate()}
								direction="vertical"
								fixedHeight
								color={primaryColors.primaryRed}
								onChange={(enddate) => {
									console.log('endReserve %%%%', enddate);
									setEndDate(Moment(enddate).format('MM/DD/YYYY'));
									setOpenEndDate(false);
								}}
							/>
						</Popover.Body>
					</Popover.Content>
				</Popover>
			</Flex>

			<Button
				background={primaryColors.primaryRed}
				onPress={() => DownloadReservationSummaryReport(data)}
				leftIcon={<Icon as={MaterialIcons} name="file-download" size="5" />}
			>
				Download
			</Button>

		</Flex>

		<Box px="8" py="4" bg="rgb(208, 208, 208)">{renderHeader(headerTitleOptions || [])}</Box>
		<Box minHeight="50">
			{
				!venuesGuestSummaryWithPagingLoading ? <>
					{
						venuesGuestSummaryWithPagingResponse && venuesGuestSummaryWithPagingResponse.length > 0 ? <>
							{
								venuesGuestSummaryWithPagingResponse.map((item, iteration) => {

									return <Box
										key={`venueReservationSummary${item.venueId}_${iteration}`}
										px="8"
										py="2">
										{renderRowItem(item, iteration)}
									</Box>
								}
								)
							}
						</> : <Box px="8" py="2" fontSize={15} display='flex' justifyContent='center' alignSelf='center'>NO DATA FOUND</Box>
					}
				</> : <SpinnerLoading />
			}

		</Box>

	</Box>
}

const mapStateToProps = (state) => {
	const { VenuesReservationSummaryResponse, venuesGuestSummaryWithPagingResponse, venuesGuestSummaryWithPagingLoading } = state.venue;
	return {
		venuesGuestSummaryWithPagingResponse,
		venuesGuestSummaryWithPagingLoading,
		venueReservationSummary: VenuesReservationSummaryResponse || []
	};
}

const mapDispatchToProps = (dispatch) => {
	return {
		GetGuestSummarybyusers: (data) => dispatch(VenueActions.GetGuestSummarybyusers(data)).then((response) => response),
		DownloadReservationSummaryReport: (data) => dispatch(VenueActions.DownloadReservationSummaryReportForVenue(data)).then((response) => response),
	};
}

venueGuestSummarys.defaultProps = {
	headerTitleOptions: [
		{ key: '0_Username', label: "Name", width: "13%" },
		{ key: '1_PhoneNumber', label: "Phone Number", width: "16%" },
		{ key: '2_Email', label: "Email", width: "22%" },
		{ key: '3_ReservationMade', label: "Reservations Made", width: "15%" },
		{ key: '4_TotalMinimum', label: "Total Minimum", width: "13%" },
		{ key: '5_TotalOrder', label: "Total Order", width: "10%" }
	]
}


venueGuestSummarys.propTypes = {
	headerTitleOptions: PropTypes.any,
	venuesGuestSummaryWithPagingResponse: PropTypes.any,
	venuesGuestSummaryWithPagingLoading: PropTypes.any,
	GetGuestSummarybyusers: PropTypes.func,
	//venueReservationSummary: PropTypes.any
};

export default connect(mapStateToProps, mapDispatchToProps)(venueGuestSummarys);