/* eslint-disable react-native/no-raw-text */
import React, { useEffect, useState } from "react";
import { StyleSheet } from 'react-native';
import { Flex, Input, Icon, FormControl, Box, Center, TextArea, Fade, Button, useToast, Heading, Divider } from "native-base";
import { Ionicons } from "@expo/vector-icons";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Redux :: Actions
import * as productActions from '../../../shared/redux/actions/product';
import * as VenueActions from '../../../shared/redux/actions/venue';
import * as ProductCategoryActions from '../../../shared/redux/actions/productCategory';

//Redux :: Components
import Linking from '../../../shared/templates/customLinking';
import { SpinnerLoading } from '../../../shared/templates/SpinnerLoading';
import { ImageUpload } from '../../../shared/templates';

const addOrEditProduct = (props) => {
  const toast = useToast();
  const { PostProduct, type, route, GetProductDetailsById, pProductId, GetProductCategoryByVenueId, productdetail } = props;

  const [isReady] = useState(true);
  const [ProductId, setProductId] = useState(pProductId);
  const [CategoryId, setCategoryId] = useState(0);
  const [VenueId, setVenueId] = useState(0);
  const [ProductName, setProductName] = useState("");
  const [ProductPrice, setProductPrice] = useState("");
  const [ProductDescription, setProductDescription] = useState("");
  const [ProductImagePath, setProductImagePath] = useState("");

  useEffect(() => {
    if (route?.params) {
      const { id, categoryid, productid } = route?.params;
      setVenueId(id || 0);
      setCategoryId(categoryid || 0);
      setProductId(productid || 0);
    }
  }, [route?.params]);

  useEffect(() => {
    loadProductDetail();
  }, [ProductId])

  useEffect(() => {
    if (productdetail.id) {
      setProductId(productdetail.id || 0);
      setVenueId(productdetail.venueid || 0);
      setCategoryId(productdetail.categoryId || 0);
      setProductName(productdetail.title || 0);
      setProductPrice(productdetail.price || 0);
      setProductDescription(productdetail.description || '');
      setProductImagePath(productdetail.imagePath || '');
    }
  }, [productdetail]);

  const ValidateFields = () => {
    if (
      (ProductName && ProductName !== "") &&
      (CategoryId && CategoryId !== "") &&
      (ProductPrice && ProductPrice !== "") &&
      (ProductDescription && ProductDescription !== "") &&
      (ProductImagePath && ProductImagePath !== "")
    ) {
      return true;
    }

    return false;
  }

  const loadProductDetail = async () => {
    if (ProductId && ProductId > 0) {
      await GetProductDetailsById(ProductId || 0).then((result) => { return result; });
    }
  }

  const redirectURL = (redirectBaseURL, isTargetBlank) => {
    if (isTargetBlank) {
      Linking.openURL(redirectBaseURL, '_blank')
    } else {
      Linking.openURL(redirectBaseURL)
    }
  }

  const HandleSubmit = async () => {
    const { loginResponse } = props;
    toast.closeAll();

    var validate = ValidateFields();
    if (loginResponse && loginResponse !== "") {
      let param = {
        Id: ProductId || 0,
        Venueid: VenueId || 0,
        CreatedBy: loginResponse?.user.id || "",
        CategoryId: CategoryId || "",
        Title: ProductName || "",
        Description: ProductDescription || "",
        Price: ProductPrice || "",
        ImagePath: ProductImagePath || "",
        Isactive: true || ""
      };

      const PostTable_Response = await PostProduct(param).then((response) => response);
      if (PostTable_Response?.data?.status === "Success") {
        toast.show({
          render: () => <Box bg="emerald.500" px="2" py="1" rounded="sm" mb={5}>
            {ProductId && ProductId > 0 ? "Product Added Successfully!" : "Product Updated Successfully"}
          </Box>
        });
        redirectURL(`/VenueWebsiteOperations/${VenueId}`, false)
      } else {
        toast.show({
          render: () => <Box bg="error.600" px="2" py="1" rounded="sm" mb={5}>Failed! Please try again later</Box>
        });
      }
    } else {
      toast.show({
        render: () => <Box bg="error.600" px="2" py="1" rounded="sm" mb={5}>Please fill all required fields</Box>
      });
    }
  }

  const CancelSubmit = () => redirectURL(`/VenueWebsiteOperations/${VenueId}`, false);

  return <>
    {
      isReady ? <Fade in={isReady}>

        <Flex flexDirection="row" alignItems="center" justifyContent="space-between" my="3" w={"100%"} mt={6}>
          {
            ProductId ?
              <Box px="8" pt="4">
                <Heading size='lg'>Product Information</Heading>
              </Box>
              :
              <Box px="8" pt="4">
                <Heading size='lg'>New Product</Heading>
              </Box>
          }
        </Flex>

        <Box px="8">
          <Divider my="3" bg="muted.400" />
        </Box>

        <Flex flexDirection="row" alignItems="flex-start">
          <Box w="70%">
            <Flex flexDirection="row" alignItems="center" px={!type ? "8" : "0"} w="100%" mt={6}>
              <Box style={styles.inputFieldWrapper}>
                <FormControl
                  isInvalid={false}
                  isRequired
                >
                  <FormControl.Label>Product Name</FormControl.Label>
                  <Input
                    variant="filled"
                    w="500"
                    selectionColor="white"
                    value={ProductName || ""}
                    onChange={(event) => setProductName(event.target.value)}
                  />
                </FormControl>
              </Box>
            </Flex>

            <Flex flexDirection="row" alignItems="center" px={!type ? "8" : "0"} w="100%" mt={6}>
              <Box style={styles.inputFieldWrapper}>
                <FormControl
                  isInvalid={false}
                  isRequired
                >
                  <FormControl.Label>Product Price</FormControl.Label>
                  <Input
                    variant="filled"
                    selectionColor="white"
                    w="500"
                    value={ProductPrice || ""}
                    onChange={(event) => setProductPrice(event.target.value)}
                  />
                </FormControl>
              </Box>
            </Flex>

            <Flex flexDirection="row" alignItems="center" px={!type ? "8" : "0"} w="100%" mt={6}>
              <Box style={styles.inputFieldWrapper}>
                <FormControl
                  isInvalid={false}
                  isRequired
                >
                  <FormControl.Label>Product Description</FormControl.Label>
                  <TextArea h={20} w="100%" maxW="500"
                    variant="filled"
                    w="500"
                    value={ProductDescription || ""}
                    onChange={(event) => setProductDescription(event.target.value)}
                  />
                </FormControl>
              </Box>
            </Flex>

          </Box>
          <Box w="25%">
            <Center mt="30">
              <ImageUpload
                imageuri={ProductImagePath}
                isEdit={true}
                id={ProductId}
                component='Table'
                onChange={(path) => {
                  setProductImagePath(path)
                }}

              />
            </Center>
          </Box>
        </Flex>

        <Flex flexDirection="row" alignItems="center" px={!type ? "8" : "0"} w="100%" mt={6}>
          <Button
            mt="2"
            mr="2"
            bgColor="#fad295"
            color="#000000"
            w="100"
            leftIcon={<Icon as={Ionicons} name="ios-save" size="xs" />}
            _text={{
              color: "#000000"
            }}
            onPress={HandleSubmit}
          >Save</Button>
          <Button
            mt="2"
            bgColor="#fc1a17"
            color="primary.50"
            w="100"
            leftIcon={<Icon as={Ionicons} name="ios-close" size="xs" />}
            onPress={CancelSubmit}
          >Cancel</Button>
        </Flex>

      </Fade> : <Flex flexDirection="row" alignItems="center" my="4" px={!type ? "8" : "0"} w="90%" mt={6} mh="100%"><SpinnerLoading /></Flex>
    }
  </>;
}

addOrEditProduct.propTypes = {
  loginResponse: PropTypes.any,
  PostTable: PropTypes.func,
  navigation: PropTypes.any,
  TableList: PropTypes.any,
  stateLookUp: PropTypes.any,
  productdetail: PropTypes.any
}

const styles = StyleSheet.create({
  inputFieldWrapper: {
    width: '20%',
    marginRight: 30
  },
});

const mapStateToProps = (state) => {
  const { TableDetailResponse, TableDetailLoading } = state.venue;
  const { loginResponse } = state.auth;
  const { productByIdResponse } = state.product;

  return {
    TableList: TableDetailResponse || [],
    loginResponse: loginResponse || "",
    loader: TableDetailLoading,
    productdetail: productByIdResponse || {}
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    GetProductDetailsById: (ProductId) => dispatch(productActions.FetchProductById(ProductId)).then((response) => response),
    PostProduct: (postreq) => dispatch(ProductCategoryActions.InsertUpdateProduct(postreq)).then((response) => response),
    GetProductCategoryByVenueId: (VenueId) => dispatch(VenueActions.GetProductCategoryByVenueId(VenueId)).then((response) => response),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(addOrEditProduct);