/* eslint-disable no-unused-vars */
import axios from 'axios';

// Init :: Config
import { API_URL } from '../../config';

// API Endpoints
import * as API_Interface from '../../services/apiURLs';

// Redux :: Action Types
import {
  //App settings
  GET_APP_SETTINGS_DETAIL_PENDING,
  GET_APP_SETTINGS_DETAIL_IDLE,
  GET_APP_SETTINGS_DETAIL_FAILED,
  GET_APP_SETTINGS_DETAIL_SUCCEEDED,
} from '../constants';

// Other Redux Actions
import { setAppLoading } from './common';

const PostAppSettings = (data, type) => async (dispatch) => {
  dispatch(setAppLoading(true));

  try {
    const response = await axios({
      method: "POST",
      baseURL: API_URL,
      url: `${API_Interface.INSERT_UPDATE_APP_SETTINGS}`,
      data: data,
      DocumentType: type,
    }).then((response) => response)
      .catch((error) => error.response)
    dispatch(setAppLoading(false));
    return response;
  } catch (exception) {
    dispatch(setAppLoading(false));
    return exception;
  }
}


const GetAppSettingsById = (id) => async (dispatch) => {
  dispatch({ type: GET_APP_SETTINGS_DETAIL_PENDING, payload: true });
  try {
    const response = await axios({
      method: 'GET',
      baseURL: API_URL,
      url: API_Interface.GET_APP_SETTINGS_BY_ID + id,
    }).then((response) => response)
      .catch((error) => error.response);
      
    if (response.status !== 200) {
      dispatch({ type: GET_APP_SETTINGS_DETAIL_IDLE, payload: response });
    } else if (response.status == 417) {
      await dispatch({ type: GET_APP_SETTINGS_DETAIL_FAILED, payload: response.data });
    } else {
      await dispatch({ type: GET_APP_SETTINGS_DETAIL_SUCCEEDED, payload: response.data });
    }
    return response;
  } catch (exception) {
    dispatch(setAppLoading(false));
    dispatch({ type: GET_APP_SETTINGS_DETAIL_FAILED, payload: exception });
  }
}

export {
  GetAppSettingsById,
  PostAppSettings
}

