// Redux :: Action Types
import {
  VENUE_IDLE,
  VENUE_PENDING,
  VENUE_FAILED,
  VENUE_SUCCEEDED,
  VENUE_VIEW_IDLE,
  VENUE_VIEW_PENDING,
  VENUE_VIEW_FAILED,
  VENUE_VIEW_SUCCEEDED,

  GET_ALL_VENUE_IDLE,
  GET_ALL_VENUE_PENDING,
  GET_ALL_VENUE_FAILED,
  GET_ALL_VENUE_SUCCEEDED,

  RESERVATION_CHECKIN_IDLE,
  RESERVATION_CHECKIN_PENDING,
  RESERVATION_CHECKIN_FAILED,
  RESERVATION_CHECKIN_SUCCEEDED,
  RESERVATION_CANCEL_IDLE,
  RESERVATION_CANCEL_PENDING,
  RESERVATION_CANCEL_FAILED,
  RESERVATION_CANCEL_SUCCEEDED,

  // Venue Users
  GET_VENUE_USERS_BY_VENUE_ID_IDLE,
  GET_VENUE_USERS_BY_VENUE_ID_PENDING,
  GET_VENUE_USERS_BY_VENUE_ID_FAILED,
  GET_VENUE_USERS_BY_VENUE_ID_SUCCEEDED,

  // Venue Block Dates
  GET_VENUE_BLOCK_DATES_ID_IDLE,
  GET_VENUE_BLOCK_DATES_ID_PENDING,
  GET_VENUE_BLOCK_DATES_ID_FAILED,
  GET_VENUE_BLOCK_DATES_ID_SUCCEEDED,

  // Venue Premium Date Range
  GET_VENUE_PREMIUM_DATE_RANGE_ID_IDLE,
  GET_VENUE_PREMIUM_DATE_RANGE_ID_PENDING,
  GET_VENUE_PREMIUM_DATE_RANGE_ID_FAILED,
  GET_VENUE_PREMIUM_DATE_RANGE_ID_SUCCEEDED,

  // Venue Settings By Day
  GET_VENUE_SETTING_BY_DAY_IDLE,
  GET_VENUE_SETTING_BY_DAY_PENDING,
  GET_VENUE_SETTING_BY_DAY_FAILED,
  GET_VENUE_SETTING_BY_DAY_SUCCEEDED,

  //Venue user detail
  GET_VENUE_USERS_IDLE,
  GET_VENUE_USERS_PENDING,
  GET_VENUE_USERS_FAILED,
  GET_VENUE_USERS_SUCCEEDED,

  // Report
  GET_VENUE_CACHE_REPORT_BY_DATE_IDLE,
  GET_VENUE_CACHE_REPORT_BY_DATE_PENDING,
  GET_VENUE_CACHE_REPORT_BY_DATE_FAILED,
  GET_VENUE_CACHE_REPORT_BY_DATE_SUCCEEDED,

  // ** Get Cache Report ** //
  GET_CACHE_REPORT_BY_DATE_IDLE,
  GET_CACHE_REPORT_BY_DATE_PENDING,
  GET_CACHE_REPORT_BY_DATE_FAILED,
  GET_CACHE_REPORT_BY_DATE_SUCCEEDED,

  // Venue Detail
  GET_VENUE_DETAIL_BY_VENUE_ID_IDLE,
  GET_VENUE_DETAIL_BY_VENUE_ID_PENDING,
  GET_VENUE_DETAIL_BY_VENUE_ID_FAILED,
  GET_VENUE_DETAIL_BY_VENUE_ID_SUCCEEDED,

  // Venue Reservation Detail
  GET_VENUE_RESERVATION_DETAIL_BY_VENUE_ID_IDLE,
  GET_VENUE_RESERVATION_DETAIL_BY_VENUE_ID_PENDING,
  GET_VENUE_RESERVATION_DETAIL_BY_VENUE_ID_FAILED,
  GET_VENUE_RESERVATION_DETAIL_BY_VENUE_ID_SUCCEEDED,

  // Venue Table details
  GET_VENUE_TABLE_BY_TABLE_ID_IDLE,
  GET_VENUE_TABLE_BY_VENUE_ID_PENDING,
  GET_VENUE_TABLE_BY_VENUE_ID_FAILED,
  GET_VENUE_TABLE_BY_VENUE_ID_SUCCEEDED,

  // Venue Product Category
  GET_PRODUCT_CATEGORY_BY_VENUE_ID_IDLE,
  GET_PRODUCT_CATEGORY_BY_VENUE_ID_PENDING,
  GET_PRODUCT_CATEGORY_BY_VENUE_ID_FAILED,
  GET_PRODUCT_CATEGORY_BY_VENUE_ID_SUCCEEDED,

  // Venue Product Category
  GET_TABLE_DETAIL_BY_ID_IDLE,
  GET_TABLE_DETAIL_BY_ID_PENDING,
  GET_TABLE_DETAIL_BY_ID_FAILED,
  GET_TABLE_DETAIL_BY_ID_SUCCEEDED,

  // cache Report
  GET_VENUE_CACHE_REPORT_BY_VENUE_ID_IDLE,
  GET_VENUE_CACHE_REPORT_BY_VENUE_ID_PENDING,
  GET_VENUE_CACHE_REPORT_BY_VENUE_ID_FAILED,
  GET_VENUE_CACHE_REPORT_BY_VENUE_ID_SUCCEEDED,

  //Venue Reservation REPORT
  GET_RESERVATION_REPORT_BY_VENUE_PENDING,
  GET_RESERVATION_REPORT_BY_VENUE_IDLE,
  GET_RESERVATION_REPORT_BY_VENUE_FAILED,
  GET_RESERVATION_REPORT_BY_VENUE_SUCCEEDED,

  //Venue Guest Summary by User
  GET_GUEST_SUMMARY_BY_USER_IDLE,
  GET_GUEST_SUMMARY_BY_USER_PENDING,
  GET_GUEST_SUMMARY_BY_USER_FAILED,
  GET_GUEST_SUMMARY_BY_USER_SUCCEEDED,

  //Venue Deposite Settings
  GET_VENUE_DEPOSITE_SETTINGS_IDLE,
  GET_VENUE_DEPOSITE_SETTINGS_PENDING,
  GET_VENUE_DEPOSITE_SETTINGS_FAILED,
  GET_VENUE_DEPOSITE_SETTINGS_SUCCEEDED,

  // Send Reservation Report
  SEND_RESERVATION_REPORT_IDLE,
  SEND_RESERVATION_REPORT_PENDING,
  SEND_RESERVATION_REPORT_FAILED,
  SEND_RESERVATION_REPORT_SUCCEEDED,

  // Send Guest Summary Report
  SEND_GUEST_SUMMARY_IDLE,
  SEND_GUEST_SUMMARY_PENDING,
  SEND_GUEST_SUMMARY_FAILED,
  SEND_GUEST_SUMMARY_SUCCEEDED,

  // Send Cache Report
  SEND_CACHE_REPORT_IDLE,
  SEND_CACHE_REPORT_PENDING,
  SEND_CACHE_REPORT_FAILED,
  SEND_CACHE_REPORT_SUCCEEDED,

} from '../constants';

/* ----- INITIAL STATE ----- */
const initialState = {
  loading: false,
  idleErr: null,
  venueErr: null,
  venueResponse: [],
  venuedetailsResponse: [],

  venueUsersLoading: false,
  venueUsersIdleErr: null,
  venueUsersResponse: null,
  venueUsersErr: null,

  venueBlockDatesLoading: false,
  venueBlockDatesIdleErr: null,
  venueBlockDatesResponse: null,
  venueBlockDatesErr: null,

  venuePremiumDatesLoading: false,
  venuePremiumDatesIdleErr: null,
  venuePremiumDatesResponse: null,
  venuePremiumDatesErr: null,

  venueSettingByDayLoading: false,
  venueSettingByDayIdleErr: null,
  venueSettingByDayResponse: null,
  venueSettingByDayErr: null,

  venueViewLoading: false,
  venueViewIdleErr: null,
  venueViewResponse: null,
  venueViewErr: null,

  venuesListWithPagingLoading: false,
  venuesListWithPagingIdleErr: [],
  venuesListWithPagingResponse: [],
  venuesListWithPagingErr: null,

  venueCacheReportLoading: false,
  venueCacheReportIdleErr: null,
  venueCacheReportResponse: null,
  venueCacheReportErr: null,

  VenuesDetailLoading: false,
  VenuesDetailIdleErr: [],
  VenuesDetailResponse: [],
  VenuesDetailErr: null,

  venuesReservationListWithPagingLoading: false,
  venuesReservationListWithPagingIdleErr: [],
  venuesReservationListWithPagingResponse: [],
  venuesReservationListWithPagingErr: null,

  venueTableLoading: false,
  venueTableIdleErr: null,
  venueTableResponse: null,
  venueTableErr: null,

  productCategoryLoading: false,
  productCategoryIdleErr: null,
  productCategoryResponse: null,
  productCategoryErr: null,

  TableDetailLoading: false,
  TableDetailIdleErr: [],
  TableDetailResponse: [],
  TableDetailPagingErr: null,

  venueCacheReportbyvenueIdLoading: false,
  venueCacheReportbyvenueIdIdleErr: null,
  venueCacheReportbyvenueIdResponse: null,
  venueCacheReportbyvenueIdErr: null,

  cacheReportDetailsLoading:false,
  cacheReportDetailsResponse: null,
  cacheReportDetailsIdleErr: null,
  cacheReportDetailsError: null,

  venuesReservationReportWithPagingResponse: [],
  venuesReservationReportWithPagingLoading: false,
  venuesReservationReportWithPagingIdleErr: [],
  venuesReservationReportsWithPagingErr: null,
  venuesReservationReportResponse: [],

  venuesGuestSummaryWithPagingLoading: false,
  venuesGuestSummaryWithPagingIdleErr: [],
  venuesGuestSummaryWithPagingResponse: [],
  venuesGuestSummaryWithPagingErr: null,

  venueDepositewithPagingLoading: false,
  venueDepositewithPagingIdleErr: [],
  venueDepositewithPagingResponse: {},
  venueDepositewithPagingErr: null,

  sendVenueReservationReportLoading: false,
  sendVenueReservationReportIdleErr: null,
  sendVenueReservationReportResponse: null,
  sendVenueReservationReportErr: null,

  sendGuestSummaryLoading: false,
  sendGuestSummaryIdleErr: null,
  sendGuestSummaryResponse: null,
  sendGuestSummaryErr: null,

  sendCacheReportLoading: false,
  sendCacheReportIdleErr: null,
  sendCacheReportResponse: null,
  sendCacheReportErr: null,
};

/* ----- REDUCER ----- */
const venueReducer = (state = initialState, action) => {
  switch (action.type) {

    case VENUE_PENDING:
      return {
        ...state,
        loading: true,
        venueResponse: [],
      };
    case VENUE_IDLE:
      return {
        ...state,
        loading: false,
        idleErr: action.payload
      };
    case VENUE_SUCCEEDED:
      return {
        ...state,
        loading: false,
        venueResponse: action.payload
      };

    case VENUE_FAILED:
      return {
        ...state,
        loading: false,
        venueErr: action.payload
      };

    // Venue Users
    case GET_VENUE_USERS_BY_VENUE_ID_PENDING:
      return {
        ...state,
        venueUsersLoading: true,
        venueUsersResponse: [],
      };

    case GET_VENUE_USERS_BY_VENUE_ID_IDLE:
      return {
        ...state,
        venueUsersLoading: false,
        venueUsersIdleErr: action.payload
      };

    case GET_VENUE_USERS_BY_VENUE_ID_SUCCEEDED:
      return {
        ...state,
        venueUsersLoading: false,
        venueUsersResponse: action.payload
      };

    case GET_VENUE_USERS_BY_VENUE_ID_FAILED:
      return {
        ...state,
        venueUsersLoading: false,
        venueUsersErr: action.payload
      };

    // Get Block Dates
    case GET_VENUE_BLOCK_DATES_ID_PENDING:
      return {
        ...state,
        venueBlockDatesLoading: true,
        venueBlockDatesResponse: [],
      };

    case GET_VENUE_BLOCK_DATES_ID_IDLE:
      return {
        ...state,
        venueBlockDatesLoading: false,
        venueBlockDatesIdleErr: action.payload
      };

    case GET_VENUE_BLOCK_DATES_ID_SUCCEEDED:
      return {
        ...state,
        venueBlockDatesLoading: false,
        venueBlockDatesResponse: action.payload
      };

    case GET_VENUE_BLOCK_DATES_ID_FAILED:
      return {
        ...state,
        venueBlockDatesLoading: false,
        venueBlockDatesErr: action.payload
      };

    // Get Venue Premium Dates
    case GET_VENUE_PREMIUM_DATE_RANGE_ID_PENDING:
      return {
        ...state,
        venuePremiumDatesLoading: true,
        venuePremiumDatesResponse: {},
      };

    case GET_VENUE_PREMIUM_DATE_RANGE_ID_IDLE:
      return {
        ...state,
        venuePremiumDatesLoading: false,
        venuePremiumDatesIdleErr: action.payload
      };

    case GET_VENUE_PREMIUM_DATE_RANGE_ID_SUCCEEDED:
      return {
        ...state,
        venuePremiumDatesLoading: false,
        venuePremiumDatesResponse: action.payload
      };

    case GET_VENUE_PREMIUM_DATE_RANGE_ID_FAILED:
      return {
        ...state,
        venuePremiumDatesLoading: false,
        venuePremiumDatesErr: action.payload
      };

    // Get Venue Settings By Day
    case GET_VENUE_SETTING_BY_DAY_PENDING:
      return {
        ...state,
        venueSettingByDayLoading: true,
        venueSettingByDayResponse: {},
      };

    case GET_VENUE_SETTING_BY_DAY_IDLE:
      return {
        ...state,
        venueSettingByDayLoading: false,
        venueSettingByDayIdleErr: action.payload
      };

    case GET_VENUE_SETTING_BY_DAY_SUCCEEDED:
      return {
        ...state,
        venueSettingByDayLoading: false,
        venueSettingByDayResponse: action.payload
      };

    case GET_VENUE_SETTING_BY_DAY_FAILED:
      return {
        ...state,
        venueSettingByDayLoading: false,
        venueSettingByDayErr: action.payload
      };

    //Venue View
    case VENUE_VIEW_PENDING:
      return {
        ...state,
        venueViewLoading: true,
        venueViewResponse: null
      };

    case VENUE_VIEW_IDLE:
      return {
        ...state,
        venueViewLoading: false,
        idleErr: action.payload
      };

    case VENUE_VIEW_SUCCEEDED:
      return {
        ...state,
        venueViewLoading: false,
        venueViewResponse: action.payload
      };

    case VENUE_VIEW_FAILED:
      return {
        ...state,
        venueViewLoading: false,
        venueViewErr: action.payload
      };

    case GET_ALL_VENUE_PENDING:
      return {
        ...state,
        venuesListWithPagingLoading: true,
        venuesListWithPagingResponse: [],
      };

    case GET_ALL_VENUE_IDLE:
      return {
        ...state,
        venuesListWithPagingLoading: false,
        venuesListWithPagingIdleErr: action.payload
      };

    case GET_ALL_VENUE_SUCCEEDED:
      return {
        ...state,
        venuesListWithPagingLoading: false,
        venuesListWithPagingResponse: action.payload
      };

    case GET_ALL_VENUE_FAILED:
      return {
        ...state,
        venuesListWithPagingLoading: false,
        venuesListWithPagingErr: action.payload
      };


    // Venue reservation
    case GET_VENUE_RESERVATION_DETAIL_BY_VENUE_ID_PENDING:
      return {
        ...state,
        venuesReservationListWithPagingLoading: true,
        venuesReservationListWithPagingResponse: [],
      };

    case GET_VENUE_RESERVATION_DETAIL_BY_VENUE_ID_IDLE:
      return {
        ...state,
        venuesReservationListWithPagingLoading: false,
        venuesReservationListWithPagingIdleErr: action.payload
      };

    case GET_VENUE_RESERVATION_DETAIL_BY_VENUE_ID_SUCCEEDED:
      return {
        ...state,
        venuesReservationListWithPagingLoading: false,
        venuesReservationListWithPagingResponse: action.payload
      };

    case GET_VENUE_RESERVATION_DETAIL_BY_VENUE_ID_FAILED:
      return {
        ...state,
        venuesReservationListWithPagingLoading: false,
        venuesReservationListWithPagingErr: action.payload
      };

    // Venue Reservation  Report
    case GET_RESERVATION_REPORT_BY_VENUE_PENDING:
      return {
        ...state,
        venuesReservationReportWithPagingLoading: true,
        venuesReservationReportWithPagingResponse: [],
      };

    case GET_RESERVATION_REPORT_BY_VENUE_IDLE:
      return {
        ...state,
        venuesReservationReportWithPagingLoading: false,
        venuesReservationReportWithPagingIdleErr: action.payload
      };

    case GET_RESERVATION_REPORT_BY_VENUE_SUCCEEDED:
      return {
        ...state,
        venuesReservationReportWithPagingLoading: false,
        venuesReservationReportWithPagingResponse: action.payload
      };

    case GET_RESERVATION_REPORT_BY_VENUE_FAILED:
      return {
        ...state,
        venuesReservationReportWithPagingLoading: false,
        venuesReservationReportWithPagingErr: action.payload
      };

    //Venue Guest Summary
    case GET_GUEST_SUMMARY_BY_USER_PENDING:
      return {
        ...state,
        venuesGuestSummaryWithPagingLoading: true,
        venuesGuestSummaryWithPagingResponse: [],
      };

    case GET_GUEST_SUMMARY_BY_USER_IDLE:
      return {
        ...state,
        venuesGuestSummaryWithPagingLoading: false,
        venuesGuestSummaryWithPagingIdleErr: action.payload
      };

    case GET_GUEST_SUMMARY_BY_USER_SUCCEEDED:
      return {
        ...state,
        venuesGuestSummaryWithPagingLoading: false,
        venuesGuestSummaryWithPagingResponse: action.payload
      };

    case GET_GUEST_SUMMARY_BY_USER_FAILED:
      return {
        ...state,
        venuesGuestSummaryWithPagingLoading: false,
        venuesGuestSummaryWithPagingErr: action.payload
      };

    // Venue Deposite Settings
    case GET_VENUE_DEPOSITE_SETTINGS_PENDING:
      return {
        ...state,
        venueDepositewithPagingLoading: true,
        venueDepositewithPagingResponse: {},
      };

    case GET_VENUE_DEPOSITE_SETTINGS_IDLE:
      return {
        ...state,
        venueDepositewithPagingLoading: false,
        venueDepositewithPagingIdleErr: action.payload
      };

    case GET_VENUE_DEPOSITE_SETTINGS_SUCCEEDED:
      return {
        ...state,
        venueDepositewithPagingLoading: false,
        venueDepositewithPagingResponse: action.payload
      };

    case GET_VENUE_DEPOSITE_SETTINGS_FAILED:
      return {
        ...state,
        venueDepositewithPagingLoading: false,
        venueDepositewithPagingErr: action.payload
      };

    // Reporting
    case GET_VENUE_CACHE_REPORT_BY_DATE_PENDING:
      return {
        ...state,
        venueCacheReportResponse: [],
      };
    case GET_VENUE_CACHE_REPORT_BY_DATE_IDLE:
      return {
        ...state,
        venueCacheReportIdleErr: action.payload
      };
    case GET_VENUE_CACHE_REPORT_BY_DATE_SUCCEEDED:
      return {
        ...state,
        venueCacheReportResponse: action.payload
      };
    case GET_VENUE_CACHE_REPORT_BY_DATE_FAILED:
      return {
        ...state,
        venueCacheReportErr: action.payload
      };

    // Get Cache Report 
    case GET_CACHE_REPORT_BY_DATE_PENDING:
      return {
        ...state,
        cacheReportDetailsLoading:true,
        cacheReportDetailsResponse: [],
      };
    case GET_CACHE_REPORT_BY_DATE_IDLE:
      return {
        ...state,
        cacheReportDetailsLoading:false,
        cacheReportDetailsIdleErr: action.payload
      };
    case GET_CACHE_REPORT_BY_DATE_SUCCEEDED:
      return {
        ...state,
        cacheReportDetailsLoading:false,
        cacheReportDetailsResponse: action.payload
      };
    case GET_CACHE_REPORT_BY_DATE_FAILED:
      return {
        ...state,
        cacheReportDetailsLoading:false,
        cacheReportDetailsError: action.payload
      };

    // Cache Reporting
    case GET_VENUE_CACHE_REPORT_BY_VENUE_ID_PENDING:
      return {
        ...state,
        venueCacheReportbyvenueIdResponse: [],
      };
    case GET_VENUE_CACHE_REPORT_BY_VENUE_ID_IDLE:
      return {
        ...state,
        venueCacheReportbyvenueIdIdleErr: action.payload
      };
    case GET_VENUE_CACHE_REPORT_BY_VENUE_ID_SUCCEEDED:
      return {
        ...state,
        venueCacheReportbyvenueIdResponse: action.payload
      };
    case GET_VENUE_CACHE_REPORT_BY_VENUE_ID_FAILED:
      return {
        ...state,
        venueCacheReportbyvenueIdErr: action.payload
      };

    // Venue Detail
    case GET_VENUE_DETAIL_BY_VENUE_ID_PENDING:
      return {
        ...state,
        VenuesDetailLoading: true,
        VenuesDetailResponse: [],
      };
    case GET_VENUE_DETAIL_BY_VENUE_ID_IDLE:
      return {
        ...state,
        VenuesDetailLoading: false,
        VenuesDetailIdleErr: action.payload
      };
    case GET_VENUE_DETAIL_BY_VENUE_ID_SUCCEEDED:
      return {
        ...state,
        VenuesDetailLoading: false,
        VenuesDetailResponse: action.payload
      };
    case GET_VENUE_DETAIL_BY_VENUE_ID_FAILED:
      return {
        ...state,
        VenuesDetailLoading: false,
        VenuesDetailErr: action.payload
      };

    //Venue Table View
    case GET_VENUE_TABLE_BY_VENUE_ID_PENDING:
      return {
        ...state,
        venueTableLoading: true,
        venueTableResponse: null
      };

    case GET_VENUE_TABLE_BY_TABLE_ID_IDLE:
      return {
        ...state,
        venueTableLoading: false,
        venueTableIdleErr: action.payload
      };

    case GET_VENUE_TABLE_BY_VENUE_ID_SUCCEEDED:
      return {
        ...state,
        venueTableLoading: false,
        venueTableResponse: action.payload
      };

    case GET_VENUE_TABLE_BY_VENUE_ID_FAILED:
      return {
        ...state,
        venueTableLoading: false,
        venueTableErr: action.payload
      };

    // Venue product category View
    case GET_PRODUCT_CATEGORY_BY_VENUE_ID_PENDING:
      return {
        ...state,
        productCategoryLoading: true,
        productCategoryResponse: null
      };

    case GET_PRODUCT_CATEGORY_BY_VENUE_ID_IDLE:
      return {
        ...state,
        productCategoryLoading: false,
        productCategoryIdleErr: action.payload
      };

    case GET_PRODUCT_CATEGORY_BY_VENUE_ID_SUCCEEDED: {
      let ProductCategoryArray = action.payload.map(cateogry => {
        return {
          title: cateogry.description,
          content: cateogry.products,
          id: cateogry.id
        }
      });
      return {
        ...state,
        productCategoryLoading: false,
        productCategoryResponse: action.payload,
        CategoryAccordionList: ProductCategoryArray
      };
    }

    case GET_PRODUCT_CATEGORY_BY_VENUE_ID_FAILED:
      return {
        ...state,
        productCategoryLoading: false,
        productCategoryIdleErr: action.payload
      };

    case GET_TABLE_DETAIL_BY_ID_PENDING:
      return {
        ...state,
        TableDetailLoading: true,
        TableDetailResponse: [],
      };
    case GET_TABLE_DETAIL_BY_ID_IDLE:
      return {
        ...state,
        TableDetailLoading: false,
        TableDetailIdleErr: action.payload
      };
    case GET_TABLE_DETAIL_BY_ID_SUCCEEDED:
      return {
        ...state,
        TableDetailLoading: false,
        TableDetailResponse: action.payload
      };
    case GET_TABLE_DETAIL_BY_ID_FAILED:
      return {
        ...state,
        TableDetailLoading: false,
        TableDetailErr: action.payload
      };

    // Send Reservation Report
    case SEND_RESERVATION_REPORT_PENDING:
      return {
        ...state,
        sendVenueReservationReportLoading: true,
        sendVenueReservationReportResponse: [],
      };

    case SEND_RESERVATION_REPORT_IDLE:
      return {
        ...state,
        sendVenueReservationReportLoading: false,
        sendVenueReservationReportIdleErr: action.payload
      };

    case SEND_RESERVATION_REPORT_SUCCEEDED:
      return {
        ...state,
        sendVenueReservationReportLoading: false,
        sendVenueReservationReportResponse: action.payload
      };

    case SEND_RESERVATION_REPORT_FAILED:
      return {
        ...state,
        sendVenueReservationReportLoading: false,
        sendVenueReservationReportErr: action.payload
      };

    // Send Guest Summary Report
    case SEND_GUEST_SUMMARY_PENDING:
      return {
        ...state,
        sendGuestSummaryLoading: true,
        sendGuestSummaryResponse: [],
      };

    case SEND_GUEST_SUMMARY_IDLE:
      return {
        ...state,
        sendGuestSummaryLoading: false,
        sendGuestSummaryIdleErr: action.payload
      };

    case SEND_GUEST_SUMMARY_SUCCEEDED:
      return {
        ...state,
        sendGuestSummaryLoading: false,
        sendGuestSummaryResponse: action.payload
      };

    case SEND_GUEST_SUMMARY_FAILED:
      return {
        ...state,
        sendGuestSummaryLoading: false,
        sendGuestSummaryErr: action.payload
      };

    // Send Cache Report
    case SEND_CACHE_REPORT_PENDING:
      return {
        ...state,
        sendCacheReportLoading: true,
        sendCacheReportResponse: [],
      };

    case SEND_CACHE_REPORT_IDLE:
      return {
        ...state,
        sendCacheReportLoading: false,
        sendCacheReportIdleErr: action.payload
      };

    case SEND_CACHE_REPORT_SUCCEEDED:
      return {
        ...state,
        sendCacheReportLoading: false,
        sendCacheReportResponse: action.payload
      };

    case SEND_CACHE_REPORT_FAILED:
      return {
        ...state,
        sendCacheReportLoading: false,
        sendCacheReportErr: action.payload
      };

    default:
      return state;

  }
}

/* -----  CheckInReservation INITIAL STATE ----- */
const CheckInInitialState = {
  loading: false,
  idleErr: null,
  checkInErr: null,
  checkInResponse: [],
};

/* -----  CheckInReservation REDUCER ----- */
const CheckInReservationReducer = (state = CheckInInitialState, action) => {
  switch (action.type) {

    case RESERVATION_CHECKIN_PENDING:
      return {
        ...state,
        loading: action.payload
      };
    case RESERVATION_CHECKIN_IDLE:
      return {
        ...state,
        idleErr: action.payload
      };
    case RESERVATION_CHECKIN_SUCCEEDED:{
      return {
        ...state,
        checkInResponse: action.payload
      };
}
    case RESERVATION_CHECKIN_FAILED:
      return {
        ...state,
        checkInErr: action.payload
      };

    default:
      return state;

  }
}

/* ----- CancelReservationReducer INITIAL STATE ----- */
const CancelReservationInitialState = {
  loading: false,
  idleErr: null,
  cancelErr: null,
  cancelResponse: []
};

/* ----- REDUCER ----- */
const CancelReservationReducer = (state = CancelReservationInitialState, action) => {
  switch (action.type) {

    case RESERVATION_CANCEL_PENDING:
      return {
        ...state,
        loading: action.payload
      };
    case RESERVATION_CANCEL_IDLE:
      return {
        ...state,
        idleErr: action.payload
      };
    case RESERVATION_CANCEL_SUCCEEDED:
      return {
        ...state,
        cancelResponse: action.payload
      };

    case RESERVATION_CANCEL_FAILED:
      return {
        ...state,
        cancelErr: action.payload
      };

    default:
      return state;

  }
}



/* ----- Venue user INITIAL STATE ----- */
const venueUserInitialState = {
  Loading: false,
  VenueUserIdleErr: [],
  VenueUserResponse: [],
  VenueUserErr: null
};


/* ----- Venue user  REDUCER ----- */
const venueUserReducer = (state = venueUserInitialState, action) => {
  switch (action.type) {

    case GET_VENUE_USERS_PENDING:
      return {
        ...state,
        loading: true,
        VenueUserResponse: [],
      };

    case GET_VENUE_USERS_IDLE:
      return {
        ...state,
        loading: false,
        VenueUserIdleErr: action.payload
      };

    case GET_VENUE_USERS_SUCCEEDED:
      return {
        ...state,
        loading: false,
        VenueUserResponse: action.payload
      };

    case GET_VENUE_USERS_FAILED:
      return {
        ...state,
        loading: false,
        VenueUserErr: action.payload
      };

    default:
      return state;

  }
}



export { venueReducer, CheckInReservationReducer, CancelReservationReducer, venueUserReducer };