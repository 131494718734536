/* eslint-disable react-native/no-raw-text */
import React, { useEffect, useState } from "react";
import { Box, Text, HStack, Center, Flex, Heading, Divider, Button, Popover, Icon } from 'native-base';
import { connect } from 'react-redux';
import { DataTable } from 'react-native-paper';
import Moment from 'moment';
import { Calendar } from 'react-date-range';
import { FontAwesome } from '@expo/vector-icons';
import { MaterialIcons } from '@expo/vector-icons';
import PropTypes from 'prop-types';


// Components
import { SpinnerLoading } from '../../../shared/templates/SpinnerLoading';

/* ----- UTILS ----- */
import { primaryColors } from '../../../shared/utils/colors';

/* ----- REDUX :: ACTIONS ----- */
import * as VenueActions from '../../../shared/redux/actions/venue';
import { id } from "date-fns/locale";

const VenueReservationList = (props) => {
  const { venueDetail, venuesReservationListWithPagingResponse, venuesReservationListWithPagingLoading,
    headerTitleOptions, venueId, GetReservationDetailsWithPaging, DownloadVenueAnalysticsReport } = props;
  const [fromDate, setfromDate] = useState(Moment().subtract(7, 'days').format('MM/DD/YYYY'));
  const [toDate, settoDate] = useState(Moment().format('MM/DD/YYYY'));
  const [OpenfromDate, setOpenfromDate] = useState(false);
  const [OpentoDate, setOpentoDate] = useState(false);

  const data = { 'venueId': venueId, 'Fromdate': fromDate, 'Todate': toDate };


  // const [pageNumber, setPageNumber] = useState(0);
  // const [pageSize] = useState(5);
  // const [totalNumberOfPages, setTotalNumberOfPages] = useState(5);

  // useEffect(() => {
  //   if (venueDetail?.id > 0) {
  //     loadReservationsList(venueDetail?.id);
  //   }
  // }, [venueDetail]);

  // useEffect(() => { loadReservationsList(venueDetail?.id) }, [pageNumber]);

  useEffect(() => {
    if (venueId > 0) {
      setTimeout(() => loadReservationsList(venueId), 50);
    }
  }, [venueId])


  useEffect(() => {
    if (fromDate) {
      setTimeout(() => loadReservationsList(venueId), 50);
    }
  }, [fromDate]);

  useEffect(() => {
    if (toDate) {
      setTimeout(() => loadReservationsList(venueId), 50);
    }
  }, [toDate]);






  const loadReservationsList = async (id) => {
    await GetReservationDetailsWithPaging(
      {
        pageNumber: 0,
        pageSize: 0,
        id: id,
        fromDate: Moment(fromDate, "MM/DD/YYYY").format("YYYY-MM-DD[T]HH:mm:ss") || '',
        toDate: Moment(toDate, "MM/DD/YYYY").format("YYYY-MM-DD[T]HH:mm:ss") || '',
      },
      false
    );
    // setTotalNumberOfPages(Math.ceil(50 / 5) - 1);
  }

  const renderHeader = (headers) => {
    return <HStack space={3}>
      {
        headers.map((item, iteration) => {
          return <Box w={item?.width} key={`${item?.key}_${iteration}`} h="10px" display="flex" flexDirection="row" alignItems="center"
            justifyContent={item?.label === "Date" || item?.label === "Searches" || item?.label === "Table Clicks" || item?.label === "Product Clicks"
              || item?.label === "Bookings" || item?.label === "Booking %" || item?.label === "Total Minimum" || item?.label === "Deposits Paid"? "center" : "flex-end"}
          >
            {(item?.label === "Date") ? <Text fontWeight="bold" italic>{item?.label}</Text>
              : <Center><Text fontWeight="bold" italic>{item?.label}</Text></Center>}
          </Box>;
        })
      }
    </HStack>
  }

  const renderRowItem = (rowItem, rowIteration) => {
    return <HStack justifyContent="flex-start" space={2}>
      {
        headerTitleOptions.map((item, iteration) => {
          return <Box key={`${item?.width}_${rowIteration}_v1${iteration}`} w={`${item?.width}`} >
            {item?.label === "Date" && <Text fontSize="md"  textAlign="center" fontWeight="bold" >{rowItem.activityDate ? Moment(rowItem?.activityDate).format("MM/DD") : 0}</Text>}
            {item?.label === "Searches" && <Center><Text fontSize="md">{rowItem.visits ? rowItem.visits : 0}</Text></Center>}
            {item?.label === "Table Clicks" && <Center><Text fontSize="md">{rowItem.tableView ? rowItem.tableView : 0}</Text></Center>}
            {item?.label === "Product Clicks" && <Center><Text fontSize="md">{rowItem.products ? rowItem.products : 0}</Text></Center>}
            {item?.label === "Bookings" && <Center><Text fontSize="md" marginLeft="20px">{rowItem.bookings ? rowItem.bookings : 0}</Text></Center>}
            {item?.label === "Booking %" && <Center><Text fontSize="md" marginLeft="35px">{rowItem.bookingPercent ? rowItem.bookingPercent + ' %' : '0 %'}</Text></Center>}
            {item?.label === "Total Minimum" && <Center><Text fontSize="md" marginLeft="45px" >{rowItem.sales ? rowItem.sales.toLocaleString("en-US", { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) : 0.00}</Text></Center>}
            {item?.label === "Deposits Paid" && <Center><Text fontSize="md" marginLeft="30px">{rowItem.deposit ? rowItem.deposit.toLocaleString("en-US", { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) : 0.00}</Text></Center>}
          </Box>;
        })
      }

    </HStack>;
  }

  // const { Pagination } = DataTable;

  return <>
    <Flex flexDirection="row" alignItems="center" w={"100%"}>
      <Heading size='lg'>Venue Analytics</Heading>
    </Flex>
    <Divider my="3" bg="muted.400" />

    <Flex
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      my="3"
      w={'100%'}
      mt={6}
    >

      <Flex
        flexDirection="row"
        alignItems="center"
        justifyContent="space-around"
      >

        <Text bold w="100%" fontSize="md" whiteSpace="nowrap">
          Start Date
        </Text>
        <Box pr={1} />
        <Popover
          isOpen={OpenfromDate}
          onOpen={() => {
            setOpenfromDate(true)
          }}
          onClose={() => setOpenfromDate(false)}
          placement="bottom left"
          trigger={(triggerProps) => {
            console.log("Start---", triggerProps);
            return (
              <Button
                w="130"
                px="1"
                py="1"
                borderRadius="md"
                variant="outline"
                colorScheme="dark.50"
                leftIcon={
                  <Icon
                    as={MaterialIcons}
                    name="calendar-today"
                    size="4"
                  />
                }
                onPress={() => setOpenfromDate(!OpenfromDate)}
                {...triggerProps}
              >
                {fromDate}
              </Button>
            );
          }}
        >
          <Popover.Content
            accessibilityLabel="Filter By Date"
            w="full"
            bg="muted.300"
          >
            <Popover.Arrow />
            <Popover.Body>
              <Calendar
                className="webCalendarPicker"
                fromDate={Moment(fromDate, 'MM/DD/YYYY').toDate()}
                maxDate={Moment(toDate, 'MM/DD/YYYY').toDate()}
                direction="vertical"
                fixedHeight
                color={primaryColors.primaryRed}
                onChange={(fromDate) => {
                  setfromDate(Moment(fromDate).format('MM/DD/YYYY'));
                  setOpenfromDate(false);
                }}
              />
            </Popover.Body>
          </Popover.Content>
        </Popover>

        <Box pr={3} />

        <Text bold w="100%" fontSize="md" whiteSpace="nowrap">
          End Date
        </Text>
        <Box pr={1} />
        <Popover
          isOpen={OpentoDate}
          onOpen={() => {
            setOpentoDate(true)
          }}
          onClose={() => setOpentoDate(false)}
          placement="bottom left"
          trigger={(triggerProps) => {
            return (
              <Button
                w="130"
                px="1"
                py="1"
                borderRadius="md"
                variant="outline"
                colorScheme="dark.50"
                leftIcon={
                  <Icon
                    as={MaterialIcons}
                    name="calendar-today"
                    size="4"
                  />
                }
                onPress={() => setOpentoDate(!OpentoDate)}
                {...triggerProps}
              >
                {toDate}
              </Button>
            );
          }}
        >
          <Popover.Content
            accessibilityLabel="Filter By Date"
            w="full"
            bg="muted.300"
          >
            <Popover.Arrow />
            <Popover.Body>
              <Calendar
                className="webCalendarPicker"
                toDate={Moment(toDate, 'MM/DD/YYYY').toDate()}
                direction="vertical"
                fixedHeight
                color={primaryColors.primaryRed}
                onChange={(toDate) => {
                  settoDate(Moment(toDate).format('MM/DD/YYYY'));
                  setOpentoDate(false);
                }}
              />
            </Popover.Body>
          </Popover.Content>
        </Popover>
      </Flex>

      <Button
        background={primaryColors.primaryRed}
        onPress={() => DownloadVenueAnalysticsReport(data)}
        leftIcon={<Icon as={MaterialIcons} name="file-download" size="5" />}
      >
        Download
      </Button>

    </Flex>

    <Box px="8" py="4" bg="rgb(208, 208, 208)">{renderHeader(headerTitleOptions || [])}</Box>
    <Box minHeight="50">
      {
        !venuesReservationListWithPagingLoading ? <>
          {
            venuesReservationListWithPagingResponse && venuesReservationListWithPagingResponse.length > 0 ? <>
              {
                venuesReservationListWithPagingResponse.map((item, iteration) => {

                  return <Box
                    key={`venueReservationList_${item.id}_${iteration}`}
                    px="8"
                    py="2">
                    {renderRowItem(item, iteration)}
                  </Box>
                }
                )
              }
            </> : <Box px="8" py="2" fontSize={15} display='flex' justifyContent='center' alignSelf='center'>NO DATA FOUND</Box>
          }
        </> : <SpinnerLoading />
      }

    </Box>
    {/* <Pagination
      page={pageNumber}
      numberOfPages={totalNumberOfPages + 1}
      onPageChange={(page) => setPageNumber(page)}
      label={`${pageNumber + 1}-${totalNumberOfPages + 1} of ${totalNumberOfPages + 1}`}
    /> */}
  </>;
}

const mapStateToProps = (state) => {
  const { VenuesDetailResponse, venuesReservationListWithPagingResponse, venuesReservationListWithPagingLoading } = state.venue;

  return {
    venuesReservationListWithPagingResponse,
    venuesReservationListWithPagingLoading,
    venueDetail: VenuesDetailResponse || []
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    GetReservationDetailsWithPaging: (data, all) => dispatch(VenueActions.GetReservationDetailsWithPaging(data, all)).then((response) => response),
    DownloadVenueAnalysticsReport: (data) => dispatch(VenueActions.DownloadVenueAnalyticsReport(data)).then((response) => response)
  };
}

VenueReservationList.defaultProps = {
  headerTitleOptions: [
    { key: '0_Date', label: "Date", width: "10%" },
    { key: '1_Reservations_Set', label: "Searches", width: "10%" },
    { key: '2_Reservations_Confirmed', label: "Table Clicks", width: "10%" },
    { key: '3_Reservations_Queued', label: "Product Clicks", width: "10%" },
    { key: '4_Reservations_Completed', label: "Bookings", width: "10%" },
    { key: '5_Reservations_Cleared', label: "Booking %", width: "10%" },
    { key: '6_Sales', label: "Total Minimum", width: "12%" },
    { key: '6_Deposit', label: "Deposits Paid", width: "10%" }
  ]
}

VenueReservationList.propTypes = {
  headerTitleOptions: PropTypes.any,
  venuesReservationListWithPagingResponse: PropTypes.any,
  venuesReservationListWithPagingLoading: PropTypes.any,
  GetReservationDetailsWithPaging: PropTypes.func,
  venueDetail: PropTypes.any
};

export default connect(mapStateToProps, mapDispatchToProps)(VenueReservationList);