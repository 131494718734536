import AsyncStorage from '@react-native-async-storage/async-storage';
import {
  APP_LOADING,
  CURRENT_ROUTE_NAME,
  CURRENT_SELECTED_ROUTE,
  NOTIFICATION_ROUTE,

  // City
  FETCH_ALL_CITY_IDLE,
  FETCH_ALL_CITY_PENDING,
  FETCH_ALL_CITY_FAILED,
  FETCH_ALL_CITY_SUCCEEDED,

  // State
  FETCH_ALL_STATE_IDLE,
  FETCH_ALL_STATE_PENDING,
  FETCH_ALL_STATE_FAILED,
  FETCH_ALL_STATE_SUCCEEDED,

  // Country
  FETCH_ALL_COUNTRY_IDLE,
  FETCH_ALL_COUNTRY_PENDING,
  FETCH_ALL_COUNTRY_FAILED,
  FETCH_ALL_COUNTRY_SUCCEEDED,
} from '../constants';

/* ----- INITIAL STATE ----- */
const initialState = {
  isAppLoading: false,
  currentAppState: '',
  currentTabIndex: 0,
  notificationRoute: {
    route: "",
    isNotify: false,
    routename: ""
  }
};

/* ----- REDUCER ----- */
const commonReducer = (state = initialState, action) => {
  switch (action.type) {

    case APP_LOADING:
      return {
        ...state,
        isAppLoading: action.payload
      };

    case CURRENT_ROUTE_NAME:
      return {
        ...state,
        currentAppState: action.payload
      }

    case CURRENT_SELECTED_ROUTE:
      return {
        ...state,
        currentTabIndex: action.payload
      }
    case NOTIFICATION_ROUTE:
      AsyncStorage.setItem('common', JSON.stringify(action.payload))
      return {
        ...state,
        notificationRoute: action.payload
      }

    default:
      return state;

  }
}

/* -----  GET COUNTRY INITIAL STATE ----- */
const countryInitialState = {
  countryLoading: false,
  countryIdleErr: null,
  countryErr: null,
  countryResponse: [],
  countryLookUp: [],
}

/* ----- GET COUNTRY REDUCER ----- */
const CountryLookUpReducer = (state = countryInitialState, action) => {
  switch (action.type) {

    case FETCH_ALL_COUNTRY_PENDING:
      return {
        ...state,
        countryLoading: true,
        countrycountryResponse: [],
        countryLookUp: [],
      };
    case FETCH_ALL_COUNTRY_IDLE:
      return {
        ...state,
        countryLoading: false,
        countryIdleErr: action.payload
      };
    case FETCH_ALL_COUNTRY_SUCCEEDED: {
      let LookUpArr = action.payload && action.payload.length > 0 ? action.payload : [];
      LookUpArr = LookUpArr.map(e => { return { value: e.id, label: e.description, code: e.code } })
      return {
        ...state,
        countryLoading: false,
        countryResponse: action.payload,
        countryLookUp: LookUpArr,
      };
    }
    case FETCH_ALL_COUNTRY_FAILED:
      return {
        ...state,
        countryLoading: false,
        countryErr: action.payload
      };
    default:
      return state;
  }
}

/* -----  GET STATE INITIAL STATE ----- */
const stateInitialState = {
  stateLoading: false,
  stateIdleErr: null,
  stateErr: null,
  stateResponse: [],
  stateLookUp: [],
}

/* ----- GET STATE REDUCER ----- */
const stateLookUpReducer = (state = stateInitialState, action) => {
  switch (action.type) {

    case FETCH_ALL_STATE_PENDING:
      return {
        ...state,
        stateLoading: true,
        stateResponse: [],
        stateLookUp: [],
      };
    case FETCH_ALL_STATE_IDLE:
      return {
        ...state,
        stateLoading: false,
        stateIdleErr: action.payload
      };
    case FETCH_ALL_STATE_SUCCEEDED: {
      let LookUpArr = action.payload && action.payload.length > 0 ? action.payload : [];
      LookUpArr = LookUpArr.map(e => { return { value: e.id, label: e.description, code: e.code, countrycode: e.countrycode } })
      return {
        ...state,
        stateLoading: false,
        stateResponse: action.payload,
        stateLookUp: LookUpArr,
      };
    }
    case FETCH_ALL_STATE_FAILED:
      return {
        ...state,
        stateLoading: false,
        stateErr: action.payload
      };
    default:
      return state;
  }
}

/* -----  GET CITY INITIAL STATE ----- */
const cityInitialState = {
  cityLoading: false,
  cityIdleErr: null,
  cityErr: null,
  cityResponse: [],
  cityLookUp: [],
}

/* ----- GET CITY REDUCER ----- */
const cityLookUpReducer = (state = cityInitialState, action) => {
  switch (action.type) {

    case FETCH_ALL_CITY_PENDING:
      return {
        ...state,
        cityLoading: true,
        cityResponse: [],
        cityLookUp: [],
      };
    case FETCH_ALL_CITY_IDLE:
      return {
        ...state,
        cityLoading: false,
        cityIdleErr: action.payload
      };
    case FETCH_ALL_CITY_SUCCEEDED: {
      let LookUpArr = action.payload && action.payload.length > 0 ? action.payload : [];
      LookUpArr = (LookUpArr || []).map(e => {
        return {
          value: e.id,
          label: e.description,
          code: e.code,
          statecode: e.statecode
        }
      });

      return {
        ...state,
        cityLoading: false,
        cityResponse: action.payload,
        cityLookUp: (LookUpArr || [])?.sort(GetSortOrder("label")),
      };
    }
    case FETCH_ALL_CITY_FAILED:
      return {
        ...state,
        cityLoading: false,
        cityErr: action.payload
      };
    default:
      return state;
  }
}

function GetSortOrder(prop) {
  return function (a, b) {
    if (a[prop] > b[prop]) {
      return 1;
    } else if (a[prop] < b[prop]) {
      return -1;
    }

    return 0;
  }
}

export {
  commonReducer,
  CountryLookUpReducer,
  stateLookUpReducer,
  cityLookUpReducer
};