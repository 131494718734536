import axios from 'axios';

// Init :: Config
import { API_URL } from '../../config';

// Redux :: Action Types
import {
  FORGOT_PASSWORD_PENDING,
  FORGOT_PASSWORD_IDLE,
  FORGOT_PASSWORD_FAILED,
  FORGOT_PASSWORD_SUCCEEDED,
  OTP_IDLE,
  OTP_PENDING,
  OTP_FAILED,
  OTP_SUCCEEDED,
  RESET_PASSWORD_IDLE,
  RESET_PASSWORD_PENDING,
  RESET_PASSWORD_FAILED,
  RESET_PASSWORD_SUCCEEDED,
} from '../constants';

// API Endpoints
import * as API_Interface from '../../services/apiURLs';

// Other Redux Actions
import { setAppLoading } from '../actions/common';

const forgotPassword = (data) => async (dispatch) => {
  dispatch(setAppLoading(true));
  dispatch({ type: FORGOT_PASSWORD_PENDING, payload: true });
  dispatch({ type: FORGOT_PASSWORD_IDLE, payload: null });
  dispatch({ type: FORGOT_PASSWORD_FAILED, payload: null });
  dispatch({ type: FORGOT_PASSWORD_SUCCEEDED, payload: null });

  try {
    const response = await axios({
      method: "POST",
      baseURL: API_URL,
      url: API_Interface.FORGOT_PASSWORD_URL,
      data: data,
    }).then((response) => response);
    if (response.status !== 200) {
      dispatch(setAppLoading(false));
      dispatch({ type: FORGOT_PASSWORD_IDLE, payload: response });
    } else {
      await dispatch({ type: FORGOT_PASSWORD_SUCCEEDED, payload: response?.data });
    }
    dispatch({ type: FORGOT_PASSWORD_PENDING, payload: false });
    dispatch(setAppLoading(false));

    return response;
  } catch (exception) {
    dispatch(setAppLoading(false));
    dispatch({ type: FORGOT_PASSWORD_FAILED, payload: exception });
    dispatch({ type: FORGOT_PASSWORD_PENDING, payload: false });
  }
}

const OTP = (data) => async (dispatch) => {
  dispatch(setAppLoading(true));
  dispatch({ type: OTP_PENDING, payload: true });
  dispatch({ type: OTP_IDLE, payload: null });
  dispatch({ type: OTP_FAILED, payload: null });
  dispatch({ type: OTP_SUCCEEDED, payload: null });

  try {
    const response = await axios({
      method: "GET",
      baseURL: API_URL,
      url: `${API_Interface.OTP_URL}`,
      params: data,
    }).then((response) => response)
      .catch((error) => error.response)

    console.log('url', `${API_Interface.OTP_URL}`);
    console.log('data', data);
    console.log('response', response);

    if (response.status !== 200) {
      dispatch(setAppLoading(false));
      dispatch({ type: OTP_IDLE, payload: response });
    } if (response.status == 417) {
      await dispatch({ type: OTP_FAILED, payload: response.data });
    } else {
      await dispatch({ type: OTP_SUCCEEDED, payload: response.data });
    }
    dispatch({ type: OTP_PENDING, payload: false });
    dispatch(setAppLoading(false));
    return response;
  } catch (exception) {
    dispatch(setAppLoading(false));
    dispatch({ type: OTP_FAILED, payload: exception });
    dispatch({ type: OTP_PENDING, payload: false });
  }
}


const resetPassword = (data) => async (dispatch) => {
  dispatch(setAppLoading(true));
  dispatch({ type: RESET_PASSWORD_PENDING, payload: true });
  dispatch({ type: RESET_PASSWORD_IDLE, payload: null });
  dispatch({ type: RESET_PASSWORD_FAILED, payload: null });
  dispatch({ type: RESET_PASSWORD_SUCCEEDED, payload: null });

  try {
    const response = await axios({
      method: "POST",
      baseURL: API_URL,
      url: `${API_Interface.RESET_PASSWORD_URL}`,
      data: data,
    }).then((response) => response)
      .catch((error) => error.response)
    if (response.status !== 200) {
      dispatch(setAppLoading(false));
      dispatch({ type: RESET_PASSWORD_IDLE, payload: response });
    } if (response.status == 417) {
      await dispatch({ type: RESET_PASSWORD_FAILED, payload: response.data });
    } else {
      await dispatch({ type: RESET_PASSWORD_SUCCEEDED, payload: response.data });
    }
    dispatch({ type: RESET_PASSWORD_PENDING, payload: false });
    dispatch(setAppLoading(false));
    return response;
  } catch (exception) {
    dispatch(setAppLoading(false));
    dispatch({ type: RESET_PASSWORD_FAILED, payload: exception });
    dispatch({ type: RESET_PASSWORD_PENDING, payload: false });
  }
}

export {
  forgotPassword,
  OTP,
  resetPassword,
}