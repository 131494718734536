import { COUNTER_CHANGE } from '../constants';

/* ----- INITIAL STATE ----- */
const initialState = {
	count: 0
};

/* ----- REDUCER ----- */
const countReducer = (state = initialState, action) => {
	switch (action.type) {

		case COUNTER_CHANGE:
			return {
				...state,
				count: action.payload
			};

		default:
			return state;

	}
}

export default countReducer;