/* eslint-disable react-native/no-raw-text */
import React, { useState, useEffect } from 'react';
import { Image, StyleSheet } from 'react-native';
import { Button, Center, Box } from 'native-base';
import * as ImagePicker from 'expo-image-picker';
import PropTypes from 'prop-types';

//Redux :: Components
import { ImagePlaceholder } from '../templates';

const ImageUpload = (props) => {
	const { isEdit, type, id, UploadImage, imageuri } = props;
	const [image, setImage] = useState("");

	useEffect(() => {
		if (imageuri) {
			setImage(imageuri || "");
		}
	}, [imageuri]);

	const pickImage = async (e) => {
			const data = new FormData();
			let result = await ImagePicker.launchImageLibraryAsync({
				mediaTypes: ImagePicker.MediaTypeOptions.Images = "Images",
				allowsEditing: true,
				aspect: [4, 3],
				quality: 1,
			});
			data.append('ImageUpload', result.uri);
			if (!result.cancelled) {
				setImage(result.uri);
				props.onChange(result?.uri);
			}
	};

	return <Center>
		{image ? <Box
			w="320"
			h="200"
			bg="muted.400"
			mb="2"
			mt="3"
			borderRadius="10"
			display="flex"
			alignItems="center"
			justifyContent="center"
			shadow={2}
			_text={{
				fontSize: "md",
				fontWeight: "bold",
				color: "white"
			}}
		>
			<Image source={{ uri: image }} alt="uploadImage" style={Customstyles.uploadImage} />
		</Box> : <ImagePlaceholder placeholderText={type === "map" ? "File Preview" : "Logo Preview"} />}
		{isEdit && <Button bg="#fad295" w="150" small onPress={pickImage} _text={{ fontWeight: 'bold' }}>{type === "map" ? "Upload File" : "Upload Image"}</Button>}
	</Center>
}

ImageUpload.propTypes = {
	isEdit: PropTypes.bool,
	onChange: PropTypes.func
}

const Customstyles = StyleSheet.create({
	uploadImage: {
		width: 320,
		height: 200,
		borderRadius: 15,
		resizeMode: 'contain'
	}
});


export default (ImageUpload);