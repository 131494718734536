/* eslint-disable no-unused-vars */
import axios from 'axios';

// Init :: Config
import { API_URL } from '../../config';

// API Endpoints
import * as API_Interface from '../../services/apiURLs';

// Redux :: Action Types
import {
  PRODUCT_IDLE,
  PRODUCT_PENDING,
  PRODUCT_FAILED,
  PRODUCT_SUCCEEDED,

  //ProductCategory
  PRODUCT_CATEGORY_IDLE,
  PRODUCT_CATEGORY_PENDING,
  PRODUCT_CATEGORY_FAILED,
  PRODUCT_CATEGORY_SUCCEEDED,

  INSERT_UPDATE_PRODUCT_CATEGORY_IDLE,
  INSERT_UPDATE_PRODUCT_CATEGORY_PENDING,
  INSERT_UPDATE_PRODUCT_CATEGORY_FAILED,
  INSERT_UPDATE_PRODUCT_CATEGORY_SUCCEEDED,

  INSERT_UPDATE_PRODUCT_IDLE,
  INSERT_UPDATE_PRODUCT_PENDING,
  INSERT_UPDATE_PRODUCT_FAILED,
  INSERT_UPDATE_PRODUCT_SUCCEEDED,

  GET_PRODUCT_CATEGORY_BY_ID_IDLE,
  GET_PRODUCT_CATEGORY_BY_ID_PENDING,
  GET_PRODUCT_CATEGORY_BY_ID_FAILED,
  GET_PRODUCT_CATEGORY_BY_ID_SUCCEEDED,

  DELETE_PRODUCT_BY_ID_IDLE,
  DELETE_PRODUCT_BY_ID_PENDING,
  DELETE_PRODUCT_BY_ID_FAILED,
  DELETE_PRODUCT_BY_ID_SUCCEEDED,
} from '../constants';

// Other Redux Actions
import { setAppLoading } from '../actions/common';

const FetchProductList = () => async (dispatch) => {
  dispatch({ type: PRODUCT_PENDING, payload: true });
  try {
    const response = await axios({
      method: "GET",
      baseURL: API_URL,
      url: `${API_Interface.GET_ALL_PRODUCT}`,
    }).then((response) => response)
      .catch((error) => error.response)
    if (response.status !== 200) {
      dispatch({ type: PRODUCT_IDLE, payload: response });
    }
    else if (response.status == 417) {

      await dispatch({ type: PRODUCT_FAILED, payload: response.data });
    } else {

      await dispatch({ type: PRODUCT_SUCCEEDED, payload: response.data });
    }
    return response;
  } catch (exception) {
    dispatch({ type: PRODUCT_FAILED, payload: exception });
  }
}

const FetchProduct = (categoryId) => async (dispatch) => {
  dispatch({ type: PRODUCT_CATEGORY_PENDING, payload: true });
  try {
    const response = await axios({
      method: "GET",
      baseURL: API_URL,
      url: `${API_Interface.GET_PRODUCT_DETAIL}` + categoryId,
    }).then((response) => response)
      .catch((error) => error.response)
    if (response.status !== 200) {
      dispatch({ type: PRODUCT_CATEGORY_IDLE, payload: response });
    }
    else if (response.status == 417) {
      await dispatch({ type: PRODUCT_CATEGORY_FAILED, payload: response.data });
    } else {
      await dispatch({ type: PRODUCT_CATEGORY_SUCCEEDED, payload: response.data });
    }
    return response;
  } catch (exception) {
    dispatch({ type: PRODUCT_CATEGORY_FAILED, payload: exception });
  }
}

const InsertUpdateProductCategory = (data) => async (dispatch) => {
  dispatch(setAppLoading(true));
  dispatch({ type: INSERT_UPDATE_PRODUCT_CATEGORY_PENDING, payload: true });
  try {
    const response = await axios({
      method: "POST",
      baseURL: API_URL,
      url: API_Interface.INSERT_UPDATE_PRODUCT_CATEGORY,
      data: data,
    }).then((response) => response)
      .catch((error) => error.response)
    if (response.status !== 200) {
      dispatch({ type: INSERT_UPDATE_PRODUCT_CATEGORY_IDLE, payload: response });
      dispatch(setAppLoading(false));
    }
    else if (response.status == 417) {
      await dispatch({ type: INSERT_UPDATE_PRODUCT_CATEGORY_FAILED, payload: response.data });
      dispatch(setAppLoading(false));
    } else {
      await dispatch({ type: INSERT_UPDATE_PRODUCT_CATEGORY_SUCCEEDED, payload: response.data });
      dispatch(setAppLoading(false));
    }
    return response;
  } catch (exception) {
    dispatch({ type: INSERT_UPDATE_PRODUCT_CATEGORY_FAILED, payload: exception });
    dispatch(setAppLoading(false));
  }
}

const DeleteProductCategory = (categoryID) => async (dispatch) => {
  try {
    const response = await axios({
      method: "POST",
      baseURL: API_URL,
      url: `${API_Interface.DELETE_PRODUCT_CATEGORY}/${categoryID}`
    }).then((response) => response)
      .catch((error) => error.response);
    return response;
  } catch (exception) {
    console.log(exception);
  }
}

const InsertUpdateProduct = (data) => async (dispatch) => {
  dispatch({ type: INSERT_UPDATE_PRODUCT_PENDING, payload: true });
  try {
    const response = await axios({
      method: "POST",
      baseURL: API_URL,
      url: API_Interface.INSERT_UPDATE_PRODUCT,
      data: data,
    }).then((response) => response)
      .catch((error) => error.response)
    if (response.status !== 200) {
      dispatch({ type: INSERT_UPDATE_PRODUCT_IDLE, payload: response });
    }
    else if (response.status == 417) {
      await dispatch({ type: INSERT_UPDATE_PRODUCT_FAILED, payload: response.data });
    } else {
      await dispatch({ type: INSERT_UPDATE_PRODUCT_SUCCEEDED, payload: response.data });
    }
    return response;
  } catch (exception) {
    dispatch({ type: INSERT_UPDATE_PRODUCT_FAILED, payload: exception });
  }
}

const GetProductCategoryById = (categoryId) => async (dispatch) => {
  dispatch({ type: GET_PRODUCT_CATEGORY_BY_ID_PENDING, payload: true });
  try {
    const response = await axios({
      method: "GET",
      baseURL: API_URL,
      url: `${API_Interface.GET_PRODUCT_CATEGORY_BY_ID}` + categoryId,
    }).then((response) => response)
      .catch((error) => error.response)
    if (response.status !== 200) {
      dispatch({ type: GET_PRODUCT_CATEGORY_BY_ID_IDLE, payload: response });
    }
    else if (response.status == 417) {
      await dispatch({ type: GET_PRODUCT_CATEGORY_BY_ID_FAILED, payload: response.data });
    } else {
      await dispatch({ type: GET_PRODUCT_CATEGORY_BY_ID_SUCCEEDED, payload: response.data });
    }
    return response;
  } catch (exception) {
    dispatch({ type: GET_PRODUCT_CATEGORY_BY_ID_FAILED, payload: exception });
  }
}
const DeleteProduct = (id) => async (dispatch) => {
  dispatch({ type: DELETE_PRODUCT_BY_ID_PENDING, payload: true });
  try {
    const response = await axios({
      method: "POST",
      baseURL: API_URL,
      url: API_Interface.DELETE_PRODUCT_BY_ID+id,
    }).then((response) => response)
      .catch((error) => error.response)
    if (response.status !== 200) {
      dispatch({ type: DELETE_PRODUCT_BY_ID_IDLE, payload: response });
    }
    else if (response.status == 417) {
      await dispatch({ type: DELETE_PRODUCT_BY_ID_FAILED, payload: response.data });
    } else {
      await dispatch({ type: DELETE_PRODUCT_BY_ID_SUCCEEDED, payload: response.data });
    }
    return response;
  } catch (exception) {
    dispatch({ type: DELETE_PRODUCT_BY_ID_FAILED, payload: exception });
  }
}


export {
  FetchProductList,
  FetchProduct,
  InsertUpdateProductCategory,
  InsertUpdateProduct,
  GetProductCategoryById,
  DeleteProduct,
  DeleteProductCategory
}
