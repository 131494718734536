import React from "react";
import { Spinner, HStack, Heading, Center, Text } from "native-base";

// Utils
import { primaryColors } from '../utils/colors';

// Stylesheet
import { RootStyles } from '../styles'

const SpinnerLoading = () => <Center flex={1} px="3" style={RootStyles.spinnerLoaderContainer}>
  <HStack space={2} alignItems="center">
    <Spinner accessibilityLabel="Loading..." color={primaryColors?.primaryRed} size="lg" />
    <Heading color={primaryColors?.primaryRed} fontSize="lg"><Text>Loading</Text></Heading>
  </HStack>
</Center>;

export { 
  SpinnerLoading 
};