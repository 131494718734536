/* eslint-disable react-native/no-raw-text */
import React, { useEffect, useState } from "react";
import { ScrollView } from 'react-native';
import { Flex, Icon, Box, Button, Divider, Heading, HStack } from "native-base";
import { connect } from 'react-redux';
import { FontAwesome } from '@expo/vector-icons';
import PropTypes from 'prop-types';

// Redux :: Actions
import * as TableActions from '../../../shared/redux/actions/Table';
import * as VenueActions from '../../../shared/redux/actions/venue';
import * as CommonActions from '../../../shared/redux/actions/common';

//Redux :: Components
import Linking from '../../../shared/templates/customLinking';
import { SpinnerLoading } from '../../../shared/templates/SpinnerLoading';
import { ImageUpload } from '../../../shared/templates';
import VenueTable from './venueTable';
import VenueCategory from './venueCategory';
import VenueWeekView from './venueWeekView';
import AddOrEditReservationDate from './addOrEditReservationDate';

/* ----- UTILS ----- */
import { primaryColors } from '../../../shared/utils/colors';
import VenueDeposits from "./VenueDeposits";

const VenueWebsiteOperations = (props) => {
  const { type, route, GetTableDetailsById, venueDetail, FetchCity, FetchState, GetVenueDetailsById } = props;

  const [isReady] = useState(true);
  const [TableId, setTableId] = useState(0);
  const [VenueId, setVenueId] = useState(0);
  const [venueID, setVenueID] = useState(0);

  useEffect(() => {
    if (route?.params) {
      const { id } = route.params;
      setVenueID(id || 0);
    }
  }, [route?.params]);

  useEffect(() => {
    if (venueID && venueID > 0) {
      loadVenueDetail();
      loadCityLookup();
      loadStateLookup();
    }
  }, [venueID]);

  const loadVenueDetail = async () => {
    await GetVenueDetailsById(venueID || 0).then((result) => { return result; });
  }

  const loadCityLookup = async () => {
    await FetchCity().then((result) => { return result; });
  }

  const loadStateLookup = async () => {
    await FetchState().then((result) => { return result; });
  }

  useEffect(() => {
    loadTableDetail();
  }, [TableId]);

  const loadTableDetail = async () => {
    if (TableId && TableId > 0) {
      await GetTableDetailsById(TableId || 0).then((result) => { return result; });
    }
  }

  const redirectURL = (redirectBaseURL, isTargetBlank) => {
    if (isTargetBlank) {
      Linking.openURL(redirectBaseURL, '_blank')
    } else {
      Linking.openURL(redirectBaseURL)
    }
  }

  return <ScrollView contentContainerStyle={{ flexGrow: 1, backgroundColor: 'white' }}>
    {
      isReady ?
        <>

          <Flex flexDirection="row" alignItems="center" justifyContent="space-between" my="1" px="6" w={"100%"} mt={6}>
            <Button
              size="sm"
              leftIcon={<Icon as={FontAwesome} name="chevron-circle-left" size="6" color="info.600" />}
              onPress={() => redirectURL(`/Venue/${venueID}`, false)}
              bg="transparent"
              _text={{
                color: "info.600",
                fontSize: 15
              }}
              colorScheme='info.600'
            >
              Back to Venue Details
            </Button>
          </Flex>

          {venueID ?
            <Box
              mt="1"
              px="8"
              py="2"
              bg="#000"
              _text={{
                fontSize: "xl",
                fontWeight: "medium",
                color: primaryColors.primaryRed,
                letterSpacing: "lg"
              }}
            >
              {venueDetail?.venueName}
            </Box>
            :
            <Box px="8">
              <Heading size='lg'>New Table</Heading>
            </Box>
          }
          
          <Box mx={8} pb="4">
            <Flex flexDirection="row" alignItems="flex-start">
              <Box w="80%">
                <Box pb="4">
                  <VenueDeposits
                     venueId={venueID || 0}
                  />
                  <Divider my="3" bg="muted.400" w={"80%"} /> 
                </Box>
                <Box pb="4">
                  <VenueTable
                    venueId={VenueId}
                    navigation={props.navigation}
                  />
                  <Divider my="3" bg="muted.400" w={"80%"} />
                </Box>
                <Box pb="4">
                  <VenueCategory
                    venueId={VenueId}
                    viewType="CATEGORY_IN_WEBSITE_OPERATIONS_VIEW"
                    isCategoryAccordion={true}
                  />
                  <Divider my="3" bg="muted.400" w={"80%"} />
                </Box>
              </Box>
              <Box w="15%" pt={5}>
                <ImageUpload
                  imageuri={venueDetail?.imagePath}
                  isEdit={true}
                />
              </Box>
            </Flex>
          </Box>

          <Box mx={8} pb="4">
            <AddOrEditReservationDate
              venueId={VenueId}
              navigation={props.navigation}
            />
          </Box>

          <Box mx={8} pb="4">
            <VenueWeekView
              venueId={VenueId}
              navigation={props.navigation}
            />
          </Box>

        </>
        :
        <Flex flexDirection="row" alignItems="center" my="4" px={!type ? "8" : "0"} w="90%" mt={6} mh="100%">
          <SpinnerLoading />
        </Flex>
    }

  </ScrollView>
}

const mapStateToProps = (state) => {
  const { TableDetailResponse, TableDetailLoading } = state.venue;
  const { loginResponse } = state.auth;
  const { productCategoryLoading, VenuesDetailResponse } = state.venue;

  return {
    TableList: TableDetailResponse || [],
    loginResponse: loginResponse || "",
    loader: TableDetailLoading,
    productCategoryLoading,
    venueDetail: VenuesDetailResponse || []
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    GetVenueDetailsById: (venueId) => dispatch(VenueActions.GetVenueDetailsById(venueId)).then((response) => response),
    GetTableDetailsById: (TableId) => dispatch(TableActions.GetTableDetailsById(TableId)).then((response) => response),
    PostTable: (postreq) => dispatch(TableActions.PostTable(postreq)).then((response) => response),
    GetVenueTableByVenueId: (venueId) => dispatch(VenueActions.GetVenueTableByVenueId(venueId)).then((response) => response),
    FetchCity: () => dispatch(CommonActions.FetchCity()).then((response) => response),
    FetchState: () => dispatch(CommonActions.FetchState()).then((response) => response),
  }
}

VenueWebsiteOperations.propTypes = {
  loginResponse: PropTypes.any,
  PostTable: PropTypes.func,
  navigation: PropTypes.any,
  TableList: PropTypes.any,
  stateLookUp: PropTypes.any,
  productCategoryLoading: PropTypes.bool,
  venueDetail: PropTypes.any,
  route: PropTypes.any,
}

export default connect(mapStateToProps, mapDispatchToProps)(VenueWebsiteOperations);