/* eslint-disable react-native/no-inline-styles */
/* eslint-disable react-native/no-color-literals */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react-native/no-raw-text */
import React, { useEffect, useState } from "react";
import { StyleSheet, ScrollView } from 'react-native';
import { Flex, Input, TextArea, Icon, FormControl, Box, Center, Fade, Button, useToast, Heading, Divider, Select, Switch } from "native-base";
import Moment from "moment";
import { Ionicons } from "@expo/vector-icons";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Redux :: Actions
import * as TableActions from '../../../shared/redux/actions/Table';
import * as VenueActions from '../../../shared/redux/actions/venue';

//Redux :: Components
import Linking from '../../../shared/templates/customLinking';
import { SpinnerLoading } from '../../../shared/templates/SpinnerLoading';
import { ImageUpload } from '../../../shared/templates';

const AddOrEditTable = (props) => {

  const {
    PostTable,
    tableDetail,
    loader,
    route,
    type,
    GetTableDetailsById,
    venueDetail,
    GetVenueDetailsById,
  } = props;

  const toast = useToast();
  const [isReady] = useState(true);
  const [tableId, setTableId] = useState(0);
  const [VenueId, setVenueId] = useState(0);
  const [TableName, setTableName] = useState("");
  const [TablePrice1, setTablePrice1] = useState("");
  const [TablePrice2, setTablePrice2] = useState("");
  const [TablePrice3, setTablePrice3] = useState("");
  const [TablePrice4, setTablePrice4] = useState("");
  const [TableCount, setTableCount] = useState("");
  const [TableDescription, setTableDescription] = useState("");
  const [TableImagePath, setTableImagePath] = useState("");
  const [isActive, setActive] = useState("0");
  const [venueName, setVenueName] = useState("");

  useEffect(() => {
    if (route?.params) {
      const { id, tableid } = route.params;
      setVenueId(id || 0);
      setTableId(tableid || 0);

      GetVenueDetailsById(id);
    }
  }, [route?.params]);

  useEffect(() => {
    if (tableDetail?.tableid) {
      setTableName(tableDetail?.tableName);

      let rateTierList = (tableDetail?.tableRateTier || [])
        ?.sort((a, b) => a.ratetierid - b.ratetierid);
        
      setTablePrice1(rateTierList?.[0]?.minimumSpendAmount);
      setTablePrice2(rateTierList?.[1]?.minimumSpendAmount);
      setTablePrice3(rateTierList?.[2]?.minimumSpendAmount);
      setTablePrice4(rateTierList?.[3]?.minimumSpendAmount);
      setTableCount(tableDetail?.count);
      setTableDescription(tableDetail?.description);
      setTableImagePath(tableDetail?.imagePath);
      setActive(tableDetail?.status);
      setVenueName(tableDetail?.venueName);
    }
  }, [tableDetail]);

  useEffect(() => {
    setVenueName(venueDetail?.venueName);
  }, [venueDetail]);

  useEffect(() => {
    loadTableDetail();
  }, [tableId])

  const loadTableDetail = async () => {
    if (tableId && tableId > 0) {
      await GetTableDetailsById(tableId || 0).then((result) => { return result; });
    }
  }

  const redirectURL = (redirectBaseURL, isTargetBlank) => {
    if (isTargetBlank) {
      Linking.openURL(redirectBaseURL, '_blank')
    } else {
      Linking.openURL(redirectBaseURL)
    }
  }

  const HandleSubmit = async () => {
    const { loginResponse } = props;

    toast.closeAll();

    if (loginResponse && loginResponse !== "") {
      let param = {
        Tableid: tableId || 0,
        Venueid: VenueId || "",
        TableName: TableName || "",
        count: TableCount || 0,
        MinimumSpendAmount: TablePrice1 || "",
        Status: isActive || "0",
        LastModifiedDate: Moment().format("YYYY-MM-DD[T]HH:mm:ss"),
        VenueName: venueName,
        ImagePath: TableImagePath || "",
        Description: TableDescription || "",
        tableratetier: [
          { ratetierid: 1, minimumSpendAmount: TablePrice1 },
          { ratetierid: 2, minimumSpendAmount: TablePrice2 },
          { ratetierid: 3, minimumSpendAmount: TablePrice3 },
          { ratetierid: 4, minimumSpendAmount: TablePrice4 }
        ]
      };

      const PostTable_Response = await PostTable(param).then((response) => response);
      if (PostTable_Response?.data?.status === "Success") {
        toast.show({
          render: () => <Box bg="emerald.500" px="2" py="1" rounded="sm" mb={5}>
            {tableId && tableId > 0 ? "Table Updated Successfully" : "Table Added Successfully!"}
          </Box>
        });

        redirectURL(`/VenueWebsiteOperations/${VenueId}`, false)
      } else {
        toast.show({
          render: () => <Box bg="error.600" px="2" py="1" rounded="sm" mb={5}>Failed! Please try again later</Box>
        });
      }
    } else {
      toast.show({
        render: () => <Box bg="error.600" px="2" py="1" rounded="sm" mb={5}>Please fill all required fields</Box>
      });
    }
  }

  const CancelSubmit = () => redirectURL(`/VenueWebsiteOperations/${VenueId}`, false);

  return <ScrollView contentContainerStyle={{ flexGrow: 1, backgroundColor: 'white' }}>
    {
      isReady ?
        <Fade in={isReady}>
          <Flex flexDirection="row" alignItems="center" justifyContent="space-between" my="3" w={"100%"} mt={6}>
            {
              tableId ?
                <Box px="8" pt="4">
                  <Heading size='lg'>Table Information</Heading>
                </Box>
                :
                <Box px="8" pt="4">
                  <Heading size='lg'>New Table</Heading>
                </Box>
            }
          </Flex>
          <Box px="8">
            <Divider my="3" bg="muted.400" />
          </Box>
          <Flex flexDirection="row" alignItems="flex-start">
            <Box w="75%">
              <Flex flexDirection="row" alignItems="center" px={!type ? "8" : "0"} w="100%" mt={6}>
                <Box style={styles.inputFieldWrapper}>
                  <FormControl
                    isInvalid={false}
                    isRequired
                  >
                    <FormControl.Label>Table Name</FormControl.Label>
                    <Input
                      variant="filled"
                      w="500"
                      value={TableName || ""}
                      onChange={(event) => setTableName(event.target.value)}
                      selectionColor="white"
                    />
                  </FormControl>
                </Box>

              </Flex>
              <Flex flexDirection="row" alignItems="center" px={!type ? "8" : "0"} w="100%" mt={6}>
                <Box style={styles.inputFieldWrapper}>
                  <FormControl
                    isInvalid={false}
                    isRequired
                  >
                    <FormControl.Label>Table Price Tier1</FormControl.Label>
                    <Input
                      variant="filled"
                      w="500"
                      value={TablePrice1 || ""}
                      onChange={(event) => setTablePrice1(event.target.value)}
                      selectionColor="white"
                    />
                  </FormControl>
                </Box>
              </Flex>
              <Flex flexDirection="row" alignItems="center" px={!type ? "8" : "0"} w="100%" mt={6}>
                <Box style={styles.inputFieldWrapper}>
                  <FormControl
                    isInvalid={false}
                    isRequired
                  >
                    <FormControl.Label>Table Price Tier2</FormControl.Label>
                    <Input
                      variant="filled"
                      w="500"
                      value={TablePrice2 || ""}
                      onChange={(event) => setTablePrice2(event.target.value)}
                      selectionColor="white"
                    />
                  </FormControl>
                </Box>
              </Flex>

              <Flex flexDirection="row" alignItems="center" px={!type ? "8" : "0"} w="100%" mt={6}>
                <Box style={styles.inputFieldWrapper}>
                  <FormControl
                    isInvalid={false}
                    isRequired
                  >
                    <FormControl.Label>Table Price Tier3</FormControl.Label>
                    <Input
                      variant="filled"
                      w="500"
                      value={TablePrice3 || ""}
                      onChange={(event) => setTablePrice3(event.target.value)}
                      selectionColor="white"
                    />
                  </FormControl>
                </Box>
              </Flex>
              <Flex flexDirection="row" alignItems="center" px={!type ? "8" : "0"} w="100%" mt={6}>
                <Box style={styles.inputFieldWrapper}>
                  <FormControl
                    isInvalid={false}
                    isRequired
                  >
                    <FormControl.Label>Table Price Tier4</FormControl.Label>
                    <Input
                      variant="filled"
                      w="500"
                      value={TablePrice4 || ""}
                      onChange={(event) => setTablePrice4(event.target.value)}
                      selectionColor="white"
                    />
                  </FormControl>
                </Box>
              </Flex>
              <Flex flexDirection="row" alignItems="center" px={!type ? "8" : "0"} w="100%" mt={6}>
                <Box style={styles.inputFieldWrapper}>
                  <FormControl
                    isInvalid={false}
                    isRequired
                  >
                    <FormControl.Label>Table Size</FormControl.Label>
                    <Input
                      variant="filled"
                      w="500"
                      value={TableCount || ""}
                      onChange={(event) => setTableCount(event.target.value)}
                      selectionColor="white"
                    />
                  </FormControl>
                </Box>
              </Flex>

              <Flex flexDirection="row" alignItems="center" px={!type ? "8" : "0"} w="100%" mt={6}>
                <Box style={styles.inputFieldWrapper}>
                  <FormControl
                    isInvalid={false}
                    isRequired
                  >
                    <FormControl.Label>Table Description</FormControl.Label>
                    <TextArea
                      h={20}
                      w="500"
                      maxW="500"
                      variant="filled"
                      //w="500"
                      value={TableDescription || ""}
                      onChange={(event) => setTableDescription(event.target.value)}
                    />
                  </FormControl>
                </Box>
              </Flex>

              {!(tableId && tableId > 0) && <Flex flexDirection="row" alignItems="center" px={!type ? "8" : "0"} w="100%" mt={6}>
                <Box style={styles.inputFieldWrapper}>
                  <FormControl  >
                    <FormControl.Label>Active    <Switch
                      colorScheme="emerald"
                      isChecked={isActive == "1" || false} onToggle={(value) => { console.log(value ? "1" : "0", 'getValue'); setActive(value ? "1" : "0") }}
                    />
                    </FormControl.Label>
                  </FormControl>
                </Box>
              </Flex>
              }

            </Box>
            <Box w="20%">
              <Center mt="30">
                <ImageUpload
                  imageuri={TableImagePath}
                  isEdit={true}
                  id={tableId}
                  component='Table'
                  onChange={(path) => {
                    setTableImagePath(path)
                  }}

                />
              </Center>
            </Box>
          </Flex>

          <Flex flexDirection="row" alignItems="flex-start" px={!type ? "8" : "0"} w="100%" mb={10} mt={6}>
            <Button
              mt="2"
              mr="2"
              bgColor="#fad295"
              color="#000000"
              w="100"
              leftIcon={<Icon as={Ionicons} name="ios-save" size="xs" />}
              _text={{
                color: "#000000"
              }}
              disabled={loader || false}
              onPress={HandleSubmit}
            >
              Save
            </Button>
            <Button
              mt="2"
              bgColor="#fc1a17"
              color="primary.50"
              w="100"
              leftIcon={<Icon as={Ionicons} name="ios-close" size="xs" />}
              onPress={CancelSubmit}
            >
              Cancel
            </Button>
          </Flex>

        </Fade>
        :
        <Flex flexDirection="row" alignItems="center" my="4" px={!type ? "8" : "0"} w="90%" mt={6} mh="100%">
          <SpinnerLoading />
        </Flex>
    }
  </ScrollView>;
}

AddOrEditTable.propTypes = {
  loginResponse: PropTypes.any,
  PostTable: PropTypes.func,
  navigation: PropTypes.any,
  tableDetail: PropTypes.any,
  stateLookUp: PropTypes.any,
  venueDetail: PropTypes.any,
  route: PropTypes.any,
  GetTableDetailsById: PropTypes.func,
  type: PropTypes.any,
  GetVenueDetailsById: PropTypes.func,
}

const styles = StyleSheet.create({
  inputFieldWrapper: {
    width: '20%',
    marginRight: 30
  },
});

const mapStateToProps = (state) => {
  const { TableDetailResponse, TableDetailLoading, VenuesDetailResponse } = state.venue;
  const { loginResponse } = state.auth;

  return {
    tableDetail: TableDetailResponse || [],
    loginResponse: loginResponse || "",
    loader: TableDetailLoading,
    venueDetail: VenuesDetailResponse || []
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    GetTableDetailsById: (tableId) => dispatch(TableActions.GetTableDetailsById(tableId)).then((response) => response),
    PostTable: (postreq) => dispatch(TableActions.PostTable(postreq)).then((response) => response),
    GetVenueTableByVenueId: (venueId) => dispatch(VenueActions.GetVenueTableByVenueId(venueId)).then((response) => response),
    GetVenueDetailsById: (venueId) => dispatch(VenueActions.GetVenueDetailsById(venueId)).then((response) => response),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddOrEditTable);