import React, { useState, useEffect, useRef } from 'react';
import { ScrollView } from 'react-native';
import { Box, Flex, Text, Button, Icon,Divider ,Spacer, useToast } from 'native-base';
import { FontAwesome } from '@expo/vector-icons';
import { connect } from 'react-redux';
import Moment from 'moment';
import PropTypes from 'prop-types';
import 'bootstrap/dist/css/bootstrap.min.css';


/* ----- COMPONENTS ----- */
import Linking from '../../../shared/templates/customLinking';
import VenueReservationList from './venueReservationList';
import VenueReservationDetails from './VenueReservationDetails';
import VenueReservationSummary from './VenueReservationSummary';



/* ----- UTILS ----- */
import { primaryColors } from '../../../shared/utils/colors';

/* ----- REDUX :: ACTIONS ----- */
import * as VenueActions from '../../../shared/redux/actions/venue';
import CacheReport from './CacheReport';



const VenuesReport = (props) => {
  const { route, venueDetail, GetReservationDetailsbyVenues, loginResponse
  } = props;

  const [isReady] = useState(true);
  const [venueID, setVenueID] = useState(0);
  const [tabIndex, setTabIndex] = useState(0);

    const [venueAnalytics, setVenueTabOpen] = useState(true);
    const [Reservation, setReserveTabOpen]=useState(false);
    const [Guest,setGuestTabOpen]=useState(false);
    const [cache,setcacheTabOpen]=useState(false);

  useEffect(() => {
    if (route?.params) {
      const { venueID } = route.params;
      setVenueID(venueID || 0);
    }
  }, [route?.params]);


  const redirectURL = (redirectBaseURL, isTargetBlank) => {
    if (isTargetBlank) {
      Linking.openURL(redirectBaseURL, '_blank')
    } else {
      Linking.openURL(redirectBaseURL)
    }
  }

  const { userRoles } = loginResponse;


  const showAllUserTab = (activeTab,tab) => {
    setVenueTabOpen(false);
    setReserveTabOpen(false);
    setGuestTabOpen(false);
    setcacheTabOpen(false);
    if (activeTab == "venueAnalytics") { 
      setVenueTabOpen(true);
      setTabIndex(tab);
   }
   else if (activeTab == "Reservation") { 
    setReserveTabOpen(true);
    setTabIndex(tab);
   }
   else if (activeTab == "Guest") { 
    setGuestTabOpen(true);
    setTabIndex(tab);
   }
   else if (activeTab == "cache") { 
    setcacheTabOpen(true);
    setTabIndex(tab);
   }
  }

  return <>
    {
      isReady ? <ScrollView contentContainerStyle={{ flexGrow: 1, backgroundColor: 'white' }}>
        {
          (venueID && venueID > 0) ?
            <Box bg="#FFF" pb="10">
              <Flex flexDirection="row" alignItems="center" justifyContent="space-between" my="1" px="6" w={"100%"} mt={6}>
                {
                  !userRoles?.includes("VenueAdmin") && (
                    <Button
                      size="sm"
                      leftIcon={<Icon as={FontAwesome} name="chevron-circle-left" size="6" color="info.600" />}
                      onPress={() => redirectURL(`/Venue/${venueID}`, false)}
                      bg="transparent"
                      _text={{
                        color: "info.600",
                        fontSize: 15
                      }}
                      colorScheme='info.600'
                    >
                      Back to Venue Details
                    </Button>
                  )
                }
              </Flex>
              <br></br>
              <div>
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button type="button"  className={["nav-link" ,venueAnalytics === true ? "active" : ""].join(" ")}  data-bs-toggle="tab" role="tab"  
                     aria-selected="true" onClick={() => showAllUserTab("venueAnalytics",0)}>
                      Venue Analytics
                    </button>
                  </li>

                  <li className="nav-item" role="presentation">
                    <button type="button"  className={["nav-link" ,Reservation === true ? "active" : ""].join(" ")}  data-bs-toggle="tab" role="tab"   
                    aria-selected="false" onClick={() => showAllUserTab("Reservation",1)}>
                      Reservation Report
                    </button>
                  </li>

                  <li className="nav-item" role="presentation">
                    <button  type="button"  className={["nav-link" ,Guest === true ? "active" : ""].join(" ")}  data-bs-toggle="tab" role="tab"   aria-selected="false" 
                    onClick={() => showAllUserTab("Guest",2)}>
                      Guest Summary
                    </button>
                  </li>

                  <li className="nav-item" role="presentation">
                    <button type="button"   className={["nav-link" ,cache === true ? "active" : ""].join(" ")}  data-bs-toggle="tab" role="tab"  aria-selected="false" 
                    onClick={() => showAllUserTab("cache",3)}>
                      Cache Report
                    </button>
                  </li>

                </ul>
              </div>
              <br></br>
              {
                tabIndex == 0 ? <Box px="8" py="2">
                  <VenueReservationList 
                     venueId={venueID || 0}
                  />
                </Box>
                  : tabIndex == 1 ? <Box px="8" py="2">
                     <VenueReservationDetails
                       venueId={venueID || 0}
                    />
                  </Box>
                    : tabIndex == 2 ? <Box px="8" py="2">
                       <VenueReservationSummary
                         venueId={venueID || 0}
                       />
                    </Box>
                      : tabIndex == 3 ? <Box px="8" py="2">
                         <CacheReport
                            venueId={venueID || 0}
                          />
                      </Box>
                        : null
              }

            </Box> : null
        }
      </ScrollView> : null
    }
  </>
};

const mapStateToProps = (state) => {
  const { loginResponse } = state.auth;
  const {
    VenuesDetailLoading,
    VenuesReservationDetailResponse,
  } = state.venue;

  return {
    loginResponse,
    VenuesDetailLoading,
    VenuesReservationDetailResponse
  };
}


const mapDispatchToProps = (dispatch) => {
  return {
    GetReservationDetailsbyVenues: (data) => dispatch(VenueActions.GetReservationDetailsbyVenue(data)).then((response) => response)
  }
}

VenuesReport.propTypes = {
  route: PropTypes.any,
  GetReservationDetailsbyVenues: PropTypes.func,
  GetVenueCacheReportByVenueId: PropTypes.func,
  loginResponse: PropTypes.any,
};

export default connect(mapStateToProps, mapDispatchToProps)(VenuesReport);
