/* ----- AUTHENTICATION ----- */
export const LOGIN_URL = `/Account/login`;
export const REGISTER_URL = `/Account/register`;
export const FORGOT_PASSWORD_URL = `/Account/forgotPassword`;
export const OTP_URL = `/Account/VerifyMobileOTP`;
export const RESET_PASSWORD_URL = `/Account/ResetPassword`;
export const GET_NOTIFICATION_UUID_BY_USER_ID = `/Account/GetNotificationUUIdByUserId`;
export const UPDATE_NOTIFICATION_UUID_BY_USER_ID = `/Account/UpdateUUIDbyUserId`;

/*-----User/Profile-----*/
export const GET_USER_DETAIL = `/Account/GetUserDetails/`;
export const GET_USER_DETAIL_BY_ID = `/Account/GetUserDetailByUserId/`;
export const POST_UPDATE_PROFILE = `/Account/UpdateProfile/`;
export const POST_USER_DETAIL_BY_ID = `/Account/PostUserProfile/`;
export const CLEAR_CACHE_REPORT = `/Reservation/ClearCacheByVenue/`;

/* -----USERS/VENUE----- */
export const GET_ALL_USERS = `Account/GetAllUserandRolewithPaging/`;
export const GET_ALL_VENUES = `Venue/getallvenue/`;
export const GET_ALL_VENUES_WITH_PAGING = `Venue/getvenuedetailsbypaging/`;
export const POST_VENUE = `Venue/InsertVenueUser/`;
export const GET_VENUE_CONTACT = `/Venue/getvenuecontactbyVenueid/`;
export const GET_VENUE_DETAIL_BY_VENUE_ID = `/Venue/getvenuebyid/`;
export const INSERT_OR_UPDATE_VENUE_DETAIL = `/Venue/insertorupdatevenue/`;
export const DELETE_USER_BY_ID = `/Account/DeleteUserbyId/`;
export const GET_VENUE_USERS_BY_VENUE_ID = `/Venue/GetVenueUserByVenueId/`;
export const GET_VENUE_SETTING_BY_DAY = `/Venue/GetVenueSettingByDay/`;
export const INSERT_OR_UPDATE_VENUE_SETTING_BY_DAY = `/Venue/InsertUpdateVenueSettingByDay`;
export const GET_PRODUCT_CATEGORY_BY_VENUE_ID_ADMIN = `/ProductCategory/GetProductAndProductCategoryByVenueId/`;
export const DELETE_VENUE_USER_BY_ID = `/Venue/DeleteVenueUserbyId/`;
export const DELETE_TABLE_DETAIL_BY_ID = `TableDetails/deletetabledetails/`;
export const REGISTER_VENUE_ADMIN_INSERT_OR_UPDATE_VENUE_DETAIL = `/Account/VenueAdminRegisterbyAdmin/`;
export const TOGGLE_VENUE_STATE = `/Venue/deletevenue`;
export const INSERT_OR_UPDATE_VENUE_PREMIUM_RATE_DATES = `/Venue/InsertUpdateVenuePremiumRateDates`;
export const SEND_RESERVATION_REPORT = `/Venue/SendReservationReport`;
export const SEND_GUEST_SUMMARY_REPORT = `/Venue/SendGuestSummary`;
export const SEND_CACHE_REPORT_BY_DATE = `/Account/SendCacheReportbyDate`;

/* -----End user Reservation----- */
export const GET_RESERVATION_BY_USER_ID = `Reservation/GetReservationDetailsByUserId/`;
export const GET_RESERVATION_DETAIL_BY_ID = `Reservation/GetReservationDetailsWithVenue/`;

/* -----Reservation----- */
export const GET_ALL_RESERVATION = `Reservation/getallreservedtabledetailsbystatus/`;
export const CHECK_IN_RESERVATION = `Reservation/checkinreservation/`;
export const UNDO_CHECK_IN_RESERVATION = `Reservation/UnCheckInReservationbyId/`;
export const CANCEL_RESERVATION = `Reservation/cancelreservation/`;
export const CLEAR_RESERVATION = `Reservation/clearreservation/`;
export const COMPLETE_RESERVATION = `Reservation/completereservation/`;
export const REQUEST_CANCELLATION = `Reservation/requestcancelreservation`;
export const DENY_RESERVATION = `Reservation/denyreservation/`;

//Filter reservation for venue reservation screen 
export const GET_ALL_ADMIN_RESERVATION = `Reservation/getallvenuereservationstovenueadmin/`;

//Filter reservation for venue online reservation screen 
export const GET_FILTER_WIDGET_RESERVATION = `Reservation/GetWidgetReservationbyDateRange/`;
export const CREATE_RESERVATION = `/Reservation/insertorupdatereservation/`;
export const GET_RESERVATION_By_ID = `/Reservation/getreservationbyid/`;
export const GET_TABLE_DETAIL = `/Reservation/getalltabledetails/`;
export const GET_WEB_RESERVATION_BY_VENUE_ID = `/Reservation/GetReservationDetailsByVenueId/`;
export const ACCEPT_RESERVATION = '/Reservation/acceptreservation/';
export const GET_TABLE_DETAIL_BY_VENUEID = `/TableDetails/GetTableDetailsByVenueId/`;
export const GET_VENUE_BLOCK_DATE = `/Venue/GetVenueBlockDate/`;
export const GET_VENUE_PREMIUM_RATE_DATE = `/Venue/GetVenuePremiumRateDate/`;
export const INSERT_UPDATE_BLOCK_DATE = `/Venue/InsertUpdateVenueTableBlock/`;
export const DELETE_BLOCK_DATE = `/Venue/DeleteVenueBlockDate/`;
export const ACCEPT_CANCELATION = `/Reservation/acceptcancelation/`;
export const DENY_CANCELATION = `/Reservation/denycancelation/`;

//** Get current date by venue id */
export const GET_CURRENTDATE_BY_VENUE_ID = `/Venue/getcurrentdatebyvenueid/`;

/* -----QUEUE----- */
// Queue End screen 
export const GET_QUEUE_RESERVATION = `Reservation/GetReservationDetailsForQueue/`;

// Queue Venue screen 
export const GET_VENUE_RESERVATIONS_QUEUE = `Reservation/GetVenueReservationsQueue/`;
export const UPDATE_QUEUE_RESERVATION_STATE = `Reservation/UpdateQStatusByReservationId/`;
export const COMPLETE_OR_CLEAR_QUEUE_RESERVATION_STATE = `Reservation/CompleteClearReservationById/`;
export const GET_ORDER_ITEMS_BY_VENUE_ID = `/Reservation/GetOrderItemsByVenueId/`;

//not in use in venue Queue (clear/Update)
export const COMPLETE_OR_CLEAR_QUEUE_PRODUCT_STATE = `Reservation/CompleteClearOrderItemByReservationId/`;

//venue Queue (clear/Update)
export const COMPLETE_CLEAR_VENUE_RESERVATION_QUEUE = `Reservation/CompleteClearVenueReservationQueue/`;

/* -----Product Category / ORDER ITEM----- */
export const GET_ALL_PRODUCT = `ProductCategory/getallproductcategory/`;
export const GET_PRODUCT_DETAIL = `Product/getproductbycategoryid/`;
export const GET_PRODUCT_CATEGORY_BY_VENUE_ID = `ProductCategory/getproductcategorybyvenueid/`;
export const GET_PRODUCT_CATEGORY_BY_ID = `ProductCategory/getproductcategorybyid/`;
export const GET_ORDER_ITEMS_BY_RESERVATION = `Order/GetOrderByReservation/`;
export const INSERT_ORDER_BY_RESERVATION = `Order/InsertUpdateOrderByReservationId/`;
export const DELETE_ORDER_ITEMS = `OrderItems/deleteorderitems/`;
export const SEND_ORDER_ITEMS = `Order/SendOrderByReservationId?`;
export const INSERT_UPDATE_PRODUCT_CATEGORY = `ProductCategory/insertorupdateproductcategory/`;
export const INSERT_UPDATE_PRODUCT = `Product/insertorupdateproduct/`;
export const GET_PRODUCT_BY_ID = `Product/getproductbyid/`;
export const GET_PRODUCT_BY_CATEGORY_ID = `Product/getproductbycategoryid/`;
export const GET_ORDER_ITEMS_BY_RESERVATION_ID = "Reservation/GetOrderItemsByReservationId/";
export const DELETE_PRODUCT_CATEGORY = `ProductCategory/deleteproductcategory`;

//Venue Queue screen order
export const GET_ORDER_ITEMS_BY_ORDER_ID = `Reservation/getorderitemsbyorderid/`;

/* -----ACTIVITY/CACHE LOG----- */
export const GET_ALL_ACTIVITY = `/ActivityLogs/getallactivitylogs/`;
export const GET_ALL_ACTIVITY_BY_USERID = '/ActivityLogs/getActivityLogsbyuserid/';
export const GET_ALL_CACHE_BY_USERID_VENUEID = '/Reservation/GetCheckInReservationDetailsForCache/'
export const GET_ALL_CACHE_BY_VENUEID = '/Reservation/GetCacheDataByVenueId/'
export const INSERT_OR_UPDATE_ACTIVITY = `/ActivityLogs/insertorupdateActivityLogs/`;
export const CANCEL_USER_ACTIVITY = `/ActivityLogs/CancelUserActivity/`;
export const CLEAR_USER_ACTIVITY = `/ActivityLogs/ClearUserActivity/`;
export const REQUEST_CANCEL_USER_ACTIVITY = `/ActivityLogs/RequestToCancelUserActivity/`;

/* ----- REPORT ----- */
export const GET_VENUE_CACHE_REPORT_BY_DATE = `Reservation/GetVenueCachereportByDate/`;
export const GET_CACHE_REPORT_BY_DATE = `Account/GetCacheReportByDate/`;  // ** for Preview Cache Reports in Cache Report Modal ** //
export const GET_VENUE_CACHE_REPORT_BY_VENUE_ID = `Reservation/GetVenueCachereportByVenueId/`;
export const DOWNLOAD_CACHE_REPORT = `Account/DownloadCacheReport`;

/* -----EMAIL REPORT----- */
export const SEND_CACHE_REPORT = `/Account/SendCacheReport/`;
export const SEND_CATCH_REPORT = `/Account/SendCatchReport`;

/* -----City , State and country----- */
export const FETCH_ALL_CITY = `/Configuration/getCity`;
export const FETCH_ALL_STATE = `/Configuration/getState`;
export const FETCH_ALL_COUNTRY = `/Configuration/getCountry`;

/* -----Venue reservation list----- */
export const GET_VENUE_RESERVATION_DETAILS = `/Venue/GetReservationCountDetailsByVenueId`;

/* -----Venue analytics ----- */
export const GET_VENUE_ANALYTICS = `/Venue/GetVenueAnalytics`;

/* -----Venue Table list----- */
export const GET_VENUE_TABLE_BY_VENUE_ID = `/TableDetails/GetTableDetailsByVenueId/`;
export const GET_VENUE_TABLE_LIST_BY_VENUE_ID = `/TableDetails/GetTableListByVenueId/`;
export const GET_VENUE_TABLE_LIST_BY_VENUE_ID_ADMIN = `/TableDetails/GetAdminTablesByVenueId/`;

/* -----Venue Table list----- */
export const GET_TABLE_DETAIL_BY_ID = `/TableDetails/GetTableDetailsById/`;

/* -----POST Table iMAGE----- */
export const INSERT_UPDATE_APP_SETTINGS = `/Configuration/InsertUpdateAppSettings/`;
export const GET_APP_SETTINGS_BY_ID = `/Configuration/GetApplicationSettings/`;

/* -----POST Table iMAGE----- */
export const POST_TABLE = `/TableDetails/insertorupdatetabledetails/`;
export const DELETE_PRODUCT_BY_ID = `/Product/deleteproduct/`;

/* ----- CART ----- */
export const SEND_ORDER_BY_RESERVATION_ID = `/Order/SendOrderByReservationId`;

/*----- Reservation Report List -----*/
export const GET_RESERVATION_REPORT_BY_VENUE = `/Reservation/GetReservationReportbyVenue`;
export const GET_GUEST_SUMMARY_BY_USER = `/Reservation/GetGuestSummarybyUser`;

/*----- Reservation Report Download -----*/
export const DOWNLOAD_RESERVATION_REPORT = `/Reservation/DownloadReservationReport`;
export const DOWNLOAD_GUEST_SUMMARY_REPORT = `/Reservation/DownloadGuestSummaryReport`;
export const Download_VenueAnalytics_Report='/Venue/DownloadVenueAnalyticsReport';


/*-----  Venue Deposite  -----*/
export const GET_VENUE_DEPOSITE_SETTINGS = `/Venue/GetVenueDepositSettings/`;
export const INSERT_UPDATE_VENUE_DEPOSITE = '/Venue/InsertUpdateVenueDeposit';

